import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <footer className="footer mt-auto text-white footer-section">
      <div className="container mt-4 p-5 d-flex flex-wrap justify-content-between">
        <div className="col footer-column">
          <div className="footer-logo-img mb-3"></div>
          <p>
            <i className="fa-solid fa-location-dot text-danger"></i>
            <span></span>{" "}
            <a
              className="nav-item-link-footer"
              href="https://www.google.com/maps/place/SAR+Custom+Services/@18.5536311,73.9494086,15z/data=!4m6!3m5!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt?entry=ttu"
            >
              518, Global business hub, opp Eon IT park, Kharadi, Pune - 411014
            </a>
          </p>
          <p>
            <i className="fa-solid fa-envelope text-danger"></i>
            <span> </span>
            <a className="nav-item-link-footer" href="mailto:support@sarcustomize.in">
              info@sarcustomize.in
            </a>
          </p>
          <p>
            <i class="fa-solid fa-phone text-danger"></i>
            <span> </span>
            <a className="nav-item-link-footer" href="tel:+918237525097">
              +91 8237525097
            </a>
          </p>
        </div>

        <div className="col footer-column">
          <h5>Quick Links</h5>
          <hr className="horizontal-line" />
          <ul className="list-unstyled mt-4">
            <li className="footer-main-links mt-2">
              <Link className="nav-item-link-footer" onClick={handleClick} to="/">
                Home
              </Link>
            </li>
            <li className="footer-main-links mt-2">
              <Link className="nav-item-link-footer" onClick={handleClick} to="/Carrer">
                Career
              </Link>
            </li>
            <li className="footer-main-links mt-2">
              <Link className="nav-item-link-footer" onClick={handleClick} to="/Demo">
                Demo
              </Link>
            </li>

            <li className="footer-main-links mt-2">
              <Link className="nav-item-link-footer" onClick={handleClick} to="/Contact">
                Contact Us
              </Link>
            </li>
           
          </ul>
        </div>

        <div className="col footer-column">
          <h5>Contact Us</h5>
          <hr />
          <ul className="list-unstyled mt-4">
            <li className="footer-main-links mt-2">
              <a
                className="nav-item-link-footer"
                href="https://www.facebook.com/sarcustomize/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0"
              >
                <i className="fab fa-facebook-f"></i> Facebook
              </a>
            </li>
            <li className="footer-main-links mt-2">
              <a
                className="nav-item-link-footer"
                href="https://www.instagram.com/sar_customize/"
              >
                <i className="fab fa-twitter"></i> Twitter
              </a>
            </li>
            <li className="footer-main-links mt-2">
              <a
                className="nav-item-link-footer"
                href="https://www.instagram.com/sar_customize/"
              >
                <i className="fab fa-instagram"></i> Instagram
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="footer d-flex p-4 border-top justify-content-center">
        © Copyright 2024 - @SAR Custom Solutions. All Rights Reserved 
      </div>
    </footer>
  );
}

export default Footer;



//     // src/components/Header.js
// import React from "react";
// import "./Footer.css";
// import { Link} from "react-router-dom"
// // import { Li, Linknk } from "react-router-dom";
// function Footer() {
//   const handleClick = ()=>{
//     window.scrollTo(0,0);
//   }
//   return (
//     <footer className="footer mt-auto  text-white footer-section">
//       {/* <div className="container d-flex footer-main-section">
//         <div className="col footer-main-col mt-4 ">
//           <p>
//             <strong>Get A Quote</strong>
//           </p>
//           <h4>
//             <strong>Let’s Transform your vision Together!</strong>
//           </h4>
//         </div>
//         <div className="col mt-4">
//           <h3>
//             <strong>We Are Always With your Business</strong>
//           </h3>
//           <button className="btn btn-dark mt-3 main-footer-call" type="button">
//             <Link href="/" className="text-white ">
//               Schedule a call <span> </span><i class="fa-solid fa-arrow-right"></i>
//             </Link>
//           </button>
//         </div>
//       </div> */}
//       <div className="container mt-4 p-5 d-flex">
//         <div className="row ">
//           <div className="col m-3 ">
//             <div className="footer-logo-img"></div>
//             <p><i class="fa-solid fa-location-dot text-danger"></i>
//               <span></span> <a className="nav-item-link-footer" href="https://www.google.com/maps/place/SAR+Custom+Services/@18.5536311,73.9494086,15z/data=!4m6!3m5!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt?entry=ttu"> 518,Global business hub,opp=Eon<br></br> IT park, Kharadi, Pune -
//               411014</a>
//             </p>
//             <p>
//             <i class="fa-solid fa-envelope text-danger"></i><span>  </span>
//               <a  className="nav-item-link-footer" href="mailto:support@sarcustomize.in">info@sarcustomize.in</a>
//             </p>
//             <p>
//             <i class="fa-solid fa-phone-flip text-danger"></i><span> </span>
//               <a className="nav-item-link-footer" href="tel:+918237525097">918237525097</a>
//             </p>
//           </div>
//         </div>
//         {/* <div className="col m-3 ">
//           <h5>Service</h5>
//           <br />
//           <hr/>
//           <div className="col">
//             <ul className="list-unstyled text-white mt-4">
//               <li>
//                 <Link className="nav-item-link-footer" to="/">ERP Development</Link>
//               </li>
//               <li className="footer-main-links mt-2">
//                 <Link className="nav-item-link-footer" to="#">CRM Development</Link>
//               </li>
//               <li className="footer-main-links mt-2">
//                 <Link className="nav-item-link-footer" to="#">Accounting & Finacial Software</Link>
//               </li>
//               <li className="footer-main-links mt-2">
//                 <Link className="nav-item-link-footer" to="#"> Project Management Tools</Link>
//               </li>
//               <li className="footer-main-links mt-2">
//                 <Link className="nav-item-link-footer" to="#">Appointment Booking Software</Link>
//               </li>
            
//             </ul>
//           </div>
//         </div> */}
//         <div className="col  m-3">
//           <h5>Quick Links</h5>
//           <br />
//           <hr className="horizontal-line"/>
//           <ul className=" list-unstyled text-white mt-4 ">
//             <li className="footer-main-links mt-2  ">
//               <Link className="nav-item-link-footer" onClick={handleClick} to="/">Home</Link>
//             </li>
//             {/* <li className="footer-main-links mt-2 ">
//               <Link className="nav-item-link-footer" onClick={handleClick} to="/about_us">About Company</Link>
              
//             </li>
//             <li className="footer-main-links mt-2">
//               <Link className="nav-item-link-footer"onClick={handleClick}  to="/Solution">Popular Service</Link>
//             </li> */}
           
//             {/* <li className="footer-main-links mt-2">
//               <Link className="nav-item-link-footer"onClick={handleClick}  to="/Blogs">Blogs</Link>
//             </li> */}
//             <li className="footer-main-links mt-2">
//               <Link className="nav-item-link-footer"onClick={handleClick}  to="/Carrer">Carrer</Link>
//             </li>
//             <li className="footer-main-links mt-2">
//               <Link className="nav-item-link-footer"onClick={handleClick}  to="/Demo">Demo</Link>
//             </li>
//             <li className="footer-main-links mt-2">
//               <Link className="nav-item-link-footer"onClick={handleClick}  to="/Contact">Contact US</Link>
//             </li>
//             {/* <li className="footer-main-links mt-2">
//               <Link className="nav-item-link-footer"onClick={handleClick}  to="/Contact">Contact </Link>
//             </li> */}
//           </ul>
//         </div>  
//         <div className="col m-3">
//           <h5>Contact Us</h5>
//           <br />
//           <hr/>
//           <ul className="list-unstyled mt-4">
//             <li className="footer-main-links mt-2">
//               <a className="nav-item-link-footer"  href="https://www.facebook.com/sarcustomize/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0">
//                 <i className="fab fa-facebook-f"></i> Facebook
//               </a>
//             </li>
//             <li className="footer-main-links mt-2">
//               <a className="nav-item-link-footer" href="https://www.instagram.com/sar_customize/">
//                 <i className="fab fa-twitter"></i> Twitter
//               </a>
//             </li>
//             <li className="footer-main-links mt-2">
//               <a className="nav-item-link-footer" href="https://www.instagram.com/sar_customize/">
//                 <i className="fab fa-instagram"></i> Instagram
//               </a>
//             </li>
//           </ul>
//         </div>
//       </div>

//       <div className="footer d-flex p-4 border-top  justify-content-center">
//         ©Copyright 2024 - @SAR Custom Solutions. All Rights Reserved
//       </div>
//     </footer>
//   );
// }

// export default Footer;



// import React from "react";
// import "./Footer.css";
// import { Link } from "react-router-dom";

// function Footer() {
//   return (
//     <footer className="footer mt-auto text-white footer-section">
//       <div className="container-fluid">
//         <div className="row">
//           <div className="col-md-6 footer-main-col">
//             <p>
//               <strong>Get A Quote</strong>
//             </p>
//             <h4>
//               <strong>Let’s Transform your vision Together!</strong>
//             </h4>
//           </div>
//           <div className="col-md-6">
//             <h3>
//               <strong>We Are Always With your Business</strong>
//             </h3>
//             <button className="btn btn-dark mt-3 main-footer-call" type="button">
//               <Link to="/" className="text-white">
//                 Schedule a call <span> </span><i className="fa-solid fa-arrow-right"></i>
//               </Link>
//             </button>
//           </div>
//         </div>
//       </div>

//       <div className="container-fluid p-5">
//         <div className="row">
//           <div className="col-md-4">
//             <div className="footer-logo-img"></div>
//             <p>
//               <i className="fa-solid fa-location-dot text-danger"></i>
//               <span> </span> 518, Global business hub, opp=Eon IT park, Kharadi, Pune - 411014
//             </p>
//             <p>
//               <i className="fa-solid fa-envelope text-danger"></i><span> </span>
//               <Link to="/" className="nav-item-link-footer">info@sarcustomize.in</Link>
//             </p>
//             <p>
//               <i className="fa-solid fa-phone-flip text-danger"></i><span> </span>
//               <Link to="/" className="nav-item-link-footer">082375 25097</Link>
//             </p>
//           </div>

//           <div className="col-md-4">
//             <h5>Service</h5>
//             <hr />
//             <ul className="list-unstyled text-white">
//               <li>
//                 <Link to="/" className="nav-item-link-footer">ERP Development</Link>
//               </li>
//               <li>
//                 <Link to="/" className="nav-item-link-footer">CRM Development</Link>
//               </li>
//               {/* Add other service links */}
//             </ul>
//           </div>

//           <div className="col-md-4">
//             <h5>Quick Links</h5>
//             <hr />
//             <ul className="list-unstyled text-white">
//               <li>
//                 <Link to="/" className="nav-item-link-footer">Home</Link>
//               </li>
//               <li>
//                 <Link to="/about_us" className="nav-item-link-footer">About Company</Link>
//               </li>
//               {/* Add other quick links */}
//             </ul>
//           </div>
//         </div>

//         <div className="row mt-4">
//           <div className="col-md-12 text-center">
//             <Link to="#" className="nav-item-link-footer">
//               <i className="fab fa-facebook-f"></i> Facebook
//             </Link>
//             <span className="ml-3"></span>
//             <Link to="#" className="nav-item-link-footer">
//               <i className="fab fa-twitter"></i> Twitter
//             </Link>
//             <span className="ml-3"></span>
//             <Link to="#" className="nav-item-link-footer">
//               <i className="fab fa-instagram"></i> Instagram
//             </Link>
//           </div>
//         </div>
//       </div>

//       <div className="footer d-flex p-4 border-top justify-content-center">
//         ©Copyright 2024 - @SAR Custom Solutions. All Rights Reserved
//       </div>
//     </footer>
//   );
// }

// export default Footer;

