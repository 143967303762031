// import React, { useState, useEffect, useCallback } from "react";
// import "./DigitalMarketing.css";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Button from "@mui/material/Button";
// import QuizIcon from "@mui/icons-material/Quiz";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// const DigitalMarketing = () => {
//   const [questions, setQuestions] = useState([]);
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [score, setScore] = useState(0);
//   const [showScore, setShowScore] = useState(false);
//   const [selectedOptions, setSelectedOptions] = useState({});
//   const [timer, setTimer] = useState(0);
//   const [timerOn, setTimerOn] = useState(false);
//   const [timeUp, setTimeUp] = useState(false);
//   const [testStarted, setTestStarted] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false); // State for dialog
//   const [openSubmitDialog, setOpenSubmitDialog] = useState(false); // State for submit dialog
//   const [cameraAccessDialogOpen, setCameraAccessDialogOpen] = useState(false);
//   const testDuration = 40 * 60;
//   const navigate = useNavigate();
//   const videoRef = React.useRef(null);

//   useEffect(() => {
//     const fetchQuestions = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_API_URL}/api/digitalquestions/random`
//         );
//         setQuestions(response.data);
//       } catch (error) {
//         console.error("Error fetching questions:", error);
//       }
//     };
//     fetchQuestions();
//   }, []);

//   const handleSubmitTest = useCallback(async () => {
//     const email = localStorage.getItem("userEmail"); // Retrieve email from local storage

//     stopCamera();
//     setShowScore(true);
//     setTimerOn(false);

//     let finalScore = 0;
//     questions.forEach((question, index) => {
//       if (selectedOptions[index] === question.correct_answer) {
//         finalScore += 1;
//       }
//     });
//     setScore(finalScore);

//     try {
//       await axios.post(`${process.env.REACT_APP_API_URL}/api/dm-test-scores1`, {
//         email: email,
//         mcq_score: finalScore,
//         mcq_total_questions: 20,
//       });
//       setOpenSubmitDialog(true);
//     } catch (error) {
//       console.error("Error submitting score:", error);
//       navigate("/DocumentUploadDM");
//     }
//   }, [selectedOptions, questions, navigate]);

//   useEffect(() => {
//     let intervalId;
//     if (timerOn) {
//       intervalId = setInterval(() => {
//         setTimer((prevTimer) => prevTimer + 1);
//         if (timer >= testDuration) {
//           setTimerOn(false);
//           setTimeUp(true);
//           handleSubmitTest();
//         }
//       }, 1000);
//     }
//     return () => clearInterval(intervalId);
//   }, [timerOn, timer, testDuration, handleSubmitTest]);

//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (testStarted && document.hidden) {
//         setOpenDialog(true);
//       }
//     };
//     document.addEventListener("visibilitychange", handleVisibilityChange);
//     return () => {
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//     };
//   }, [testStarted]);

//   useEffect(() => {
//     if (testStarted) {
//       setCameraAccessDialogOpen(true);
//     } else {
//       stopCamera();
//     }
//   }, [testStarted]);

//   const handleOptionChange = (option) => {
//     setSelectedOptions({
//       ...selectedOptions,
//       [currentQuestion]: option,
//     });
//   };

//   const handleNextQuestion = () => {
//     const nextQuestion = currentQuestion + 1;
//     if (nextQuestion < questions.length) {
//       setSelectedOptions({
//         ...selectedOptions,
//         [currentQuestion]: selectedOptions[currentQuestion] || "", // Store the current question's answer even if not selected
//       });
//       setCurrentQuestion(nextQuestion);
//     }
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//     handleSubmitTest();
//     navigate("/DocumentUploadDM");
//   };

//   const handleCloseSubmitDialog = () => {
//     setOpenSubmitDialog(false);
//     navigate("/DocumentUploadDM");
//   };

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes.toString().padStart(2, "0")}:${seconds
//       .toString()
//       .padStart(2, "0")}`;
//   };

//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//       }
//     } catch (error) {
//       console.error("Error accessing the camera:", error);
//     }
//   };

//   const stopCamera = () => {
//     if (videoRef.current && videoRef.current.srcObject) {
//       const tracks = videoRef.current.srcObject.getTracks();
//       tracks.forEach((track) => track.stop());
//       videoRef.current.srcObject = null;
//     }
//   };

//   const handleCameraAccess = () => {
//     setCameraAccessDialogOpen(false);
//     startCamera();
//   };

//   return (
//     <div className="quiz-container">
//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>Tab Switch Detected</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             You have switched tabs. The test will now be submitted.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog
//         open={cameraAccessDialogOpen}
//         //  onClose={() => setCameraAccessDialogOpen(false)}
//       >
//         <DialogTitle>Camera Access Required</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             This test requires access to your camera. Please allow camera access
//             to proceed with the test.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCameraAccess} color="primary">
//             Allow
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={openSubmitDialog} onClose={handleCloseSubmitDialog}>
//         <DialogContent>
//           <DialogContentText>
//             <h2 style={{ color: "black" }}>Test Submitted Successfully!!</h2>
//             {/* You scored {score} out of {questions.length}. */}
//             <h5>
//               Percentage: {((score / questions.length) * 100).toFixed(2)}%
//             </h5>
//             <h6>
//               {(score / questions.length) * 100 >= 60
//                 ? "You have Passed the test"
//                 : "you have Failed the test"}
//             </h6>
//           </DialogContentText>
//         </DialogContent>

//         <DialogActions>
//           <Button onClick={handleCloseSubmitDialog} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <h1 className="coding-test text-white p-3 d-flex justify-content-center mb-4">
//         DIGITAL MARKETING TEST
//       </h1>

//       {!timerOn && !showScore && (
//         <div className="start-container">
//           <h2>
//             <strong>Read carefully</strong>
//           </h2>
//           <div className="test-criteria">
//             <p>
//               <strong>Format:</strong> The test consists of 20 multiple-choice
//               questions (MCQs).
//             </p>
//             <p>
//               <strong>Passing Score:</strong> A minimum of 60% is required for
//               selection.
//             </p>
//             <p>
//               <strong>Time Limit: </strong>The test must be completed within 40
//               minutes.
//             </p>
//             <p>
//               <strong>Warning: </strong>Don't change the tab when the exam will
//               start. Otherwise, the exam will be submitted.
//             </p>
//             <p>All The Best !!</p>
//           </div>
//           <button
//             className="start-btn"
//             onClick={() => {
//               setTimerOn(true);
//               setTestStarted(true);
//             }}
//           >
//             Start MCQ Test
//           </button>
//         </div>
//       )}

//       {timerOn && (
//         <div className="timer">
//           Time Left:{" "}
//           {timer < testDuration ? formatTime(testDuration - timer) : "00:00"}
//         </div>
//       )}

//       {timeUp && (
//         <div className="quiz-card score-card">
//           <div className="quiz-card-body">
//             <h5 className="quiz-card-title">Time's up!</h5>
//             <p>Please submit the test to see your score.</p>
//           </div>
//         </div>
//       )}

//       {timerOn && !showScore && !timeUp && (
//         <div className="quiz-card">
//           <div className="quiz-card-body">
//             <h5 className="quiz-card-title">
//               <QuizIcon /> Question {currentQuestion + 1}/{questions.length}
//             </h5>
//             <p className="quiz-card-text">
//               {`${currentQuestion + 1}. ${
//                 questions[currentQuestion].question_text
//               }`}
//             </p>
//             <div className="quiz-options">
//               {questions[currentQuestion].options.map((option, index) => (
//                 <FormControlLabel
//                   key={index}
//                   control={
//                     <Checkbox
//                       checked={selectedOptions[currentQuestion] === option}
//                       onChange={() => handleOptionChange(option)}
//                     />
//                   }
//                   label={option}
//                 />
//               ))}
//             </div>

//             <div className="quiz-navigation">
//               {currentQuestion > 0 && (
//                 <button
//                   className="quiz-btn-back"
//                   onClick={() => setCurrentQuestion(currentQuestion - 1)}
//                 >
//                   Back
//                 </button>
//               )}
//               {currentQuestion < questions.length - 1 ? (
//                 <button
//                   className="quiz-btn-next"
//                   onClick={handleNextQuestion}
//                   disabled={!selectedOptions[currentQuestion]}
//                 >
//                   Next
//                 </button>
//               ) : (
//                 <button
//                   className="quiz-btn-submit"
//                   onClick={handleSubmitTest}
//                   disabled={!selectedOptions[currentQuestion]}
//                 >
//                   Submit Test
//                 </button>
//               )}
//             </div>
//             {!selectedOptions[currentQuestion] && (
//               <p>Please select an option.</p>
//             )}
//           </div>
//         </div>
//       )}

//       {testStarted && (
//         <div className="camera">
//           <video ref={videoRef} autoPlay className="video-feed"></video>
//         </div>
//       )}
//     </div>
//   );
// };

// export default DigitalMarketing;




import React, { useState, useEffect, useCallback } from "react";
import "./DigitalMarketing.css";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import QuizIcon from "@mui/icons-material/Quiz";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const DigitalMarketing = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [timer, setTimer] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // State for dialog
  const [openSubmitDialog, setOpenSubmitDialog] = useState(false); // State for submit dialog
  const [cameraAccessDialogOpen, setCameraAccessDialogOpen] = useState(false);
  const testDuration = 40 * 60;
  const navigate = useNavigate();
  const videoRef = React.useRef(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/digitalquestions/random`
        );
        setQuestions(response.data);
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };
    fetchQuestions();
  }, []);

  const handleSubmitTest = useCallback(async () => {
    const email = localStorage.getItem("userEmail"); // Retrieve email from local storage

    stopCamera();
    setShowScore(true);
    setTimerOn(false);

    let finalScore = 0;
    questions.forEach((question, index) => {
      if (selectedOptions[index] === question.correct_answer) {
        finalScore += 1;
      }
    });
    setScore(finalScore);

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/dm-test-scores1`, {
        email: email,
        mcq_score: finalScore,
        mcq_total_questions: 20,
      });
      setOpenSubmitDialog(true);
    } catch (error) {
      console.error("Error submitting score:", error);
      navigate("/DocumentUploadDM");
    }
  }, [selectedOptions, questions, navigate]);

  useEffect(() => {
    let intervalId;
    if (timerOn) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
        if (timer >= testDuration) {
          setTimerOn(false);
          setTimeUp(true);
          handleSubmitTest();
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timerOn, timer, testDuration, handleSubmitTest]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (testStarted && document.hidden) {
        setOpenDialog(true);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [testStarted]);

  useEffect(() => {
    if (testStarted) {
      setCameraAccessDialogOpen(true);
    } else {
      stopCamera();
    }
  }, [testStarted]);


  const checkCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setCameraAccessDialogOpen(true);
    }
  };
  

  const handleOptionChange = (option) => {
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestion]: option,
    });
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setSelectedOptions({
        ...selectedOptions,
        [currentQuestion]: selectedOptions[currentQuestion] || "", // Store the current question's answer even if not selected
      });
      setCurrentQuestion(nextQuestion);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleSubmitTest();
    navigate("/DocumentUploadDM");
  };

  const handleCloseSubmitDialog = () => {
    setOpenSubmitDialog(false);
    navigate("/DocumentUploadDM");
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setCameraAccessDialogOpen(true);
      console.error("Error accessing the camera:", error);
    }
  };
  useEffect(() => {
    const cameraCheckInterval = setInterval(() => {
      if (testStarted) {
        checkCameraAccess();
      }
    }, 5000); // Check every 5 seconds
  
    return () => clearInterval(cameraCheckInterval);
  }, [testStarted]);
  

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleCameraAccess = () => {
    setCameraAccessDialogOpen(false);
    startCamera();
  };

  return (
    <div className="quiz-container">
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Tab Switch Detected</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have switched tabs. The test will now be submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={cameraAccessDialogOpen}
        //  onClose={() => setCameraAccessDialogOpen(false)}
      >
        <DialogTitle>Camera Access Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This test requires access to your camera. Please allow camera access
            to proceed with the test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCameraAccess} color="primary">
            Allow
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSubmitDialog} onClose={handleCloseSubmitDialog}>
        <DialogContent>
          <DialogContentText>
            <h2 style={{ color: "black" }}>Test Submitted Successfully!!</h2>
            {/* You scored {score} out of {questions.length}. */}
            <h5>
              Percentage: {((score / questions.length) * 100).toFixed(2)}%
            </h5>
            <h6>
              {(score / questions.length) * 100 >= 60
                ? "You have Passed the test"
                : "you have Failed the test"}
            </h6>
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseSubmitDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <h1 className="coding-test text-white p-3 d-flex justify-content-center mb-4">
        DIGITAL MARKETING TEST
      </h1>

      {!timerOn && !showScore && (
        <div className="start-container">
          <h2>
            <strong>Read carefully</strong>
          </h2>
          <div className="test-criteria">
            <p>
              <strong>Format:</strong> The test consists of 20 multiple-choice
              questions (MCQs).
            </p>
            <p>
              <strong>Passing Score:</strong> A minimum of 60% is required for
              selection.
            </p>
            <p>
              <strong>Time Limit: </strong>The test must be completed within 40
              minutes.
            </p>
            <p>
              <strong>Camera Acess </strong>Allow the Camera acess from settings..Before start test..
            </p>
            <p>
              <strong>Warning: </strong>Don't change the tab when the exam will
              start. Otherwise, the exam will be submitted.
            </p>
            <p>All The Best !!</p>
          </div>
          <button
            className="start-btn"
            onClick={() => {
              setTimerOn(true);
              setTestStarted(true);
            }}
          >
            Start MCQ Test
          </button>
        </div>
      )}

      {timerOn && (
        <div className="timer">
          Time Left:{" "}
          {timer < testDuration ? formatTime(testDuration - timer) : "00:00"}
        </div>
      )}

      {timeUp && (
        <div className="quiz-card score-card">
          <div className="quiz-card-body">
            <h5 className="quiz-card-title">Time's up!</h5>
            <p>Please submit the test to see your score.</p>
          </div>
        </div>
      )}

      {timerOn && !showScore && !timeUp && (
        <div className="quiz-card">
          <div className="quiz-card-body">
            <h5 className="quiz-card-title">
              <QuizIcon /> Question {currentQuestion + 1}/{questions.length}
            </h5>
            <p className="quiz-card-text">
              {`${currentQuestion + 1}. ${
                questions[currentQuestion].question_text
              }`}
            </p>
            <div className="quiz-options">
              {questions[currentQuestion].options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedOptions[currentQuestion] === option}
                      onChange={() => handleOptionChange(option)}
                    />
                  }
                  label={option}
                />
              ))}
            </div>

            <div className="quiz-navigation">
              {currentQuestion > 0 && (
                <button
                  className="quiz-btn-back"
                  onClick={() => setCurrentQuestion(currentQuestion - 1)}
                >
                  Back
                </button>
              )}
              {currentQuestion < questions.length - 1 ? (
                <button
                  className="quiz-btn-next"
                  onClick={handleNextQuestion}
                  disabled={!selectedOptions[currentQuestion]}
                >
                  Next
                </button>
              ) : (
                <button
                  className="quiz-btn-submit"
                  onClick={handleSubmitTest}
                  disabled={!selectedOptions[currentQuestion]}
                >
                  Submit Test
                </button>
              )}
            </div>
            {!selectedOptions[currentQuestion] && (
              <p>Please select an option.</p>
            )}
          </div>
        </div>
      )}

      {testStarted && (
        <div className="camera">
          <video ref={videoRef} autoPlay className="video-feed"></video>
        </div>
      )}
      <Dialog
  open={cameraAccessDialogOpen}
  aria-labelledby="camera-access-dialog-title"
  aria-describedby="camera-access-dialog-description"
>
  <DialogTitle id="camera-access-dialog-title">
    {"Camera Access Required"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="camera-access-dialog-description">
      To ensure the integrity of the test, we need to access your camera.
      Please allow camera access to proceed with the test.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCameraAccess} color="primary" autoFocus>
      Allow
    </Button>
  </DialogActions>
</Dialog>

    </div>
  );
};

export default DigitalMarketing;



