// // src/components/Header.js
// import React from "react";
// import "./Header.css";
// import { Link } from "react-router-dom";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useNavigate } from 'react-router-dom';

// import Navbar from "../Navbar/Navbar";
// function Header() {
//     const navigate = useNavigate();
  
//   return (
//     <>
//       <nav
//         style={{
//           position: "fixed",
//           zIndex: "9999",
//           width: "100vw",
//         }}
//         className="navbar "
//       >
//         <div className="nav-item nav-item-link">
        
//           <a
//             className="nav-Link active  nav-item-link"
//             href="tel:+918237525097"
//           >
//             <i class="fa-solid fa-phone-flip"></i>
//             <span> </span>
//             918237525097
//           </a>
//         </div>
//         <div className="nav-item nav-item-link">
//           <a className="nav-Link nav-item-link" href="https://www.google.com/maps/place/SAR+Custom+Services/@18.5536311,73.9494086,15z/data=!4m14!1m7!3m6!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!2sSAR+Custom+Services!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt!3m5!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt?entry=ttu">
//             <i class="fa-solid fa-location-dot "></i>
//             Pune, Maharashtra
//           </a>
//         </div>
//         <div className="nav-item nav-item-link">
//           <a
//             className="nav-Link nav-item-link"
//             href="mailto:support@sarcustomize.in"
//           >
//             <i class="fa-solid fa-envelope  "></i>
//             <span> </span>
//             support@sarcustomize.in
//           </a>
//         </div>
//         <div className="nav-item social-media-icon nav-item-link">
//           <div className="nav-Link nav-item-link">
//             <a className="social-media-icon" href="https://www.facebook.com/sarcustomize/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0">
//               <i class="fa-brands fa-facebook"></i>{" "}
//             </a>

//             <a className="social-media-icon" href="https://www.instagram.com/sar_customize/">
//               <i class="fa-brands fa-square-instagram"></i>{" "}
//             </a>
//             <a className="social-media-icon" href="https://www.linkedin.com/company/sar-custom-solutions/">
//               {" "}
//               <i class="fa-brands fa-linkedin"></i>{" "}
//             </a>
//           </div>
//           </div>
//           <div className="Admin-page">

//           <button className="btn Login-admin bg-danger " onClick={() => navigate('/Admin')}>Login</button>
//             {/* <Link href="./Admin">Login</Link>  */}

//           </div>
      
//       </nav>
//       <Navbar />
//       <div>
//         <link
//           rel="stylesheet"
//           href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
//         />
//         <a
//           href="tel:+918237525097"
//           class="float"
//           target="_blank"
//         >
//           <i className="fa-sharp fa-solid fa-phone-volume"></i>
//         </a>
//       </div>


//       <div className="whatsapp-phone-icons">
//         <a
//           href="https://api.whatsapp.com/send/?phone=918237525097&text&type=phone_number&app_absent=0"
//           class="whatsapp_float"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <i class="fa fa-whatsapp whatsapp-icon"></i>
//         </a>
//       </div>
//     </>
//   );
// }

// export default Header;


// import React from "react";
// import "./Header.css";

// import { useNavigate } from 'react-router-dom';

// import Navbar from "../Navbar/Navbar";
// function Header() {
//     const navigate = useNavigate();
  
//   return (
//     <>
//       <nav className="sar-header-navbar">
//         <div className="sar-header-nav-item sar-header-nav-item-link">
//           <a className="sar-header-nav-link active sar-header-nav-item-link" href="tel:+918237525097">
//           <i class="fa-solid fa-phone"></i>            <span> </span>
//             918237525097
//           </a>
//         </div>
//         <div className="sar-header-nav-item sar-header-nav-item-link">
//           <a className="sar-header-nav-link sar-header-nav-item-link" href="https://www.google.com/maps/place/SAR+Custom+Services/@18.5536311,73.9494086,15z/data=!4m14!1m7!3m6!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!2sSAR+Custom+Services!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt!3m5!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt?entry=ttu">
//             <i className="fa-solid fa-location-dot "></i>
//             Pune, Maharashtra
//           </a>
//         </div>
//         <div className="sar-header-nav-item sar-header-nav-item-link">
//           <a className="sar-header-nav-link sar-header-nav-item-link" href="mailto:support@sarcustomize.in">
//             <i className="fa-solid fa-envelope  "></i>
//             <span> </span> 
//             support@sarcustomize.in
//           </a>
//         </div>
//         <div className="sar-header-nav-item sar-header-social-media-icon sar-header-nav-item-link">
//           <div className="sar-header-nav-link sar-header-nav-item-link">
//             <a className="sar-header-social-media-icon" href="https://www.facebook.com/sarcustomize/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0">
//               <i className="fa-brands fa-facebook m-2"></i>{" "}
//             </a>
//             <a className="sar-header-social-media-icon" href="https://www.instagram.com/sar_customize/">
//               <i className="fa-brands fa-square-instagram m-2"></i>{" "}
//             </a>
//             <a className="sar-header-social-media-icon" href="https://www.linkedin.com/company/sar-custom-solutions/">
//               {" "}
//               <i className="fa-brands fa-linkedin m-2"></i>{" "}
//             </a>
//           </div>
//         </div>
//         <div className="sar-header-admin-page">
//           <button className="sar-header-btn sar-header-login-admin bg-danger btn " onClick={() => navigate('/Admin')}>Login</button>
//         </div>
//       </nav>
//       <Navbar />
//       <div>
//         <link
//           rel="stylesheet"
//           href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
//         />
//         <a
//           href="tel:+918237525097"
//           className="sar-header-float"
//           target="_blank"
//         >
//           <i className="fa-sharp fa-solid fa-phone-volume"></i>
//         </a>
//       </div>
//       <div className="sar-header-whatsapp-phone-icons">
//         <a
//           href="https://api.whatsapp.com/send/?phone=918237525097&text&type=phone_number&app_absent=0"
//           className="sar-header-whatsapp-float"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           <i className="fa fa-whatsapp sar-header-whatsapp-icon"></i>
//         </a>
//       </div>
//     </>
//   );
// }

// export default Header;


import React from "react";
import "./Header.css";
import { useNavigate } from 'react-router-dom';
import Navbar from "../Navbar/Navbar";

function Header() {
    const navigate = useNavigate();
    return (
        <>
            <nav className="header-navbar">
                <div className="header-nav-item header-nav-item-link">
                    <a className="header-nav-link active header-nav-item-link" href="tel:+918237525097">
                        <i className="fa-solid fa-phone"></i>
                        <span> </span>
                        +91 8237525097
                    </a>
                </div>
                <div className="header-nav-item header-nav-item-link">
                    <a className="header-nav-link header-nav-item-link" href="https://www.google.com/maps/place/SAR+Custom+Services/@18.5536311,73.9494086,15z/data=!4m14!1m7!3m6!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!2sSAR+Custom+Services!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt!3m5!1s0x61b0c1f03cc9ba0b:0x2f218bae41a6d480!8m2!3d18.5536311!4d73.9494086!16s%2Fg%2F11y287zcpt?entry=ttu" target="_blank" rel="noopener noreferrer">
                        <i className="fa-solid fa-location-dot "></i>
                        Pune, Maharashtra
                    </a>
                </div>
                <div className="header-nav-item header-nav-item-link">
                    <a className="header-nav-link header-nav-item-link" href="mailto:support@sarcustomize.in">
                        <i className="fa-solid fa-envelope  "></i>
                        <span> </span>
                        support@sarcustomize.in
                    </a>
                </div>
                <div className="header-social-media-icon header-nav-item-link">
                    <div className="header-nav-link header-nav-item-link">
                        <a className="header-social-media-icon" href="https://www.facebook.com/sarcustomize/?show_switched_toast=0&show_invite_to_follow=0&show_switched_tooltip=0&show_podcast_settings=0&show_community_review_changes=0&show_community_rollback=0&show_follower_visibility_disclosure=0" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-facebook m-2"></i>
                        </a>
                        <a className="header-social-media-icon" href="https://www.instagram.com/sar_customize/" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-square-instagram m-2"></i>
                        </a>
                        <a className="header-social-media-icon" href="https://www.linkedin.com/company/sar-custom-solutions/" target="_blank" rel="noopener noreferrer">
                            <i className="fa-brands fa-linkedin m-2"></i>
                        </a>
                    </div>
                </div>
                <div className="header-admin-page">
                    <button className="header-btn header-login-admin bg-primary btn" onClick={() => navigate('/Admin')}>Login</button>
                </div>
            </nav>
            <Navbar />
            <div>
                <link
                    rel="stylesheet"
                    href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
                />
                <a
                    href="tel:+918237525097"
                    className="header-float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fa-sharp fa-solid fa-phone-volume"></i>
                </a>
            </div>
            <div className="header-whatsapp-phone-icons">
                <a
                    href="https://api.whatsapp.com/send/?phone=918237525097&text&type=phone_number&app_absent=0"
                    className="header-whatsapp-float"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <i className="fa fa-whatsapp header-whatsapp-icon"></i>
                </a>
            </div>
            <Navbar/>
        </>
    );
}

export default Header;
