
import React from "react";
import "./ReactWelcome.css";
import { useNavigate } from "react-router-dom";

const ReactWelcome = () => {
  const navigate = useNavigate();

  const handleApplyNowClick = () => {
    navigate("/Reactmcqtest");
  };

  return (
    <div className="card start-test shadow">
    <div className="card-body">
      <h1 className="card-title">
        Welcome to SAR Custom Solutions!
      </h1>
      <p className="card-text">
        You will be redirected to the MCQ and Coding test.
      </p>
      <p className="card-text">
        Please complete both parts to continue the selection process.
      </p>
      <p>
        <span className="bg-warning fw-lighter">
          Please keep handy soft copy of your documents (Degree certificate and Aadhar Card) to upload it after the completion of test.
        </span>
      </p>
      <div className="rules">
        <h2>Rules and Regulations</h2>
        <ul>
          <li>
            This test consists of 20 multiple-choice questions and 4 coding questions.
          </li>
          <li>
            You have 2 hours and 10 minutes to complete the test.
          </li>
          <li>
            Do not use any external resources during the test.
          </li>
          <li>
            Avoid changing tabs or copying answers, as this will result in automatic submission without warning.
          </li>
          <li>
            Ensure a stable internet connection to prevent early submission.
          </li>
        </ul>
      </div>
      <p>Good luck with your test!</p>
      <button
        onClick={handleApplyNowClick}
        className="start-test-button bg-danger"
      >
        Start Test
      </button>
    </div>
  </div>
  );
};

export default ReactWelcome;
