

  // import React, { useEffect, useState } from 'react';
  // import AdminData from '../AdminData/AdminData';
  // import "./EnquireNow.css";
  // const EnquireNow = () => {
  //   const [contacts, setContacts] = useState([]);
  //   const [error, setError] = useState(null);
  //   const [loading, setLoading] = useState(true);
  //   useEffect(() => {
  //     fetch(`${process.env.REACT_APP_API_URL}/contacts`)
  //       .then(response => {
  //         if (!response.ok) {
  //           throw new Error(`HTTP error! status: ${response.status}`);
  //         }
  //         return response.json();
  //       })
  //       .then(data => {
  //         setContacts(data ?? []);
  //         setLoading(false);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching contacts:', error);
  //         setError('Error fetching contacts');
  //         setLoading(false);
  //       });
  //   }, []);
  //   return (
  //     <>
  //       <div className="AdminLogin" style={{ display: "flex" }}>
  //         <AdminData />
  //       </div>
  //       <div className="container">
  //         <h2 className="get-free-estimate-admin">Enquire Now</h2>
  //         {loading ? (
  //           <p>Loading...</p>
  //         ) : error ? (
  //           <p className="error">{error}</p>
  //         ) : contacts.length > 0 ? (
  //           <table className="table table-bordered custom-table">
  //             <thead className="thead-dark">
  //               <tr>
  //                 <th scope="col">Sr No</th>
  //                 <th scope="col">Name</th>
  //                 <th scope="col">Email</th>
  //                 <th scope="col">Contact Number</th>
  //                 <th scope="col">Message</th>
  //               </tr>
  //             </thead>
  //             <tbody>
  //               {contacts.map((contact, index) => (
  //                 <tr key={contact.id}>
  //                   <td>{index + 1}</td>
  //                   <td>{contact.name}</td>
  //                   <td>{contact.email}</td>
  //                   <td>{contact.contact_number}</td>
  //                   <td>{contact.message}</td>
  //                 </tr>
  //               ))}
  //             </tbody>
  //           </table>
  //         ) : (
  //           <p>No contacts found.</p>
  //         )}
  //       </div>
  //     </>
  //   );
  // }
  // export default EnquireNow;

  
  import React, { useEffect, useState } from 'react';
  import AdminData from '../AdminData/AdminData';
  import "./EnquireNow.css";
  
  const EnquireNow = () => {
    const [contacts, setContacts] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      fetchContacts();
    }, []);
  
    const fetchContacts = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/contacts`);
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.statusText}`);
        }
        const data = await response.json();
        setContacts(data ?? []);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching contacts:', error.message);
        setError('Failed to fetch contacts');
        setLoading(false);
      }
    };
  
    return (
      <>
      <AdminData/>
        <div className="AdminLogin" style={{ display: "flex" }}>
        </div>
        <div className="container">
          <h2 className="get-free-estimate-admin">Enquire Now</h2>
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="error">{error}</p>
          ) : contacts.length > 0 ? (
            <table className="table table-bordered custom-table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Sr No</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Message</th>
                </tr>
              </thead>
              <tbody>
                {contacts.map((contact, index) => (
                  <tr key={contact.id}>
                    <td>{index + 1}</td>
                    <td>{contact.name}</td>
                    <td>{contact.email}</td>
                    <td>{contact.contact_number}</td>
                    <td>{contact.message}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No contacts found.</p>
          )}
        </div>
      </>
    );
  };
  
  export default EnquireNow;
  