// import React, { useState } from "react";
// import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
// import "bootstrap/dist/css/bootstrap.min.css";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./CarrerFormDM.css";
// import Header from "../../../../../component/Header/Header";
// import Footer from "../../../../Footer/Footer";
// // import Header from "../../../../component/Header/Header";
// // import Footer from "../../../Footer/Footer";
// const CarrerFormDM = () => {
//   const navigate = useNavigate();
//   const [formData, setFormData] = useState({
//     full_name: "",
//     email: "",
//     mobile_number: "",
//     experience_level: "",
//     available_starting_date: "",
//     skills: "",
//     resume_upload: null, // Changed from empty string to null
//   });
//   const [emailError, setEmailError] = useState("");
//   const [submitted, setSubmitted] = useState(false);
//   const [errors, setErrors] = useState({});
//   const handleFileUpload = (event) => {
//     const file = event.target.files[0];
//     setFormData({
//       ...formData,
//       resume_upload: file,
//     });
//   };
//   const handleChange = (event) => {
//     const { name, value } = event.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       // Validate form fields
//       if (!validateForm()) {
//         return;
//       }
//       // Check if email already exists
//       const existingApplication = await checkEmailExists(formData.email);
//       if (existingApplication) {
//         setEmailError("Email is already registered.");
//       } else {
//         await submitApplication(formData);
//         setSubmitted(true);
//         localStorage.setItem("userEmail", formData.email);
//         setFormData({
//           full_name: "",
//           email: "",
//           mobile_number: "",
//           experience_level: "",
//           available_starting_date: "",
//           skills: "",
//           resume_upload: null,
//         });
//         navigate("/WelcomeStartTest"); // Navigate to the "/StartTest" page
//       }
//     } catch (error) {
//       console.error("Error submitting application:", error);
//     }
//   };
//   const validateForm = () => {
//     const {
//       full_name,
//       email,
//       mobile_number,
//       experience_level,
//       available_starting_date,
//       skills,
//       resume_upload,
//     } = formData;
//     let valid = true;
//     let errors = {};
//     if (!full_name) {
//       errors.full_name = "Full name is required";
//       valid = false;
//     }
//     if (!email) {
//       errors.email = "Email is required";
//       valid = false;
//     } else if (!validateEmail(email)) {
//       errors.email = "Please enter a valid email address";
//       valid = false;
//     }
//     if (!mobile_number) {
//       errors.mobile_number = "Mobile number is required";
//       valid = false;
//     } else if (!validateMobileNumber(mobile_number)) {
//       errors.mobile_number = "Please enter a valid mobile number";
//       valid = false;
//     }
//     if (!experience_level) {
//       errors.experience_level = "Experience level is required";
//       valid = false;
//     }
//     if (!available_starting_date) {
//       errors.available_starting_date = "Available starting date is required";
//       valid = false;
//     }
//     if (!skills) {
//       errors.skills = "Skills are required";
//       valid = false;
//     }
//     if (!resume_upload) {
//       errors.resume_upload = "Resume upload is required";
//       valid = false;
//     }
//     setErrors(errors);
//     return valid;
//   };
//   const validateEmail = (email) => {
//     // Basic email validation using regex
//     const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
//     return regex.test(email);
//   };
//   const validateMobileNumber = (mobile_number) => {
//     // Basic mobile number validation using regex
//     const regex = /^[0-9]{10}$/;
//     return regex.test(mobile_number);
//   };
//   const checkEmailExists = async (email) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/api/dm-application-entries/${email}`
//       );
//       return response.data; // Returns true if email exists, false otherwise
//     } catch (error) {
//       console.error("Error checking email:", error);
//       return false; // Assume email does not exist on error
//     }
//   };
//   const submitApplication = async (data) => {
//     try {
//       const formData = new FormData();
//       formData.append("full_name", data.full_name);
//       formData.append("email", data.email);
//       formData.append("mobile_number", data.mobile_number);
//       formData.append("experience_level", data.experience_level);
//       formData.append("available_starting_date", data.available_starting_date);
//       formData.append("skills", data.skills);
//       formData.append("resume_upload", data.resume_upload);
//       await axios.post(
//         `${process.env.REACT_APP_API_URL}/api/dm-application-entries`,
//         formData,
//         {
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );
//     } catch (error) {
//       throw new Error("Failed to submit application");
//     }
//   };
//   return (
//     <>
//       <Header />
//       <Container className="career-form mt-5">
//         <Card className="career-card-form p-4 shadow-sm border-0 rounded">
//           <Card.Body>
//             <h1 className="career-card-title-form text-center mb-4">
//               Apply Now
//             </h1>
//             <Form onSubmit={handleSubmit}>
//               {/* <Form.Group controlId="resumeUpload">
//                 <Form.Control
//                   type="file"
//                   onChange={handleFileUpload}
//                   className="career-file-input"
//                   required
//                   isInvalid={!!errors.resume_upload}
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.resume_upload}
//                 </Form.Control.Feedback>
//               </Form.Group> */}
//               <Row>
//                 <Col md={12}>
//                   <Form.Group controlId="resumeUpload">
//                     <Form.Label>Upload Your Resume</Form.Label>
//                     <Form.Control
//                       type="file"
//                       onChange={handleFileUpload}
//                       className="career-file-input"
//                       required
//                       isInvalid={!!errors.resume_upload}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.resume_upload}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Row>
//                 <Col md={12}>
//                   <Form.Group controlId="full_name">
//                     <Form.Control
//                       type="text"
//                       name="full_name"
//                       placeholder="Full Name"
//                       value={formData.full_name}
//                       onChange={handleChange}
//                       className="career-form-control"
//                       isInvalid={!!errors.full_name}
//                       required
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.full_name}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Form.Group controlId="email">
//                 <Form.Control
//                   type="email"
//                   name="email"
//                   placeholder="Email"
//                   value={formData.email}
//                   onChange={handleChange}
//                   className="career-form-control"
//                   isInvalid={!!errors.email || !!emailError}
//                   required
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.email || emailError}
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Group controlId="mobile_number">
//                 <Form.Control
//                   type="text"
//                   name="mobile_number"
//                   placeholder="Mobile Number"
//                   value={formData.mobile_number}
//                   onChange={handleChange}
//                   className="career-form-control"
//                   isInvalid={!!errors.mobile_number}
//                   required
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.mobile_number}
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Row>
//                 <Col md={6}>
//                   <Form.Group controlId="experience_level">
//                     <Form.Control
//                       as="select"
//                       name="experience_level"
//                       value={formData.experience_level}
//                       onChange={handleChange}
//                       className="career-form-control"
//                       isInvalid={!!errors.experience_level}
//                       required
//                     >
//                       <option value="">Experience Level</option>
//                       <option value="Beginner">Beginner</option>
//                       <option value="Intermediate">Intermediate</option>
//                       <option value="Expert">Expert</option>
//                     </Form.Control>
//                     <Form.Control.Feedback type="invalid">
//                       {errors.experience_level}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>
//                 <Col md={6}>
//                   <Form.Group controlId="available_starting_date">
//                     <Form.Control
//                       type="text"
//                       name="available_starting_date"
//                       placeholder="Available Date to Join"
//                       value={formData.available_starting_date}
//                       onChange={handleChange}
//                       onFocus={(e) => (e.target.type = "date")}
//                       onBlur={(e) => (e.target.type = "text")}
//                       className={`career-form-control ${
//                         errors.available_starting_date && "is-invalid"
//                       }`}
//                       isInvalid={!!errors.available_starting_date}
//                     />
//                     <Form.Control.Feedback type="invalid">
//                       {errors.available_starting_date}
//                     </Form.Control.Feedback>
//                   </Form.Group>
//                 </Col>
//               </Row>
//               <Form.Group controlId="skills">
//                 <Form.Control
//                   type="text"
//                   name="skills"
//                   placeholder="Skills"
//                   value={formData.skills}
//                   onChange={handleChange}
//                   className="career-form-control"
//                   isInvalid={!!errors.skills}
//                   required
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   {errors.skills}
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <Form.Check
//                 type="checkbox"
//                 label="I confirm that I have read, understand and agree to SAR Privacy Policy."
//                 required
//                 className="font-weight-bold"
//               />
//               <div className="text-center ">
//                 <Button type="submit" className="career-btn-lg-form-sar">
//                   Submit
//                 </Button>
//               </div>
//             </Form>
//             {submitted && (
//               <div className="mt-3 text-center">
//                 <Card.Text>
//                   Your application has been submitted. Thank you!
//                 </Card.Text>
//               </div>
//             )}
//           </Card.Body>
//         </Card>
//       </Container>
//       <Footer />
//     </>
//   );
// };
// export default CarrerFormDM;










import React, { useState } from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./CarrerFormDM.css";
import Footer from "../../../../Footer/Footer";
const CarrerFormDM = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    mobile_number: "",
    experience_level: "",
    available_starting_date: "",
    skills: "",
    resume_upload: null, // Changed from empty string to null
  });
  const [emailError, setEmailError] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type !== "application/pdf") {
      setErrors({
        ...errors,
        resume_upload: "Only PDF files are allowed",
      });
    } else {
      setErrors({
        ...errors,
        resume_upload: null,
      });
      setFormData({
        ...formData,
        resume_upload: file,
      });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Validate form fields
      if (!validateForm()) {
        return;
      }
      // Check if email already exists
      const existingApplication = await checkEmailExists(formData.email);
      if (existingApplication) {
        setEmailError("Email is already registered.");
      } else {
        await submitApplication(formData);
        setSubmitted(true);
        localStorage.setItem("userEmail", formData.email);
        setFormData({
          full_name: "",
          email: "",
          mobile_number: "",
          experience_level: "",
          available_starting_date: "",
          skills: "",
          resume_upload: null,
        });
        navigate("/WelcomeStartTest"); // Navigate to the "/StartTest" page
      }
    } catch (error) {
      console.error("Error submitting application:", error);
    }
  };
  const validateForm = () => {
    const {
      full_name,
      email,
      mobile_number,
      experience_level,
      available_starting_date,
      skills,
      resume_upload,
    } = formData;
    let valid = true;
    let errors = {};
    if (!full_name) {
      errors.full_name = "Full name is required";
      valid = false;
    }
    if (!email) {
      errors.email = "Email is required";
      valid = false;
    } else if (!validateEmail(email)) {
      errors.email = "Please enter a valid email address";
      valid = false;
    }
    if (!mobile_number) {
      errors.mobile_number = "Mobile number is required";
      valid = false;
    } else if (!validateMobileNumber(mobile_number)) {
      errors.mobile_number = "Please enter a valid mobile number";
      valid = false;
    }
    if (!experience_level) {
      errors.experience_level = "Experience level is required";
      valid = false;
    }
    if (!available_starting_date) {
      errors.available_starting_date = "Available starting date is required";
      valid = false;
    }
    if (!skills) {
      errors.skills = "Skills are required";
      valid = false;
    }
    if (!resume_upload) {
      errors.resume_upload = "Resume upload is required";
      valid = false;
    }
    setErrors(errors);
    return valid;
  };
  const validateEmail = (email) => {
    // Basic email validation using regex
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };
  const validateMobileNumber = (mobile_number) => {
    // Basic mobile number validation using regex
    const regex = /^[0-9]{10}$/;
    return regex.test(mobile_number);
  };
  const checkEmailExists = async (email) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/dm-application-entries/${email}`
      );
      return response.data; // Returns true if email exists, false otherwise
    } catch (error) {
      console.error("Error checking email:", error);
      return false; // Assume email does not exist on error
    }
  };
  const submitApplication = async (data) => {
    try {
      const formData = new FormData();
      formData.append("full_name", data.full_name);
      formData.append("email", data.email);
      formData.append("mobile_number", data.mobile_number);
      formData.append("experience_level", data.experience_level);
      formData.append("available_starting_date", data.available_starting_date);
      formData.append("skills", data.skills);
      formData.append("resume_upload", data.resume_upload);
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/dm-application-entries`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      throw new Error("Failed to submit application");
    }
  };
  return (
    <>
      {/* <Header /> */}
      <Container className="career-form mt-5">
        <Card className="career-card-form p-4 shadow-sm border-0 rounded">
          <Card.Body>
            <h1 className="career-card-title-form text-center mt-4 mb-4">
              Apply Now
            </h1>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="resumeUpload">
                    <Form.Label>Upload Your Resume</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleFileUpload}
                      className="career-file-input"
                      required
                      isInvalid={!!errors.resume_upload}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.resume_upload}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group controlId="full_name">
                    <Form.Control
                      type="text"
                      name="full_name"
                      placeholder="Full Name"
                      value={formData.full_name}
                      onChange={handleChange}
                      className="career-form-control"
                      isInvalid={!!errors.full_name}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.full_name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="email">
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  className="career-form-control"
                  isInvalid={!!errors.email || !!emailError}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email || emailError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="mobile_number">
                <Form.Control
                  type="text"
                  name="mobile_number"
                  placeholder="Mobile Number"
                  value={formData.mobile_number}
                  onChange={handleChange}
                  className="career-form-control"
                  isInvalid={!!errors.mobile_number}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.mobile_number}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col md={6}>
                  <Form.Group controlId="experience_level">
                    <Form.Control
                      as="select"
                      name="experience_level"
                      value={formData.experience_level}
                      onChange={handleChange}
                      className="career-form-control"
                      isInvalid={!!errors.experience_level}
                      required
                    >
                      <option value="">Experience Level</option>
                      <option value="Beginner">Beginner</option>
                      <option value="Intermediate">Intermediate</option>
                      <option value="Expert">Expert</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.experience_level}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group controlId="available_starting_date">
                    <Form.Control
                      type="text"
                      name="available_starting_date"
                      placeholder="Available Date to Join"
                      value={formData.available_starting_date}
                      onChange={handleChange}
                      onFocus={(e) => (e.target.type = "date")}
                      onBlur={(e) => (e.target.type = "text")}
                      className={`career-form-control ${
                        errors.available_starting_date && "is-invalid"
                      }`}
                      isInvalid={!!errors.available_starting_date}
                    />
                    <Form.Control.Feedback type="
                    invalid">
                      {errors.available_starting_date}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group controlId="skills">
                <Form.Control
                  type="text"
                  name="skills"
                  placeholder="Skills"
                  value={formData.skills}
                  onChange={handleChange}
                  className="career-form-control"
                  isInvalid={!!errors.skills}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.skills}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Check
                type="checkbox"
                label="I confirm that I have read, understand and agree to SAR Privacy Policy."
                required
                className="font-weight-bold"
              />
              <div className="text-center ">
                <Button type="submit" className="career-btn-lg-form-sar">
                  Submit
                </Button>
              </div>
            </Form>
            {submitted && (
              <div className="mt-3 text-center">
                <Card.Text>
                  Your application has been submitted. Thank you!
                </Card.Text>
              </div>
            )}
          </Card.Body>
        </Card>
      </Container>
      <Footer />
    </>
  );
};
export default CarrerFormDM;