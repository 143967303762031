import React from "react";
import "./UIWelcome.css"
import { useLocation, useNavigate } from "react-router-dom";

const UIWelcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { flag } = location.state || {};

  const handleApplyNowClick = () => {
    if (flag === "uiux") {
      navigate("/UImcqtest");
    } else {
      // handle other cases or navigate to a different route
      navigate("/UImcqtest");
    }
  };

  return (
    <div className="card welcome-start-test shadow">
      <div className="card-body welcome-start-test">
        <h1 className="card-title welcome-start-test">
          Welcome to SAR Custom Solutions!
        </h1>
        <p className="card-text welcome-start-test">
          You will be redirected to the MCQ test.
        </p>
        <p className="card-text welcome-start-test">
          Please complete both parts to continue the selection process.
        </p>
        <p>
          <span className="bg-warning fw-lighter">
            Please keep handy soft copy of your documents (Degree certificate
            and Aadhar Card) to upload it after the completion of the test.
          </span>
        </p>
        <div className="rules welcome-start-test">
          <h2 className="welcome-start-test">Rules and Regulations</h2>
          <ul className="welcome-start-test">
            <li className="welcome-start-test">
              This test consists of 20 multiple-choice questions.
            </li>
            <li className="welcome-start-test">
              You have 40 minutes to complete the test.
            </li>
            <li className="welcome-start-test">
              Do not use any external resources during the test.
            </li>
            <li className="welcome-start-test">
              Avoid changing tabs this will result in automatic submission
              without warning.
            </li>
            <li className="welcome-start-test">
              Ensure a stable internet connection to prevent early submission.
            </li>
          </ul>
        </div>
        <p className="welcome-start-test">Good luck with your test!</p>
        <button
          onClick={handleApplyNowClick}
          className="welcome-start-test-button bg-danger"
        >
          Start Test
        </button>
      </div>
    </div>
  );
};

export default UIWelcome;
