
// import React, { useState } from 'react';
// import './Admin.css';
// import { useNavigate } from 'react-router-dom';
// import Header from '../../component/Header/Header';

// const Admin = () => {
//   const navigate = useNavigate();

//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [isLoggedIn, setIsLoggedIn] = useState(false);

//   const adminUsername = 'admin@sarcustomsolutions';
//   const adminPassword = 'Admin@1234#';

//   const handleLogin = (e) => {
//     e.preventDefault();
//     if (username === adminUsername && password === adminPassword) {
//       console.log('Admin logged in successfully');
//       setIsLoggedIn(true);
//       navigate("../DocumentUploadAdmin");
//     } else {
//       alert('Invalid username or password');
//     }
//   };

//   if (isLoggedIn) {
//     return <div>Welcome, Admin!</div>;
//   }

//   return (
//     <>
//       <Header />
//       <div className="Admin-login-container">
//         <div className="Admin-img-admin-page">
//           <img src="./img/admin1img.png" alt="Admin" />
//         </div>
//         <div className="Admin-login-form-container">
//           <div className="Admin-login-form">
//             <h2 className="Admin-login-heading">Admin Login</h2>
//             <form onSubmit={handleLogin}>
//               <div className="Admin-form-group">
//                 <label htmlFor="username">Username:</label>
//                 <input
//                   type="text"
//                   id="username"
//                   name="username"
//                   placeholder="Username"
//                   value={username}
//                   onChange={(e) => setUsername(e.target.value)}
//                 />
//               </div>
//               <div className="Admin-form-group">
//                 <label htmlFor="password">Password:</label>
//                 <input
//                   type="password"
//                   id="password"
//                   name="password"
//                   placeholder="Password"
//                   value={password}
//                   onChange={(e) => setPassword(e.target.value)}
//                 />
//               </div>
//               <div className="Admin-form-group">
//                 <button className="Admin-login-button" type="submit">
//                   Login
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
      
//     </>
//   );
// };

// export default Admin;
import React, { useState } from 'react';
import './Admin.css';
import { useNavigate } from 'react-router-dom';
const Admin = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const adminUsername = 'admin@sarcustomsolutions';
  const adminPassword = 'Adm1n@sar4321#';
  const handleLogin = (e) => {
    e.preventDefault();
    if (username === adminUsername && password === adminPassword) {
      console.log('Admin logged in successfully');
      setIsLoggedIn(true);
      // navigate("../DocumentUploadAdmin");
      navigate("../AdminDashboard");
    } else {
      alert('Invalid username or password');
    }
  };
  if (isLoggedIn) {
    return <div>Welcome, Admin!</div>;
  }
  return (
    <>
      <div className="Admin-login-container">
        <div className="Admin-img-admin-page">
          <img src="./img/admin1img.png" alt="Admin" />
        </div>
        <div className="Admin-login-form-container">
          <div className="Admin-login-form">
            <h2 className="Admin-login-heading">Admin Login</h2>
            <form onSubmit={handleLogin}>
              <div className="Admin-form-group">
                <label htmlFor="username">Username:</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="Admin-form-group">
                <label htmlFor="password">Password:</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="Admin-form-group">
                <button className="Admin-login-button" type="submit">
                  Login
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Admin;









