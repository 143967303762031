// import React from 'react';
// import { Card, Button } from 'react-bootstrap';
// import { Link } from 'react-router-dom'; // Assuming you use React Router for navigation
// import './AdminDashboard.css'; // Your custom CSS file
// import AdminData from '../AdminData/AdminData';
// const AdminDashboard = () => {
//   return (
//     <>
//       <AdminData/>
//       <div className="admin-dashboard">
//         <Card className="candidate-card1">
//           <Card.Body>
//             <div className="card-header">
//               <Card.Title>Java Candidates</Card.Title>
//               <Link to="/DocumentUploadAdmin">
//                 <Button className="arrow-button">
//                   <i className="fas fa-arrow-right"></i>
//                 </Button>
//               </Link>
//             </div>
//             <Card.Img variant="top" src="/JavaImg.jpg" className="java-img" />
//           </Card.Body>
//         </Card>
//         <Card className="candidate-card2">
//           <Card.Body>
//             <div className="card-header">
//               <Card.Title>Marketing Candidates</Card.Title>
//               <Link to="/DocumentUploadAdminDM">
//                 <Button className="arrow-button">
//                   <i className="fas fa-arrow-right"></i>
//                 </Button>
//               </Link>
//             </div>
//             <Card.Img variant="top" src="/digital-marketing-with-icons-business-people.jpg" />
//           </Card.Body>
//         </Card>
//         <Card className="candidate-card3">
//           <Card.Body>
//             <div className="card-header">
//               <Card.Title>UI/UX Candidates</Card.Title>
//               <Link to="/DocumentUploadAdminUI">
//                 <Button className="arrow-button">
//                   <i className="fas fa-arrow-right"></i>
//                 </Button>
//               </Link>
//             </div>
//             <Card.Img variant="top" className="java-img" src="/uiux.jpg" />
//           </Card.Body>
//         </Card>
//       </div>
//     </>
//   );
// };
// export default AdminDashboard;





import React from 'react';
import { Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Assuming you use React Router for navigation
import './AdminDashboard.css'; // Your custom CSS file
import AdminData from '../AdminData/AdminData';

const AdminDashboard = () => {
  return (
    <>
      <AdminData />
      <div className="admin-dashboard">
        <Link to="/DocumentUploadAdmin" className="card-link">
          <Card className="candidate-card">
            <Card.Body>
              <div className="card-header">
                <Card.Title>Java Candidates</Card.Title>
                <Button className="arrow-button">
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </div>
              <Card.Img variant="top" src="/JavaImg.jpg" className="candidate-img" />
            </Card.Body>
          </Card>
        </Link>
        <Link to="/DocumentUploadAdminDM" className="card-link">
          <Card className="candidate-card">
            <Card.Body>
              <div className="card-header">
                <Card.Title>MarketingCandidates</Card.Title>
                <Button className="arrow-button">
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </div>
              <Card.Img variant="top" src="/digital-marketing-with-icons-business-people.jpg" className="candidate-img" />
            </Card.Body>
          </Card>
        </Link>
        <Link to="/DocumentUploadAdminUI" className="card-link">
          <Card className="candidate-card">
            <Card.Body>
              <div className="card-header">
                <Card.Title>UI/UX Candidates</Card.Title>
                <Button className="arrow-button">
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </div>
              <Card.Img variant="top" src="/uiux.jpg" className="candidate-img" />
            </Card.Body>
          </Card>
        </Link>
        <Link to="/ReactDocumentUploadAdmin" className="card-link">
          <Card className="candidate-card">
            <Card.Body>
              <div className="card-header">
                <Card.Title>React Js Candidates</Card.Title>
                <Button className="arrow-button">
                  <i className="fas fa-arrow-right"></i>
                </Button>
              </div>
              <Card.Img variant="top" src="/ReactJS.jpg" className="candidate-img" />
            </Card.Body>
          </Card>
        </Link>
      </div>
    </>
  );
};

export default AdminDashboard;






