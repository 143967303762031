// // // import React, { useState } from "react";
// // // import "./Navbar.css";
// // // import { Link } from "react-router-dom";
// // // function Navbar() {
// // //   const [isOpen, setIsOpen] = useState(false);
// // //   const toggleMenu = () => {
// // //     setIsOpen(!isOpen);
// // //   };
// // //   return (
// // //     <>
// // //       <nav
// // //         style={{ position: "fixed", zIndex: "9999", top: "55px" }}
// // //         className="navbar sar-custom navbar-expand-lg navbar-light bg-light"
// // //       >
// // //         <div className="container py-2">
// // //           <img className="lolo-img-sar" src="./img/SAR-logo.png" alt="Logo" />
// // //           <button
// // //             className={`navbar-toggler ${isOpen ? "open" : ""}`}
// // //             type="button"
// // //             onClick={toggleMenu}
// // //           >
// // //             <span className="navbar-toggler-icon"></span>
// // //           </button>
// // //           <div
// // //             className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
// // //             id="navbarSupportedContent"
// // //           >
// // //             <ul className="navbar-nav ms-auto nav-ul">
// // //               <li className="nav-item active">
// // //                 <Link className="nav-link" to="/" onClick={toggleMenu}>
// // //                   HOME <span className="sr-only">(current)</span>
// // //                 </Link>
// // //                 <hr />
// // //               </li>
// // //               <li className="nav-item">
// // //                 <Link className="nav-link" to="/Solution" onClick={toggleMenu}>
// // //                   WHAT WE DO
// // //                 </Link>
// // //                 <hr />
// // //               </li>
// // //               <li className="nav-item">
// // //                 <Link className="nav-link" to="/about_us" onClick={toggleMenu}>
// // //                   ABOUT US
// // //                 </Link>
// // //                 <hr />
// // //               </li>
// // //               <li className="nav-item">
// // //                 <Link className="nav-link" to="/Blogs" onClick={toggleMenu}>
// // //                   BLOGS
// // //                 </Link>
// // //                 <hr />
// // //               </li>
// // //               <li className="nav-item">
// // //                 <Link className="nav-link" to="/Carrer" onClick={toggleMenu}>
// // //                   CARRER
// // //                 </Link>
// // //                 <hr />
// // //               </li>
// // //               <li className="nav-item">
// // //                 <Link className="nav-link" to="/Contact" onClick={toggleMenu}>
// // //                   CONTACT US
// // //                 </Link>
// // //                 <hr />
// // //               </li>

// // //               {/* <li className="nav-item">
// // //                 <Link className="nav-link" to="/Offers" onClick={toggleMenu}>
// // //                   OFFERS
// // //                 </Link>
// // //                 <hr />
// // //               </li> */}

// // //             </ul>
// // //             <form className="form-inline my-2 my-lg-0 ms-5">
// // //               <button className="btn bg-danger btn my-2 my-sm-0" type="submit">
// // //                 <a className="call-now-btn" href="tel:+918237525097">
// // //                   CALL NOW <i className="fa-solid fa-phone-volume"></i>
// // //                 </a>
// // //               </button>
// // //             </form>
// // //           </div>
// // //         </div>
// // //       </nav>
// // //     </>
// // //   );
// // // }
// // // export default Navbar;


// import React, { useState } from "react";
// import "./Navbar.css";
// import { Link } from "react-router-dom";

// function Navbar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav
//       style={{ position: "fixed", zIndex: "9999", top: "55px" }}
//       className="navbar sar-custom navbar-expand-lg navbar-light bg-light"
//     >
//       <div className="container py-2">
//         <img className="lolo-img-sar" src="./img/SAR-logo.png" alt="Logo" />
//         <button
//           className={`navbar-toggler ${isOpen ? "open" : ""}`}
//           type="button"
//           onClick={toggleMenu}
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div
//           className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
//           id="navbarSupportedContent"
//         >
//           <ul className="navbar-nav ms-auto nav-ul">
//             <li className="nav-item active">
//               <Link className="nav-link" to="/" onClick={toggleMenu}>
//                 HOME <span className="sr-only">(current)</span>
//               </Link>
//               <hr />
//             </li>
//             {/* <li className="nav-item">
//               <Link className="nav-link" to="/Solution" onClick={toggleMenu}>
//                 WHAT WE DO
//               </Link>
//               <hr />
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link" to="/about_us" onClick={toggleMenu}>
//                 ABOUT US
//               </Link>
//               <hr />
//             </li> */}
//             {/* <li className="nav-item">
//               <Link className="nav-link" to="/Blogs" onClick={toggleMenu}>
//                 BLOGS
//               </Link>
//               <hr />
//             </li> */}
//             <li className="nav-item">
//               <Link className="nav-link" to="/Carrer" onClick={toggleMenu}>
//                 CARRER
//               </Link>
//               <hr />
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link" to="/Contact" onClick={toggleMenu}>
//                 CONTACT US
//               </Link>
//               <hr />
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link" to="/Demo" onClick={toggleMenu}>
//                 DEMO
//               </Link>
//               <hr />
//             </li>
//           </ul>
//           {/* <form className="form-inline my-2 my-lg-0 ms-5">
//             <button className="btn bg-danger my-2 my-sm-0" type="submit">
//               <a className="call-now-btn" href="tel:+918237525097">
//                 CALL NOW <i className="fa-solid fa-phone-volume"></i>
//               </a>
//             </button>
//           </form> */}
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;

import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css"; // Custom styles if needed

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleNavClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
     {/* style={{ position: "fixed", zIndex: "9999", top: "55px" }}> */}
      <div className="container-fluid">
        <Link className="navbar-brand" to="/">
          <img src="./img/SAR-logo.png" alt="Logo" className="logo-img-sar" />
        </Link>
        <button
          className={`navbar-toggler ${isOpen ? "open" : ""}`}
          type="button"
          onClick={toggleMenu}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
        id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item active">
              <Link
                className="nav-link"
                to="/"
                onClick={handleNavClick}
              >
                HOME <span className="sr-only">(current)</span>
              </Link>
            </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/Carrer"
                  onClick={handleNavClick}
                >
                  CARRER
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/Contact"
                  onClick={handleNavClick}
                >
                  CONTACT US
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link"
                  to="/Demo"
                  onClick={handleNavClick}
                >
                  DEMO
                </Link>
              </li>
          </ul>
          {/* Example of additional items if needed */}
          {/* <form className="d-flex">
            <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search" />
            <button className="btn btn-outline-success" type="submit">Search</button>
          </form> */}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { HashLink } from 'react-router-hash-link';
// import "./Navbar.css";

// function Navbar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const toggleMenu = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav
//       style={{ position: "fixed", zIndex: "9999", top: "55px" }}
//       className="navbar sar-custom navbar-expand-lg navbar-light bg-light"
//     >
//       <div className="container py-2">
//         <img className="lolo-img-sar" src="./img/SAR-logo.png" alt="Logo" />
//         <button
//           className={`navbar-toggler ${isOpen ? "open" : ""}`}
//           type="button"
//           onClick={toggleMenu}
//         >
//           <span className="navbar-toggler-icon"></span>
//         </button>
//         <div
//           className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
//           id="navbarSupportedContent"
//         >
//           <ul className="navbar-nav ms-auto nav-ul">
//             <li className="nav-item active">
//               <Link className="nav-link" to="/" onClick={toggleMenu}>
//                 HOME <span className="sr-only">(current)</span>
//               </Link>
//               <hr />
//             </li>
//             {/* <li className="nav-item">
//               <Link className="nav-link" to="/Solution" onClick={toggleMenu}>
//                 WHAT WE DO
//               </Link>
//               <hr />
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link" to="/about_us" onClick={toggleMenu}>
//                 ABOUT US
//               </Link>
//               <hr />
//             </li> */}
//             {/* <li className="nav-item">
//               <Link className="nav-link" to="/Blogs" onClick={toggleMenu}>
//                 BLOGS
//               </Link>
//               <hr />
//             </li> */}
//             <li className="nav-item">
//               <Link className="nav-link" to="/Carrer" onClick={toggleMenu}>
//                 CARRER
//               </Link>
//               <hr />
//             </li>
//             <li className="nav-item">
//               <HashLink className="nav-link" to="/#contact" onClick={toggleMenu}>
//                 CONTACT US
//               </HashLink>
//               <hr />
//             </li>
//             <li className="nav-item">
//               <Link className="nav-link" to="/Demo" onClick={toggleMenu}>
//                 DEMO
//               </Link>
//               <hr />
//             </li>
//           </ul>
//           <form className="form-inline my-2 my-lg-0 ms-5">
//             <button className="btn bg-danger my-2 my-sm-0" type="submit">
//               <a className="call-now-btn" href="tel:+918237525097">
//                 CALL NOW <i className="fa-solid fa-phone-volume"></i>
//               </a>
//             </button>
//           </form>
//         </div>
//       </div>
//     </nav>
//   );
// }

// export default Navbar;


// import React, { useState } from 'react';
// import './Navbar.css';

// const Navbar = () => {
//   const [isOpen, setIsOpen] = useState(false);

//   const toggleBurger = () => {
//     setIsOpen(!isOpen);
//   };

//   return (
//     <nav className="navbar">
//       <div className="logo">
//         <img className='ml-4' src="./img/SAR-logo.png" alt="Logo" />
//       </div>
//       <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
//         <li><a href="#home">Home</a></li>
//         <li><a href="#career">Career</a></li>
//         <li><a href="#demo">Demo</a></li>
//         <li><a href="#contact">Contact Us</a></li>
//       </ul>
   
//     </nav>
//   );
// };

// export default Navbar;
