
// import React from "react";
// import { Link } from "react-router-dom";
// import "./AdminData.css"; // Ensure you have a corresponding CSS file for custom styles

// function AdminData() {
//   return (
//     <div className="d-flex">
//       <nav className="sidebar navbar-dark bg-danger ">
//         <div className="sidebar-sticky">
//           <ul className="nav flex-column">
//             {/* <li className="nav-item">
//               <Link to="/GetFreeEstimate" className="nav-link">
//               <i class="fas fa-envelope"></i> <br /> <span className="a1 text-white">Get FREE Estimate</span>
//               </Link>
//             </li> */}
//             <li className="nav-item">
//               <Link to="/EnquireNow" className="nav-link">
//               <i class="fa-solid fa-user"></i> <br /> <span className="a1 text-white">Enquire from Data</span>
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link to="/DocumentUploadAdmin" className="nav-link">
//               <i class="fa-solid fa-user-tie"></i>  <br /> <span className="a1 text-white">Document Upload Admin</span>
//               </Link>
//             </li>
//             <li className="nav-item">
//               <Link to="/" className="nav-link">
//               <i class="fa-solid fa-right-from-bracket"></i> <span className="a1 primary text-white">Logout</span>
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </nav>
//       <div className="main-content">
//         {/* Your main content goes here */}
//       </div>
//     </div>
//   );
// }

// export default AdminData;


// // import React from "react";
// // import { Link } from "react-router-dom";

// // function AdminData() {
// //   return (
// //     <nav className="navbar navbar-expand-lg     position: fixed; Adminnavbar-sar navbar-dark bg-dark">
// //       <div className="container-fluid">
// //         <div className="navbar-nav w-100 justify-content-between">
// //           <Link to="/GetFreeEstimate" className="nav-link">
// //           <span className="a1 text-white">Get FREE Estimate</span>
// //           </Link>
// //           <Link to="/EnquireNow" className="nav-link">
// //             <span className="a1 text-white">Enquire from Data</span>
// //           </Link>
// //           <Link to="/DocumentUploadAdmin" className="nav-link">
// //             <span className="a1 text-white">Document Upload Admin</span>
// //           </Link>
// //           <Link to="/" className="nav-link">
// //             <span className="a1 primary text-white">Logout</span>
// //           </Link>
// //         </div>
// //       </div>
// //     </nav>
// //   );
// // }

// // export default AdminData;


// // import React, { useState } from "react";
// // import "./AdminData.css";
// // import { faUserCircle, faTasks, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import sar_logo from "./img/sar.jpg";
// // import dummy_profile from "./img/dummy-profile.png";
// // import { Link } from "react-router-dom";

// // const AdminData = () => {
// //   const [showOptions, setShowOptions] = useState(false);

// //   const handleReportsHover = (isHovering) => {
// //     setShowOptions(isHovering);
// //   };

// //   return (
// //     <div className="vertical-navbar">
// //       <div className="company-info">
// //         <div className="company-logo">
// //           <img src={sar_logo} alt="Company Logo" />
// //         </div>
// //         <div className="company-name">
// //           <h3>SAR Custom <br /> Solutions</h3>
// //         </div>
// //       </div>
// //       <p className="a-link" id="prf">
// //         <FontAwesomeIcon className="icons" icon={faUserCircle} />
// //       </p>
// //       <div className="profile">
// //         <Link className="profile-link" to="#">
// //           <img src={dummy_profile} alt="Profile" />
// //           <p>SAR Custom</p>
// //           <hr />
// //         </Link>
// //       </div>
// //       <div className="nav-links">
// //         <div
// //           className="a-link"
// //           id="reports"
// //           onMouseEnter={() => handleReportsHover(true)}
// //           onMouseLeave={() => handleReportsHover(false)}
// //         >
// //           <Link to='/Admin' className="reports-1">
// //             <FontAwesomeIcon className="icons" icon={faUser} />
// //             <span className="link-name">AdminData</span>
// //           </Link>
// //           {showOptions && (
// //             <div className="options">
// //               <Link to='/GetFreeEstimate' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Get FREE Estimate</span>
// //               </Link>
// //               <Link to='/EnquireNow' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Enquire from Data</span>
// //               </Link>
// //               <Link to='/DocumentUploadAdmin' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Document Upload Admin</span>
// //               </Link>
// //             </div>
// //           )}
// //         </div>

// //         <Link className="a-link" to="/">
// //           <FontAwesomeIcon className="icons" icon={faSignOutAlt} />
// //           <span className="link-name">Logout</span>
// //         </Link>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AdminData;

// // import React, { useState } from "react";
// // import "./AdminData.css";
// // import { faUserCircle, faTasks, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import sar_logo from "./img/sar.jpg";
// // import dummy_profile from "./img/dummy-profile.png";
// // import { Link } from "react-router-dom";

// // const AdminData = () => {
// //   const [showOptions, setShowOptions] = useState(false);

// //   const handleReportsHover = (isHovering) => {
// //     setShowOptions(isHovering);
// //   };

// //   return (
// //     <div className="vertical-navbar">
// //       <div className="company-info">
// //         <div className="company-logo">
// //           <img src={sar_logo} alt="Company Logo" />
// //         </div>
// //         <div className="company-name">
// //           <h3>SAR Custom <br /> Solutions</h3>
// //         </div>
// //       </div>
// //       <p className="a-link" id="prf">
// //         <FontAwesomeIcon className="icons" icon={faUserCircle} />
// //       </p>
// //       <div className="profile">
// //         <Link className="profile-link" to="#">
// //           <img src={dummy_profile} alt="Profile" />
// //           <p>SAR Custom</p>
// //           <hr />
// //         </Link>
// //       </div>
// //       <div className="nav-links">
// //         <div
// //           className="a-link"
// //           id="reports"
// //           onMouseEnter={() => handleReportsHover(true)}
// //           onMouseLeave={() => handleReportsHover(false)}
// //         >
// //           <FontAwesomeIcon className="icons" icon={faUser} />
// //           <span className="link-name">AdminData</span>
// //           {showOptions && (
// //             <div className="options">
// //               <Link to='/GetFreeEstimate' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Get FREE Estimate</span>
// //               </Link>
// //               <Link to='/EnquireNow' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Enquire from Data</span>
// //               </Link>
// //               <Link to='/DocumentUploadAdmin' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Document Upload Admin</span>
// //               </Link>
// //             </div>
// //           )}
// //         </div>
// //         <Link className="a-link" to="/">
// //           <FontAwesomeIcon className="icons" icon={faSignOutAlt} />
// //           <span className="link-name">Logout</span>
// //         </Link>
// //       </div>
// //     // </div>
// //   );
// // };

// // export default AdminData;

// // import React, { useState } from "react";
// // import "./AdminData.css";
// // import { faUserCircle, faTasks, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
// // import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// // import sar_logo from "./img/sar.jpg";
// // import dummy_profile from "./img/dummy-profile.png";
// // import { Link } from "react-router-dom";

// // const AdminData = () => {
// //   const [showOptions, setShowOptions] = useState(false);

// //   const handleReportsHover = (isHovering) => {
// //     setShowOptions(isHovering);
// //   };

// //   return (
// //     <div className="vertical-navbar">
// //       <div className="company-info">
// //         <div className="company-logo">
// //           <img src={sar_logo} alt="Company Logo" />
// //         </div>
// //         <div className="company-name">
// //           <h3>
// //             SAR Custom <br /> Solutions
// //           </h3>
// //         </div>
// //       </div>
// //       <p className="a-link" id="prf">
// //         <FontAwesomeIcon className="icons" icon={faUserCircle} />
// //       </p>
// //       <div className="profile">
// //         <Link className="profile-link" to="#">
// //           <img src={dummy_profile} alt="Profile" />
// //           <p>SAR Custom</p>
// //           <hr />
// //         </Link>
// //       </div>
// //       <div className="nav-links">
// //         <div
// //           className="a-link"
// //           id="reports"
// //           onMouseEnter={() => handleReportsHover(true)}
// //           onMouseLeave={() => handleReportsHover(false)}
// //         >
// //           <FontAwesomeIcon className="icons" icon={faUser} />
// //           <span className="link-name">Admin Data</span>
// //           {showOptions && (
// //             <div className="options">
// //               <Link to='/GetFreeEstimate' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Get FREE Estimate</span>
// //               </Link>
// //               <Link to='/EnquireNow' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Enquire from Data</span>
// //               </Link>
// //               <Link to='/DocumentUploadAdmin' className="options1">
// //                 <FontAwesomeIcon className="icons" icon={faTasks} />
// //                 <span className="a1">Document Upload Admin</span>
// //               </Link>
// //             </div>
// //           )}
// //         </div>
// //         <Link className="a-link" to="/">
// //           <FontAwesomeIcon className="icons" icon={faSignOutAlt} />
// //           <span className="link-name">Logout</span>
// //         </Link>
// //       </div>
// //     </div>
// //   );
// // };

// // export default AdminData;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./AdminData.css";
function AdminData() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".sidebar") && isOpen) {
        setIsOpen(true); // Corrected to set to false to close the drawer
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);
  return (
    <div className={`admin-data-container ${isOpen ? 'drawer-open' : ''}`}>
      <nav className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <div className="sidebar-sticky">
          <ul className="nav flex-column">
            <li className="nav-item">
              <Link to="/AdminDashboard" className="nav-link">
                <i className="fa-solid fa-user"></i>
                {isOpen && <span className="a1 text-white">Candidate Details</span>}
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/" className="nav-link1">
                <i className="fa-solid fa-right-from-bracket"></i>
                {isOpen && <span className="a1 text-white">Logout</span>}
              </Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="main-content">
        <button className="toggle-button" onClick={toggleDrawer}>
          <i className={`fa ${isOpen ? 'fa-times' : 'fa-bars'}`}></i>
        </button>
      </div>
    </div>
  );
}
export default AdminData;