import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Form, Button, Card } from "react-bootstrap";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import axios from "axios";
import "./Reactcodetest.css";
import { useNavigate, useLocation } from "react-router-dom";

const Reactcodetest = () => {
  const location = useLocation();
  const [mcqTestResults, setMCQTestResults] = useState({});
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [timeLeft, setTimeLeft] = useState(1.5 * 60 * 60); // 1.5 hours
  const [timerOn, setTimerOn] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const [isPageVisible, setPageVisible] = useState(true); // State to track page visibility
  const [openDialog, setOpenDialog] = useState(false);
  const [cameraAccessDialogOpen, setCameraAccessDialogOpen] = useState(false);
  const [alertDialogOpen, setAlertDialogOpen] = useState(false);
  const [alertDialogMessage, setAlertDialogMessage] = useState("");
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const handleSubmit = useCallback(
    (e) => {
      if (e) e.preventDefault();
      if ((answers[currentQuestionIndex] || "").trim() === "") {
        setAlertDialogMessage(
          "Please enter your code before proceeding to the next question."
        );
        setAlertDialogOpen(true);
        return;
      }
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      } else {
        setOpenDialog(true);
      }
    },
    [answers, currentQuestionIndex, questions.length]
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      setPageVisible(!document.hidden); // Update state based on page visibility
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (timerOn && timeLeft > 0 && isPageVisible) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0 || (!isPageVisible && testStarted)) {
      setTimerOn(false);
      handleSubmit();
    }
  }, [timeLeft, timerOn, isPageVisible, testStarted, handleSubmit]);

  useEffect(() => {
    if (location.state && location.state.mcqTestResults) {
      setMCQTestResults(location.state.mcqTestResults);
    }
  }, [location.state]);

  useEffect(() => {
    if (testStarted) {
      setCameraAccessDialogOpen(true);
    } else {
      stopCamera();
    }
  }, [testStarted]);

  useEffect(() => {
    // Fetch questions from the backend API
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/reactcodingquestions`
        );
        const data = response.data;
        setQuestions(data);
        setAnswers(Array(data.length).fill(""));
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, []);
  useEffect(() => {
    const cameraCheckInterval = setInterval(() => {
      if (testStarted) {
        checkCameraAccess();
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(cameraCheckInterval);
  }, [testStarted]);

  const checkCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setCameraAccessDialogOpen(true);
    }
  };

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setCameraAccessDialogOpen(true);
      console.error("Error accessing the camera:", error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleAnswerChange = (value) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = value;
    setAnswers(newAnswers);
  };

  const handleBack = (e) => {
    e.preventDefault();
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleFinalSubmit = async () => {
    const email = localStorage.getItem("userEmail"); // Retrieve email from local storage
    if (!email) {
      console.error("Email not found in local storage.");
      setAlertDialogMessage(
        "Error: Email not found. Please submit the career form first."
      );
      setAlertDialogOpen(true);
      return;
    }
    const mcqScore = mcqTestResults.mcqScore || 0; // Default to 0 if not available
    const mcqTotalQuestions =
      mcqTestResults.mcqTotalQuestions || questions.length;
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/react-test-scores`,
        {
          email: email,
          mcq_score: mcqScore,
          mcq_total_questions: mcqTotalQuestions,
          coding_total_questions: questions.length,
          code1: answers[0] || "",
          code2: answers[1] || "",
          code3: answers[2] || "",
          code4: answers[3] || "",
        }
      );
      console.log(response.data);
      setSubmitted(true);
      setTimerOn(false);
      setTimeLeft(0);
      stopCamera(); // Stop the camera
      navigate("/DocumentUploadReact");
    } catch (error) {
      console.error("Error submitting test scores:", error);
    }
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const startTest = () => {
    if (testStarted) {
      setAlertDialogMessage(
        "Test has already started. Please do not switch tabs."
      );
      setAlertDialogOpen(true);
      return;
    }
    setTestStarted(true);
    setTimerOn(true);
  };

  const handleCameraAccess = () => {
    setCameraAccessDialogOpen(false);
    startCamera();
  };

  const handlePaste = (e) => {
    e.preventDefault();
    setAlertDialogMessage("Pasting is not allowed in the answer section.");
    setAlertDialogOpen(true);
  };

  return (
    <Container className="quiz-container">
      <h1 className="coding-test text-white p-3 d-flex justify-content-center mb-4">
        CODING TEST
      </h1>
      <Card>
        <Card.Body>
          {!testStarted ? (
            <div className="test-info-container">
              <h2>Read carefully</h2>
              <Card>
                <Card.Body>
                  <Card.Text className="mb-4">
                    <p>
                      <strong>Test Format:</strong> The test consists of 4
                      programming questions.
                    </p>{" "}
                    <br />
                    <p>
                      <strong>Passing Score:</strong> A minimum of 60% is
                      required for selection.
                    </p>
                    <br />
                    <p>
                      <strong>Time Limit:</strong> The test must be completed
                      within 1 hours and 30 minutes.
                    </p>
                    <br />
                    <p>
                      <strong>Camera Acess </strong>Allow the Camera acess from
                      settings..Before start test..
                    </p>
                    <br />
                    <p>
                      <strong>Warning: </strong>Don't change the tab when the
                      exam will start. Otherwise, the exam will be submitted.
                    </p>
                    <br />
                    <p>All The Best !!</p>
                  </Card.Text>
                  <div className="d-flex justify-content-start">
                    <Button variant="primary" onClick={startTest}>
                      Start Coding Test
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ) : (
            <>
              {!submitted && isPageVisible && (
                <div className="timerr text-center mb-3">
                  Time Left: {formatTime(timeLeft)}
                </div>
              )}
              {!submitted && isPageVisible ? (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId={`question-${currentQuestionIndex}`}>
                    <Form.Label>
                      <strong>Question No {currentQuestionIndex + 1}:</strong>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={questions[currentQuestionIndex]?.question || ""}
                      readOnly
                    />
                    <Form.Label className="mt-3">
                      <strong>Your Answer:</strong>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      value={answers[currentQuestionIndex] || ""}
                      onChange={(e) => handleAnswerChange(e.target.value)}
                      onPaste={handlePaste} // Disable pasting
                      required
                    />
                  </Form.Group>
                  <div className="button-container mt-3">
                    <Button
                      variant="secondary"
                      onClick={handleBack}
                      disabled={currentQuestionIndex === 0}
                    >
                      Back
                    </Button>
                    <Button variant="primary" type="submit" className="ms-4">
                      {currentQuestionIndex === questions.length - 1
                        ? "Submit"
                        : "Next Question"}
                    </Button>
                  </div>
                </Form>
              ) : (
                <div className="text-center mt-3">
                  <h2 className="text-danger">
                    The page is not visible. Please return to the test tab.
                  </h2>
                </div>
              )}
            </>
          )}
        </Card.Body>
      </Card>

      {/* Camera Feed */}
      <div className="camera-container">
        <video ref={videoRef} autoPlay muted className="camera-video" />
      </div>

      {/* Dialog for camera access */}
      <Dialog
        open={cameraAccessDialogOpen}
        aria-labelledby="camera-access-dialog-title"
        aria-describedby="camera-access-dialog-description"
      >
        <DialogTitle id="camera-access-dialog-title">
          {"Camera Access Required"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="camera-access-dialog-description">
            To ensure the integrity of the test, we need to access your camera.
            Please allow camera access to proceed with the test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCameraAccess} color="primary" autoFocus>
            Start Test
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for confirming submission */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Submit your test"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to submit your test? You won't be able to
            change your answers after submission.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleFinalSubmit} color="primary" autoFocus>
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for camera access */}
      <Dialog
        open={cameraAccessDialogOpen}
        // onClose={() => setCameraAccessDialogOpen(false)}
        aria-labelledby="camera-access-dialog-title"
        aria-describedby="camera-access-dialog-description"
      >
        <DialogTitle id="camera-access-dialog-title">
          {"Camera Access Required"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="camera-access-dialog-description">
            To ensure the integrity of the test, we need to access your camera.
            Please allow camera access to proceed with the test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={() => setCameraAccessDialogOpen(false)} color="primary">
            Cancel
          </Button> */}
          <Button onClick={handleCameraAccess} color="primary" autoFocus>
            Start Test
          </Button>
        </DialogActions>
      </Dialog>

      {/* Alert Dialog */}
      <Dialog
        open={alertDialogOpen}
        onClose={() => setAlertDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alertDialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setAlertDialogOpen(false)}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Reactcodetest;
