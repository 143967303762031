// // import React, { useState } from "react";
// // import { Container, Row, Col, Modal, Button } from "react-bootstrap";
// // import { FaTimes } from "react-icons/fa";
// // // import Footer from "../Footer/Footer";
// // import Header from "../../component/Header/Header";
// // import "./Carrer.css";
// // import { useNavigate } from "react-router-dom";

// // const headerStyle = {
// //   backgroundColor: "#1F2A57",
// //   color: "white",
// //   padding: "2rem 0",
// //   textAlign: "center",
// // };

// // const jobButtonStyle = {
// //   margin: "10px",
// //   padding: "10px 20px",
// //   fontSize: "16px",
// //   borderColor: "#dcdcdc",
// //   backgroundColor: "#343a40", // Dark background color
// //   color: "white", // White text color
// //   borderRadius: "5px",
// //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
// //   width: "100%", // Ensure full width on smaller screens
// //   maxWidth: "200px", // Limit width on larger screens
// // };

// // const jobDescriptions = {
// //   "Java Developer": {
// //     description: "Java Developer job description.",
// //     responsibilities: [
// //       "Develop and maintain high-quality Java-based applications.",
// //       "Writing efficient, reusable, and reliable code.",
// //       "Understand and implement security best practices, ensuring the development.",
// //       "Ensure the development of secure applications that protect against common vulnerabilities.",
// //       "Collaborate with team members",
// //     ],
// //     requirementsskills: [
// //       "core Java, Spring, Spring Boot, JDBC, HTML, CSS, Java Script, React JS, PostgreSQL.",
// //     ],
// //     // formDestination: "/JavaDeveloperForm", // Destination page after form submission
// //   },
// //   "Digital Marketing": {
// //     description: "Digital Marketing job description.",
// //     responsibilities: [
// //       "Develop marketing strategies",
// //       "Social Media Management",
// //       "Search Engine Optimization (SEO)",
// //       "Manage social media campaigns",
// //       "Content Creation and Management:",
// //     ],
// //     requirementsskills: [
// //       "Experience in digital marketing",
// //       "Strong analytical skills",
// //       "Content Creation",
// //       "Email Marketing",
// //       // "Social Media Management",
// //     ],
// //   },
// //   ".Net Developer": {
// //     description: ".Net Developer job description.",
// //     responsibilities: ["Develop .NET applications", "Maintain code quality"],
// //     requirementsskills: [
// //       "Proven experience as a .NET Developer",
// //       "Knowledge of .NET frameworks",
// //     ],
// //   },
// //   "Content Writer": {
// //     description: "Content Writer job description.",
// //     responsibilities: [
// //       "Write compelling content",
// //       "Edit and proofread content",
// //     ],
// //     requirementsskills: [
// //       "Experience as a content writer",
// //       "Excellent writing skills",
// //     ],
// //     // formDestination: "/DigitalMarketingForm", // Destination page after form submission
// //   },
// //   Fullstack: {
// //     description: "Fullstack Developer job description.",
// //     responsibilities: [
// //       "Develop front-end and back-end applications",
// //       "Ensure responsiveness of applications",
// //     ],
// //     requirementsskills: ["Current closed this opening"],
// //   },
// //   "React Native": {
// //     description: "React Native Developer job description.",
// //     responsibilities: ["Current closed this opening"],
// //     requirementsskills: [],
// //   },
// //   "Python Developer": {
// //     description: "Python Developer job description.",
// //     responsibilities: ["Current closed this opening"],
// //     requirementsskills: [],
// //   },
// //   "Android Developer": {
// //     description: "Android Developer job description.",
// //     responsibilities: ["Current closed this opening"],
// //     requirementsskills: [],
// //   },
// // };

// // const openJobs = ["Java Developer", "Digital Marketing"];

// // const isJobOpen = (jobTitle) => openJobs.includes(jobTitle);

// // function Carrer() {
// //   const [showModal, setShowModal] = useState(false);
// //   const [jobTitle, setJobTitle] = useState("");
// //   const navigate = useNavigate();

// //   const handleShowModal = (title) => {
// //     setJobTitle(title);
// //     setShowModal(true);
// //   };

// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //     setJobTitle("");
// //   };

// //   const handleApplyNowClick = () => {
// //     let loginPage;
// //     switch (jobTitle) {
// //       case "Digital Marketing":
// //         loginPage = "/LoginCarrerDM";
// //         break;
// //       case "Java Developer":
// //         loginPage = "/LoginCarrer";
// //         break;
// //       // Add cases for other job titles if needed
// //       default:
// //         // Default page if the job title doesn't match any specific case
// //         loginPage = "/LoginCarrer";
// //     }
// //     navigate(loginPage, { state: { jobTitle } });
// //   };

// //   return (
// //     <>
// //       <Header />
// //       <div className="container-fluid">
// //           <h1 className="p-1 d-flex justify-content-center">Carrer Section</h1>
// //         {/* <div className="carrer-image">
// //           <div className="container-fluid">
// //             <h1 className="home-to-contact text-dangars">
// //               <span className="contact-heading text-white">
// //                 <strong> Carrer Page</strong>
// //               </span>
// //               <span className="home-to-contact-us text-primary">
// //                 <strong>Home - Carrer Page</strong>
// //               </span>
// //             </h1>
// //           </div>
// //         </div> */}
// //         <div className="row carrer-page-image">
// //           <Container className="text-center mt-5">
// //             <div
// //               className=".bg-primary.bg-gradient text-white"
// //               style={headerStyle}
// //             >
// //               <h1 className="">BUILD YOUR STRONG CAREER</h1>
// //               <h6>
// //                 Choose a job you love, and you will never have to work a day in
// //                 your life
// //               </h6>
// //             </div>


            
// //             <div className="mt-4">
// //               <h2>Current Job-Openings</h2>
// //               <Row className="d-flex justify-content-center mt-3">
// //                 {Object.keys(jobDescriptions).map((job, index) => (
// //                   <Col key={index} xs={6} md={4} lg={3} className="mb-4">
// //                     <button
// //                       className="btn"
// //                       style={jobButtonStyle}
// //                       onClick={() => handleShowModal(job)}
// //                       disabled={!isJobOpen(job)}
// //                     >
// //                       {job}
// //                     </button>
// //                   </Col>
// //                 ))}
// //               </Row>
// //             </div>

// //             <Modal
// //               show={showModal}
// //               onHide={handleCloseModal}
// //               centered
// //               dialogClassName="custom-modal"
// //             >
// //               <Modal.Header className="custom-modal-header apply-form-code mt-6">
// //                 <Modal.Title>{jobTitle}</Modal.Title>
// //                 <FaTimes className="close-icon" onClick={handleCloseModal} />
// //               </Modal.Header>
// //               <Modal.Body>
// //                 <p>{jobDescriptions[jobTitle]?.description}</p>
// //                 <h5>Responsibilities</h5>
// //                 <ul>
// //                   {jobDescriptions[jobTitle]?.responsibilities.map(
// //                     (item, index) => (
// //                       <li key={index}>{item}</li>
// //                     )
// //                   )}
// //                 </ul>
// //                 <h5>Requirements skills </h5>
// //                 <ul>
// //                   {jobDescriptions[jobTitle]?.requirementsskills.map(
// //                     (item, index) => (
// //                       <li key={index}>{item}</li>
// //                     )
// //                   )}
// //                 </ul>
// //               </Modal.Body>
// //               <Modal.Footer className="d-flex justify-content-center">
// //                 <Button
// //                   variant="primary"
// //                   onClick={handleApplyNowClick}
// //                   disabled={!isJobOpen(jobTitle)}
// //                 >
// //                   Apply Now
// //                 </Button>
// //               </Modal.Footer>
// //             </Modal>
// //           </Container>
// //         </div>
// //       </div>
// //       {/* <Footer /> */}
// //     </>
// //   );
// // }

// // export default Carrer;



// // import React, { useState } from "react";
// // import { Container, Row, Col, Modal, Button } from "react-bootstrap";
// // import { FaTimes, FaLock } from "react-icons/fa";
// // import Header from "../../component/Header/Header";
// // import "./Carrer.css";
// // import { useNavigate } from "react-router-dom";

// // const headerStyle = {
// //   backgroundColor: "#1F2A57",
// //   color: "white",
// //   padding: "2rem 0",
// //   textAlign: "center",
// // };

// // const jobButtonStyle = {
// //   margin: "10px",
// //   padding: "10px 20px",
// //   fontSize: "16px",
// //   borderColor: "#dcdcdc",
// //   backgroundColor: "#343a40",
// //   color: "white",
// //   borderRadius: "5px",
// //   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
// //   width: "100%",
// //   maxWidth: "200px",
// //   position: "relative", // Add relative positioning for icon
// // };

// // const lockIconStyle = {
// //   marginLeft: "8px", // Adjust spacing between text and icon
// // };

// // const jobDescriptions = {
// //   "Java Developer": {
// //     description: "Java Developer job description.",
// //     responsibilities: [
// //       "Develop and maintain high-quality Java-based applications.",
// //       "Writing efficient, reusable, and reliable code.",
// //       "Understand and implement security best practices, ensuring the development.",
// //       "Ensure the development of secure applications that protect against common vulnerabilities.",
// //       "Collaborate with team members",
// //     ],
// //     requirementsskills: [
// //       "core Java, Spring, Spring Boot, JDBC, HTML, CSS, Java Script, React JS, PostgreSQL.",
// //     ],
// //   },
// //   "Digital Marketing": {
// //     description: "Digital Marketing job description.",
// //     responsibilities: [
// //       "Develop marketing strategies",
// //       "Social Media Management",
// //       "Search Engine Optimization (SEO)",
// //       "Manage social media campaigns",
// //       "Content Creation and Management:",
// //     ],
// //     requirementsskills: [
// //       "Experience in digital marketing",
// //       "Strong analytical skills",
// //       "Content Creation",
// //       "Email Marketing",
// //     ],
// //   },
// //   ".Net Developer": {
// //     description: ".Net Developer job description.",
// //     responsibilities: ["Develop .NET applications", "Maintain code quality"],
// //     requirementsskills: [
// //       "Proven experience as a .NET Developer",
// //       "Knowledge of .NET frameworks",
// //     ],
// //   },
// //   "Content Writer": {
// //     description: "Content Writer job description.",
// //     responsibilities: [
// //       "Write compelling content",
// //       "Edit and proofread content",
// //     ],
// //     requirementsskills: [
// //       "Experience as a content writer",
// //       "Excellent writing skills",
// //     ],
// //   },
// //   "Fullstack": {
// //     description: "Fullstack Developer job description.",
// //     responsibilities: [
// //       "Develop front-end and back-end applications",
// //       "Ensure responsiveness of applications",
// //     ],
// //     requirementsskills: ["This position is currently closed."],
// //   },
// //   "React Native": {
// //     description: "React Native Developer job description.",
// //     responsibilities: ["This position is currently closed."],
// //     requirementsskills: [],
// //   },
// //   "Python Developer": {
// //     description: "Python Developer job description.",
// //     responsibilities: ["This position is currently closed."],
// //     requirementsskills: [],
// //   },
// //   "Android Developer": {
// //     description: "Android Developer job description.",
// //     responsibilities: ["This position is currently closed."],
// //     requirementsskills: [],
// //   },
// // };

// // const openJobs = ["Java Developer", "Digital Marketing"];

// // const isJobOpen = (jobTitle) => openJobs.includes(jobTitle);

// // function Carrer() {
// //   const [showModal, setShowModal] = useState(false);
// //   const [jobTitle, setJobTitle] = useState("");
// //   const navigate = useNavigate();

// //   const handleShowModal = (title) => {
// //     setJobTitle(title);
// //     setShowModal(true);
// //   };

// //   const handleCloseModal = () => {
// //     setShowModal(false);
// //     setJobTitle("");
// //   };

// //   const handleApplyNowClick = () => {
// //     let loginPage;
// //     switch (jobTitle) {
// //       case "Digital Marketing":
// //         loginPage = "/LoginCarrerDM";
// //         break;
// //       case "Java Developer":
// //         loginPage = "/LoginCarrer";
// //         break;
// //       default:
// //         loginPage = "/LoginCarrer";
// //     }
// //     navigate(loginPage, { state: { jobTitle } });
// //   };

// //   return (
// //     <>
// //       <Header />
// //       <div className="container-fluid">
// //         <h1 className="p-1 d-flex justify-content-center">Career Section</h1>
// //         <div className="row carrer-page-image">
// //           <Container className="text-center mt-5">
// //             <div style={headerStyle}>
// //               <h1>BUILD YOUR STRONG CAREER</h1>
// //               <h6>
// //                 Choose a job you love, and you will never have to work a day in
// //                 your life
// //               </h6>
// //             </div>

// //             <div className="mt-4">
// //               <h2>Current Job Openings</h2>
// //               <Row className="d-flex justify-content-center mt-3">
// //                 {Object.keys(jobDescriptions).map((job, index) => (
// //                   <Col key={index} xs={6} md={4} lg={3} className="mb-4">
// //                     <button
// //                       className="btn"
// //                       style={jobButtonStyle}
// //                       onClick={() => handleShowModal(job)}
// //                       disabled={!isJobOpen(job)}
// //                     >
// //                       {job}
// //                       {!isJobOpen(job) && <FaLock style={lockIconStyle} />}
// //                     </button>
// //                     {!isJobOpen(job) && (
// //                       <p className="text-muted">Currently closed</p>
// //                     )}
// //                   </Col>
// //                 ))}
// //               </Row>
// //             </div>

// //             <Modal
// //               show={showModal}
// //               onHide={handleCloseModal}
// //               centered
// //               dialogClassName="custom-modal"
// //             >
// //               <Modal.Header className="custom-modal-header apply-form-code mt-6">
// //                 <Modal.Title>{jobTitle}</Modal.Title>
// //                 <FaTimes className="close-icon" onClick={handleCloseModal} />
// //               </Modal.Header>
// //               <Modal.Body>
// //                 <p>{jobDescriptions[jobTitle]?.description}</p>
// //                 <h5>Responsibilities</h5>
// //                 <ul>
// //                   {jobDescriptions[jobTitle]?.responsibilities.map(
// //                     (item, index) => (
// //                       <li key={index}>{item}</li>
// //                     )
// //                   )}
// //                 </ul>
// //                 <h5>Requirements skills </h5>
// //                 <ul>
// //                   {jobDescriptions[jobTitle]?.requirementsskills.map(
// //                     (item, index) => (
// //                       <li key={index}>{item}</li>
// //                     )
// //                   )}
// //                 </ul>
// //               </Modal.Body>
// //               <Modal.Footer className="d-flex justify-content-center">
// //                 <Button
// //                   variant="primary"
// //                   onClick={handleApplyNowClick}
// //                   disabled={!isJobOpen(jobTitle)}
// //                 >
// //                   Apply Now
// //                 </Button>
// //               </Modal.Footer>
// //             </Modal>
// //           </Container>
// //         </div>
// //       </div>
// //     </>
// //   );
// // }

// // export default Carrer;

// import React, { useState } from "react";
// import { Container, Row, Col, Modal, Button } from "react-bootstrap";
// import { FaTimes, FaLock } from "react-icons/fa";
// import "./Carrer.css";
// import { useNavigate } from "react-router-dom";

// const headerStyle = {
//   backgroundColor: "#1F2A57",
//   color: "white",
//   padding: "2rem 0",
//   textAlign: "center",
// };

// const jobButtonStyle = {
//   margin: "10px",
//   padding: "10px 20px",
//   fontSize: "16px",
//   borderColor: "#dcdcdc",
//   backgroundColor: "#343a40",
//   color: "white",
//   borderRadius: "5px",
//   boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
//   width: "100%",
//   maxWidth: "200px",
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   position: "relative",
// };

// const lockIconStyle = {
//   marginLeft: "8px",
// };
// const headerContainerStyle = {
//   backgroundColor: "#f1f1f1",
//   display: "flex",
//   justifyContent: "space-between",
//   alignItems: "center",
//   padding: "10px 20px",
// };

// const headerButtonStyle = {
//   backgroundColor: "#007bff",
//   border: "none",
//   color: "white",
//   padding: "10px 20px",
//   borderRadius: "5px",
// };
// const jobDescriptions = {
//   "Java Developer": {
//     description: "Java Developer job description.",
//     responsibilities: [
//       "Develop and maintain high-quality Java-based applications.",
//       "Writing efficient, reusable, and reliable code.",
//       "Understand and implement security best practices, ensuring the development.",
//       "Ensure the development of secure applications that protect against common vulnerabilities.",
//       "Collaborate with team members",
//     ],
//     requirementsskills: [
//       "core Java, Spring, Spring Boot, JDBC, HTML, CSS, Java Script, React JS, PostgreSQL.",
//     ],
//   },
//   "Digital Marketing": {
//     description: "Digital Marketing job description.",
//     responsibilities: [
//       "Develop marketing strategies",
//       "Social Media Management",
//       "Search Engine Optimization (SEO)",
//       "Manage social media campaigns",
//       "Content Creation and Management:",
//     ],
//     requirementsskills: [
//       "Experience in digital marketing",
//       "Strong analytical skills",
//       "Content Creation",
//       "Email Marketing",
//     ],
//   },
//   "UI/UX Designer Intern": {
//     description: "UI/UX Intern job description.",
//     responsibilities: ["Assist in designing user interfaces for web and mobile applications.", 
//       "Create wireframes, prototypes, and mockups for new features and improvements using Figma.",
//     " Conduct user research and gather feedback to inform design decisions",
//     "Collaborate with developers, product managers, and other designers to ensure a seamless user experience.",
//     "Help maintain and improve our design system and UI/UX best practices"
//     ],
//     requirementsskills: [
//       " Currently pursuing or recently completed a degree in Graphic Design, Interaction Design, Human-Computer Interaction, or a related field.",
//       "Strong portfolio showcasing UI/UX design skills and projects.",
//       "Proficiency in design tools such as Figma, Adobe XD, Sketch, or similar.",
//       "Basic understanding of HTML/CSS, Frontend and responsive design principles."
//     ],
//   },
//   "Content Writer": {
//     description: "Content Writer job description.",
//     responsibilities: [
//       "Write compelling content",
//       "Edit and proofread content",
//     ],
//     requirementsskills: [
//       "Experience as a content writer",
//       "Excellent writing skills",
//     ],
//   },
//   Fullstack: {
//     description: "Fullstack Developer job description.",
//     responsibilities: [
//       "Develop front-end and back-end applications",
//       "Ensure responsiveness of applications",
//     ],
//     requirementsskills: ["This position is currently closed."],
//   },
//   "React Native": {
//     description: "React Native Developer job description.",
//     responsibilities: ["This position is currently closed."],
//     requirementsskills: [],
//   },
//   "Python Developer": {
//     description: "Python Developer job description.",
//     responsibilities: ["This position is currently closed."],
//     requirementsskills: [],
//   },
//   "Android Developer": {
//     description: "Android Developer job description.",
//     responsibilities: ["This position is currently closed."],
//     requirementsskills: [],
//   },
// };

// const openJobs = ["Java Developer", "Digital Marketing","UI/UX Designer Intern"];

// const isJobOpen = (jobTitle) => openJobs.includes(jobTitle);

// function Carrer() {
//   const [showModal, setShowModal] = useState(false);
//   const [jobTitle, setJobTitle] = useState("");
//   const navigate = useNavigate();

//   const handleShowModal = (title) => {
//     setJobTitle(title);
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//     setJobTitle("");
//   };

//   const handleApplyNowClick = () => {
//     let loginPage;
//     switch (jobTitle) {
//       case "Digital Marketing":
//         loginPage = "/CarrerFormDM";
//         break;
//       case "Java Developer":
//         loginPage = "/CarrerForm";
//         break;
//       case "UI/UX Designer Intern":
//         loginPage = "/CarrerFormUI";
//         break;
//       default:
//         loginPage = "/CarrerForm";
//     }
//     navigate(loginPage, { state: { jobTitle } });
//   };

//   return (
//     <>
//       {/* <Header /> */}
//       <div className="container-fluid">
//       <div style={headerContainerStyle}>
//         <h1 className="p-1">Career Section</h1>
//         <button
//           className="header-btn"
//           style={headerButtonStyle}
//           onClick={() => navigate('/Admin')}
//         >
//           Login
//         </button>
//       </div>
//         <div className="row carrer-page-image">
//           <Container className="text-center mt-5">
//             <div style={headerStyle}>
//               <h1>BUILD YOUR STRONG CAREER</h1>
//               <h6>
//                 Choose a job you love, and you will never have to work a day in
//                 your life
//               </h6>
//             </div>

//             <div className="mt-4">
//               <h2>Current Job Openings</h2>
//               <Row className="d-flex justify-content-center mt-3">
//                 {Object.keys(jobDescriptions).map((job, index) => (
//                   <Col key={index} xs={6} md={4} lg={3} className="mb-4">
//                     <button
//                       className="btn"
//                       style={jobButtonStyle}
//                       onClick={() => handleShowModal(job)}
//                       disabled={!isJobOpen(job)}
//                     >
//                       {job}
//                       {!isJobOpen(job) && <FaLock style={lockIconStyle} />}
//                     </button>
//                   </Col>
//                 ))}
//               </Row>
//             </div>

//             <Modal
//               show={showModal}
//               onHide={handleCloseModal}
//               centered
//               dialogClassName="custom-modal"
//             >
//               <Modal.Header className="custom-modal-header apply-form-code mt-6">
//                 <Modal.Title>{jobTitle}</Modal.Title>
//                 <FaTimes className="close-icon" onClick={handleCloseModal} />
//               </Modal.Header>
//               <Modal.Body>
//                 <p>{jobDescriptions[jobTitle]?.description}</p>
//                 <h5>Responsibilities</h5>
//                 <ul>
//                   {jobDescriptions[jobTitle]?.responsibilities.map(
//                     (item, index) => (
//                       <li key={index}>{item}</li>
//                     )
//                   )}
//                 </ul>
//                 <h5>Requirements skills </h5>
//                 <ul>
//                   {jobDescriptions[jobTitle]?.requirementsskills.map(
//                     (item, index) => (
//                       <li key={index}>{item}</li>
//                     )
//                   )}
//                 </ul>
//               </Modal.Body>
//               <Modal.Footer className="d-flex justify-content-center">
//                 <Button
//                   variant="primary"
//                   onClick={handleApplyNowClick}
//                   disabled={!isJobOpen(jobTitle)}
//                 >
//                   Apply Now
//                 </Button>
//               </Modal.Footer>
//             </Modal>
//           </Container>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Carrer;

import React, { useState } from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { FaTimes, FaLock } from "react-icons/fa";
import Header from "../../component/Header/Header";
import "./Carrer.css";
import { useNavigate } from "react-router-dom";

const headerStyle = {
  backgroundColor: "#1F2A57",
  color: "white",
  padding: "2rem 0",
  textAlign: "center",
};

const jobButtonStyle = {
  margin: "10px",
  padding: "10px 20px",
  fontSize: "16px",
  borderColor: "#dcdcdc",
  backgroundColor: "#343a40",
  color: "white",
  borderRadius: "5px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  width: "100%",
  maxWidth: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "relative",
};

const lockIconStyle = {
  marginLeft: "8px",
};

const jobDescriptions = {
  "Java Developer": {
    description: "Java Developer job description.",
    responsibilities: [
      "Develop and maintain high-quality Java-based applications.",
      "Writing efficient, reusable, and reliable code.",
      "Understand and implement security best practices, ensuring the development.",
      "Ensure the development of secure applications that protect against common vulnerabilities.",
      "Collaborate with team members",
    ],
    requirementsskills: [
      "core Java, Spring, Spring Boot, JDBC, HTML, CSS, Java Script, React JS, PostgreSQL.",
    ],
  },
  "Digital Marketing": {
    description: "Digital Marketing job description.",
    responsibilities: [
      "Develop marketing strategies",
      "Social Media Management",
      "Search Engine Optimization (SEO)",
      "Manage social media campaigns",
      "Content Creation and Management:",
    ],
    requirementsskills: [
      "Experience in digital marketing",
      "Strong analytical skills",
      "Content Creation",
      "Email Marketing",
    ],
  },
  "UI/UX Designer Intern": {
    description: "UI/UX Intern job description.",
    responsibilities: ["Assist in designing user interfaces for web and mobile applications.", 
      "Create wireframes, prototypes, and mockups for new features and improvements using Figma.",
    " Conduct user research and gather feedback to inform design decisions",
    "Collaborate with developers, product managers, and other designers to ensure a seamless user experience.",
    "Help maintain and improve our design system and UI/UX best practices"
    ],
    requirementsskills: [
      " Currently pursuing or recently completed a degree in Graphic Design, Interaction Design, Human-Computer Interaction, or a related field.",
      "Strong portfolio showcasing UI/UX design skills and projects.",
      "Proficiency in design tools such as Figma, Adobe XD, Sketch, or similar.",
      "Basic understanding of HTML/CSS, Frontend and responsive design principles."
    ],
  },
  "React Js Intern": {
    description: "React Js Intern job description.",
    responsibilities: [
      "Writing efficient, reusable, and reliable code.",
      "Understand and implement security best practices, ensuring the development.",
      "Ensure the development of secure applications that protect against common vulnerabilities.",
      "Participate in code reviews and provide constructive feedback.",
      "Assist in developing and maintaining web applications using React.js.",
    ],
    requirementsskills: [
      "Familiarity with JavaScript, HTML, CSS, Bootstrap, React.js, and front-end development principles.",
      "Basic understanding of Node.js and Express.js",
      "Strong problem-solving skills and attention to detail.",
      "Ability to work collaboratively in a team environment.",
    ],
  },
  Fullstack: {
    description: "Fullstack Developer job description.",
    responsibilities: [
      "Develop front-end and back-end applications",
      "Ensure responsiveness of applications",
    ],
    requirementsskills: ["This position is currently closed."],
  },
  "React Native": {
    description: "React Native Developer job description.",
    responsibilities: ["This position is currently closed."],
    requirementsskills: [],
  },
  "Python Developer": {
    description: "Python Developer job description.",
    responsibilities: ["This position is currently closed."],
    requirementsskills: [],
  },
  "Android Developer": {
    description: "Android Developer job description.",
    responsibilities: ["This position is currently closed."],
    requirementsskills: [],
  },
};

const openJobs = ["Java Developer", "Digital Marketing","UI/UX Designer Intern","React Js Intern"];

const isJobOpen = (jobTitle) => openJobs.includes(jobTitle);

function Carrer() {
  const [showModal, setShowModal] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const navigate = useNavigate();

  const handleShowModal = (title) => {
    setJobTitle(title);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setJobTitle("");
  };

  const handleApplyNowClick = () => {
    let loginPage;
    switch (jobTitle) {
      case "Digital Marketing":
        loginPage = "/CarrerFormDM";
        break;
      case "Java Developer":
        loginPage = "/CarrerForm";
        break;
      case "UI/UX Designer Intern":
        loginPage = "/CarrerFormUI";
        break;
        case "React Js Intern":
          loginPage = "/CarrerFormReact";
          break;
      default:
        loginPage = "/CarrerForm";
    }
    navigate(loginPage, { state: { jobTitle } });
  };

  return (
    <>
      <Header />
      <div className="container-fluid">
        <h1 className="p-1 d-flex justify-content-center">Career Section</h1>
        <div className="row carrer-page-image">
          <Container className="text-center mt-5">
            <div style={headerStyle}>
              <h1>BUILD YOUR STRONG CAREER</h1>
              <h6>
                Choose a job you love, and you will never have to work a day in
                your life
              </h6>
            </div>

            <div className="mt-4">
              <h2>Current Job Openings</h2>
              <Row className="d-flex justify-content-center mt-3">
                {Object.keys(jobDescriptions).map((job, index) => (
                  <Col key={index} xs={6} md={4} lg={3} className="mb-4">
                    <button
                      className="btn"
                      style={jobButtonStyle}
                      onClick={() => handleShowModal(job)}
                      disabled={!isJobOpen(job)}
                    >
                      {job}
                      {!isJobOpen(job) && <FaLock style={lockIconStyle} />}
                    </button>
                  </Col>
                ))}
              </Row>
            </div>

            <Modal
              show={showModal}
              onHide={handleCloseModal}
              centered
              dialogClassName="custom-modal"
            >
              <Modal.Header className="custom-modal-header apply-form-code mt-6">
                <Modal.Title>{jobTitle}</Modal.Title>
                <FaTimes className="close-icon" onClick={handleCloseModal} />
              </Modal.Header>
              <Modal.Body>
                <p>{jobDescriptions[jobTitle]?.description}</p>
                <h5>Responsibilities</h5>
                <ul>
                  {jobDescriptions[jobTitle]?.responsibilities.map(
                    (item, index) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
                <h5>Requirements skills </h5>
                <ul>
                  {jobDescriptions[jobTitle]?.requirementsskills.map(
                    (item, index) => (
                      <li key={index}>{item}</li>
                    )
                  )}
                </ul>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <Button
                  variant="primary"
                  onClick={handleApplyNowClick}
                  disabled={!isJobOpen(jobTitle)}
                >
                  Apply Now
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Carrer;
