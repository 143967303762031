import React from "react";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";


import Admin from "./ComponentAll/Admin/Admin";
import GetFreeEstimate from "./ComponentAll/GetFREEEstimate/GetFreeEstimate";
import EnquireNow from './ComponentAll/EnquireNow/EnquireNow';
import AdminLogin from "./Admin";
import AdminData from "./ComponentAll/AdminData/AdminData";
import Carrer from "./ComponentAll/Carrer/Carrer";
import CarrerForm from "./ComponentAll/Carrer/page/CarrerForm/CarrerForm.jsx"
import MCQTest from "./ComponentAll/Carrer/page/MCQTest/MCQTest.jsx";
import StartTest from "./ComponentAll/Carrer/page/StartTest/StartTest.jsx";
import LoginCarrer from "./ComponentAll/Carrer/page/LoginCarrer/LoginCarrer.jsx"
import CodeTest from "./ComponentAll/Carrer/page/codeTest/codeTest.jsx";
import DocumentUpload from "./ComponentAll/DocumentUpload/DocumentUpload.jsx"
import WelcomeStartTest from "./ComponentAll/WelcomeStartTest/WelcomeStartTest.jsx"
import DigitalMarketing from "./ComponentAll/Carrer/page/DigitalMarketing/DigitalMarketing.jsx";
import DocumentUploadAdmin from "./ComponentAll/DocumentUploadAdmin/DocumentUploadAdmin.jsx"
import CarrerFormDM from "./ComponentAll/Carrer/page/CarrerForm/CarrerFormDM/CarrerFormDM.jsx";
import LoginCarrerDM from "./ComponentAll/Carrer/page/LoginCarrer/LoginCarrerDM/LoginCarrerDM.jsx";
import DocumentUploadDM from "./ComponentAll/DocumentUploadDM/DocumentUploadDM.jsx";
// import Layout from "./ComponentAll/Layout.jsx";
import ScrollToTop from "./ComponentAll/ScrollToTop/ScrollToTop.jsx"
import AdminDashboard from "./ComponentAll/AdminDashboard/AdminDashboard.jsx";
import DocumentUploadAdminDM from "./ComponentAll/DocumentUploadAdmin/DocumentUploadDM/DocumentUploadAdminDM.jsx";
import CarrerFormUI from "./ComponentAll/Carrer/page/CarrerForm/CareerFormUI/CareerFormUI.jsx";
import UImcqtest from "./ComponentAll/UImcqtest/UImcqtest.jsx";
import UIWelcome from "./ComponentAll/UIWelcome/UIWelcome.jsx";
import DocumentUploadUI from "./ComponentAll/DocumentUploadUI/DocumentUploadUI.jsx";
import DocumentUploadAdminUI from "./ComponentAll/DocumentUploadAdmin/DocumentUploadAdminUI.jsx";


import Reactmcqtest from "./ComponentAll/Reactmcqtest/Reactmcqtest.jsx";
import DocumentUploadReact from "./ComponentAll/DocumentUploadReact/DocumentUploadReact.jsx";
import Reactcodetest from "./ComponentAll/Reactcodetest/Reactcodetest.jsx";
// import ReactDocumentUploadAdmin from "./ComponentAll/DocumentUploadAdmin/ReactDocumentUploadAdmin.jsx"
import ReactDocumentUploadAdmin from "./ComponentAll/DocumentUploadAdmin/ReactDocumentUploadAdmin.jsx"
import CarrerFormReact from "./ComponentAll/Carrer/page/CarrerForm/CarrerformReact/CarrerFormReact.jsx";
// import ReactWelcome from "./ComponentAll/ReactWelcome/ReactWelcome.jsx";
import ReactWelcome from "./ComponentAll/ReactWelcome/ReactWelcome.jsx";
// import CarrerFormReact from "./ComponentAll/Carrer/page/CarrerForm/CareerformReact/CareerformReact.jsx";


function App() {
  

  
  return (
    <>  
      <BrowserRouter>
        
        <div className="app"> 
      
      <ScrollToTop/>
          <Routes>

            <Route path="/" element={<Carrer />} />

            <Route path="/Admin" element={<Admin />} />
            <Route path="/AdminLogin" element={<AdminLogin />} />
            <Route path="/AdminData" element={<AdminData />} />
            <Route path="/CarrerForm" element={<CarrerForm />} />
            <Route path="/MCQTest" element={<MCQTest />} />
            <Route path="/CodeTest" element={<CodeTest />} />
            <Route path="/StartTest" element={<StartTest />} />
            <Route path="/DigitalMarketing" element={<DigitalMarketing />} />
            <Route path="/WelcomeStartTest" element={<WelcomeStartTest />} />
            <Route path="/LoginCarrer" element={<LoginCarrer />} />
            <Route path="/DocumentUpload" element={<DocumentUpload />} />
            <Route path="/CarrerFormDM" element={<CarrerFormDM />} />
            <Route path="/LoginCarrerDM" element={<LoginCarrerDM />} />
            <Route path="/DocumentUploadDM" element={<DocumentUploadDM />} />



<Route path="/CarrerFormUI" element={<CarrerFormUI />}/>
<Route path="/UIWelcome" element={<UIWelcome/>} />
<Route path="/UImcqtest" element={<UImcqtest/>} />
<Route path="/DocumentUploadUI" element={<DocumentUploadUI/>} />
<Route path="/DocumentUploadAdminUI" element={<DocumentUploadAdminUI/>} />





<Route path="/CarrerFormReact" element={<CarrerFormReact />}/>
<Route path="/ReactWelcome" element={<ReactWelcome/>} />
<Route path="/Reactmcqtest" element={<Reactmcqtest/>} />
<Route path="/Reactcodetest" element={<Reactcodetest/>} />
<Route path="/DocumentUploadReact" element={<DocumentUploadReact/>} />
<Route path="/ReactDocumentUploadAdmin" element={<ReactDocumentUploadAdmin/>} />
          </Routes>
        
          
        </div>
       
          <Routes>
            <Route path="/GetFreeEstimate" element={<GetFreeEstimate />} />
            <Route path="/EnquireNow" element={<EnquireNow />} />
            <Route path="/DocumentUploadAdmin" element={<DocumentUploadAdmin />} />
            <Route path="/AdminDashboard" element={<AdminDashboard />} />
            <Route path="/DocumentUploadAdminDM" element={<DocumentUploadAdminDM />} />

          </Routes>
        {/* </div> */}
      </BrowserRouter>

      {/* <BrowserRouter>
        <div className="AdminLogin" style={{ display: "flex" }}>
          <AdminData />
          <Routes>
            <Route path="/GetFreeEstimate" element={<GetFreeEstimate />} />
            <Route path="/EnquireNow" element={<EnquireNow />} />
          </Routes>
        </div>
      </BrowserRouter> */}
    </>
  );
    };

export default App;