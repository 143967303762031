
// // import React, { useState, useEffect, useRef, useCallback } from "react";
// // import axios from "axios";
// // import Checkbox from "@mui/material/Checkbox";
// // import FormControlLabel from "@mui/material/FormControlLabel";
// // import Dialog from "@mui/material/Dialog";
// // import DialogActions from "@mui/material/DialogActions";
// // import DialogContent from "@mui/material/DialogContent";
// // import DialogContentText from "@mui/material/DialogContentText";
// // import DialogTitle from "@mui/material/DialogTitle";
// // import Button from "@mui/material/Button";
// // import { useNavigate } from "react-router-dom";
// // import "./Reactmcqtest.css";

// // const Reactmcqtest = () => {
// //   const [questions, setQuestions] = useState([]);
// //   const [currentQuestion, setCurrentQuestion] = useState(0);
// //   const [score, setScore] = useState(0);
// //   const [showScore, setShowScore] = useState(false);
// //   const [selectedOptions, setSelectedOptions] = useState({});
// //   const [timer, setTimer] = useState(0);
// //   const [timerOn, setTimerOn] = useState(false);
// //   const [timeUp, setTimeUp] = useState(false);
// //   const [testStarted, setTestStarted] = useState(false);
// //   const [openDialog, setOpenDialog] = useState(false);
// //   const [cameraAccessDialogOpen, setCameraAccessDialogOpen] = useState(false);

// //   const testDuration = 40 * 60;
// //   const navigate = useNavigate();
// //   const videoRef = useRef(null);

// //   const handleSubmitTest = useCallback(async () => {
// //     setShowScore(true);
// //     setTimerOn(false);
// //     stopCamera(); // Stop the camera when the test is submitted
// //     let finalScore = 0;
// //     questions.forEach((question, index) => {
// //       if (selectedOptions[index] === question.correctanswer) {
// //         finalScore += 1;
// //       }
// //     });
// //     setScore(finalScore);
// //     console.log(finalScore);

// //     const postData = {
// //       name: "Your Name", // Replace with actual user data
// //       email: "your.email@example.com", // Replace with actual user data
// //       mcqScore: finalScore,
// //       mcqTotalQuestions: questions.length,
// //       codingTotalQuestions: 0, // Replace with actual coding question data if applicable
// //       codes: [], // Replace with actual code answers if applicable
// //     };
// //     try {
// //       console.log("Posting MCQ test data:", postData); // Log the payload
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_API_URL}/api/react-test-score`,
// //         postData
// //       );
// //       console.log("Test score posted successfully:", response.data);
// //     } catch (error) {
// //       console.error("Error posting test score:", error);
// //     }
// //   }, [selectedOptions, questions]);

// //   useEffect(() => {
// //     let intervalId;
// //     if (timerOn) {
// //       intervalId = setInterval(() => {
// //         setTimer((prevTimer) => prevTimer + 1);
// //         if (timer >= testDuration) {
// //           setTimerOn(false);
// //           setTimeUp(true);
// //           handleSubmitTest();
// //         }
// //       }, 1000);
// //     }
// //     return () => clearInterval(intervalId);
// //   }, [timerOn, timer, testDuration, handleSubmitTest]);

// //   useEffect(() => {
// //     const handleVisibilityChange = () => {
// //       if (testStarted && document.hidden) {
// //         setOpenDialog(true);
// //       }
// //     };
// //     document.addEventListener("visibilitychange", handleVisibilityChange);
// //     return () => {
// //       document.removeEventListener("visibilitychange", handleVisibilityChange);
// //     };
// //   }, [testStarted]);

// //   useEffect(() => {
// //     if (testStarted) {
// //       setCameraAccessDialogOpen(true);
// //     } else {
// //       stopCamera();
// //     }
// //   }, [testStarted]);

// //   useEffect(() => {
// //     const cameraCheckInterval = setInterval(() => {
// //       if (testStarted) {
// //         checkCameraAccess();
// //       }
// //     }, 5000); // Check every 5 seconds
  
// //     return () => clearInterval(cameraCheckInterval);
// //   }, [testStarted]);
  

// //   const checkCameraAccess = async () => {
// //     try {
// //       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
// //       if (videoRef.current) {
// //         videoRef.current.srcObject = stream;
// //       }
// //     } catch (error) {
// //       setCameraAccessDialogOpen(true);
// //     }
// //   };
  

// //   const startCamera = async () => {
// //     try {
// //       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
// //       if (videoRef.current) {
// //         videoRef.current.srcObject = stream;
// //       }
// //     } catch (error) {
// //       setCameraAccessDialogOpen(true);
// //       console.error("Error accessing the camera:", error);
// //     }
// //   };

// //   const stopCamera = () => {
// //     if (videoRef.current && videoRef.current.srcObject) {
// //       const tracks = videoRef.current.srcObject.getTracks();
// //       tracks.forEach((track) => track.stop());
// //       videoRef.current.srcObject = null;
// //     }
// //   };

// //   const handleOptionChange = (option) => {
// //     setSelectedOptions({
// //       ...selectedOptions,
// //       [currentQuestion]: option,
// //     });
// //   };

// //   const handleNextQuestion = () => {
// //     const nextQuestion = currentQuestion + 1;
// //     if (nextQuestion < questions.length) {
// //       setSelectedOptions({
// //         ...selectedOptions,
// //         [currentQuestion]: selectedOptions[currentQuestion] || "", // Store the current question's answer even if not selected
// //       });
// //       setCurrentQuestion(nextQuestion);
// //     } else {
// //       handleSubmitTest();
// //     }
// //   };

// //   const handlePrevQuestion = () => {
// //     const prevQuestion = currentQuestion - 1;
// //     if (prevQuestion >= 0) {
// //       setCurrentQuestion(prevQuestion);
// //     }
// //   };

// //   const formatTime = (timeInSeconds) => {
// //     const minutes = Math.floor(timeInSeconds / 60);
// //     const seconds = timeInSeconds % 60;
// //     return `${minutes.toString().padStart(2, "0")}:${seconds
// //       .toString()
// //       .padStart(2, "0")}`;
// //   };

// //   const handleCloseDialog = () => {
// //     setOpenDialog(false);
// //     handleSubmitTest();
// //   };

// //   const handleCameraAccess = () => {
// //     setCameraAccessDialogOpen(false);
// //     startCamera();
// //   };

// //   const fetchQuestions = async () => {
// //     try {
// //       const response = await axios.get(
// //         `${process.env.REACT_APP_API_URL}/api/react-questions`
// //       );
// //       setQuestions(response.data);
// //     } catch (error) {
// //       console.error("Error fetching questions:", error);
// //     }
// //   };

// //   const handleCloseScoreDialog = () => {
// //     navigate("/Reactcodetest", {
// //       state: {
// //         mcqTestResults: {
// //           mcqScore: score,
// //           mcqTotalQuestions: questions.length,
// //         },
// //       },
// //     });
// //   };

// //   return (
// //     <div className="quiz-container">
// //       <Dialog open={openDialog} onClose={handleCloseDialog}>
// //         <DialogTitle>Tab Switch Detected</DialogTitle>
// //         <DialogContent>
// //           <DialogContentText>
// //             You have switched tabs. The test will now be submitted.
// //           </DialogContentText>
// //         </DialogContent>
// //         <DialogActions>
// //           <Button onClick={handleCloseDialog} color="primary">
// //             OK
// //           </Button>
// //         </DialogActions>
// //       </Dialog>

// //       <Dialog open={cameraAccessDialogOpen}>
// //         <DialogTitle>Camera Access Required</DialogTitle>
// //         <DialogContent>
// //           <DialogContentText>
// //             This test requires access to your camera. Please allow camera access
// //             to proceed with the test.
// //           </DialogContentText>
// //         </DialogContent>
// //         <DialogActions>
// //           <Button onClick={handleCameraAccess} color="primary">
// //             Allow
// //           </Button>
// //         </DialogActions>
// //       </Dialog>

// //       <h1 className="coding-test text-white p-3 d-flex justify-content-center mb-4">
// //         MCQ TEST
// //       </h1>

// //       {!timerOn && !showScore && (
// //         <div className="start-container">
// //           <h2>
// //             <strong>Read carefully</strong>
// //           </h2>
// //           <div className="test-criteria">
// //             <p>
// //               <strong>Format:</strong> The test consists of 20 multiple-choice
// //               questions (MCQs).
// //             </p>
// //             <p>
// //               <strong>Passing Score:</strong> A minimum of 60% is required for
// //               selection.
// //             </p>
// //             <p>
// //               <strong>Time Limit: </strong>The test must be completed within 40
// //               minutes.
// //             </p>
// //             <p>
// //               <strong>Camera Acess </strong>Allow the Camera acess from settings..Before start test..
// //             </p>
// //             <p>
// //               <strong>Warning: </strong>Don't change the tab when the exam will
// //               start. Otherwise, the exam will be submitted.
// //             </p>
// //             <p>All The Best !!</p>
// //           </div>
// //           <button
// //             className="start-btn"
// //             onClick={() => {
// //               fetchQuestions();
// //               setTimerOn(true);
// //               setTestStarted(true);
// //             }}
// //           >
// //             Start MCQ Test
// //           </button>
// //         </div>
// //       )}

// //       {timerOn && (
// //         <div className="timer">
// //           Time Left:{" "}
// //           {timer < testDuration ? formatTime(testDuration - timer) : "00:00"}
// //         </div>
// //       )}

// //       {timeUp && (
// //         <div className="quiz-card score-card">
// //           <h1>Time's Up!</h1>
// //           <h2>
// //             Your Score: {score} out of {questions.length}
// //           </h2>
// //         </div>
// //       )}

// //       {!timeUp && timerOn && !showScore && (
// //         <div className="quiz-card">
// //           <div className="quiz-card-body">
// //             <h5 className="quiz-card-title">
// //               Question {currentQuestion + 1} / {questions.length}
// //             </h5>
// //             <p className="quiz-card-text">
// //               {questions[currentQuestion]?.question}
// //             </p>
// //             <div className="quiz-options">
// //               {questions[currentQuestion]?.options.map((option, index) => (
// //                 <FormControlLabel
// //                   key={index}
// //                   control={
// //                     <Checkbox
// //                       checked={selectedOptions[currentQuestion] === option}
// //                       onChange={() => handleOptionChange(option)}
// //                     />
// //                   }
// //                   label={option}
// //                 />
// //               ))}
// //             </div>
// //             <div className="quiz-navigation">
// //               {currentQuestion > 0 && (
// //                 <button className="quiz-btn-back" onClick={handlePrevQuestion}>
// //                   Back
// //                 </button>
// //               )}
// //               {currentQuestion < questions.length - 1 ? (
// //                 <button
// //                   className="quiz-btn-next"
// //                   onClick={handleNextQuestion}
// //                   disabled={!selectedOptions[currentQuestion]}
// //                 >
// //                   Next
// //                 </button>
// //               ) : (
// //                 <button
// //                   className="quiz-btn-submit"
// //                   onClick={handleSubmitTest}
// //                   disabled={!selectedOptions[currentQuestion]}
// //                 >
// //                   Submit Test
// //                 </button>
// //               )}
// //             </div>

// //             {!selectedOptions[currentQuestion] && (
// //               <p>Please select an option.</p>
// //             )}
// //           </div>
// //         </div>
// //       )}

// //       {showScore && (
// //         <div className="quiz-card score-card">
// //           <h3>Test Submitted Successfully</h3>
// //           {/* <h2>Your Score: {score} out of {questions.length}</h2> */}
// //           <h4>Percentage: {((score / questions.length) * 100).toFixed(2)}%</h4>
// //           <h5>
// //             {(score / questions.length) * 100 >= 60
// //               ? "You have Passed "
// //               : "you have Failed"}
// //           </h5>
// //           <Button onClick={handleCloseScoreDialog} className="custom-button">
// //             Next: Start Coding Test
// //           </Button>
// //         </div>
// //       )}

// //       <div className="camera">
// //         <video ref={videoRef} autoPlay playsInline className="video-feed" />
// //       </div>


// //       <Dialog
// //   open={cameraAccessDialogOpen}
// //   aria-labelledby="camera-access-dialog-title"
// //   aria-describedby="camera-access-dialog-description"
// // >
// //   <DialogTitle id="camera-access-dialog-title">
// //     {"Camera Access Required"}
// //   </DialogTitle>
// //   <DialogContent>
// //     <DialogContentText id="camera-access-dialog-description">
// //       To ensure the integrity of the test, we need to access your camera.
// //       Please allow camera access to proceed with the test.
// //     </DialogContentText>
// //   </DialogContent>
// //   <DialogActions>
// //     <Button onClick={handleCameraAccess} color="primary" autoFocus>
// //       Start Test
// //     </Button>
// //   </DialogActions>
// // </Dialog>


// //     </div>
// //   );
// // };

// // export default Reactmcqtest;


// import React, { useState, useEffect, useRef, useCallback } from "react";
// import Checkbox from "@mui/material/Checkbox";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
// import DialogTitle from "@mui/material/DialogTitle";
// import Button from "@mui/material/Button";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import "./Reactmcqtest.css";

// const Reactmcqtest = () => {
//   const [questions, setQuestions] = useState([]);
//   const [currentQuestion, setCurrentQuestion] = useState(0);
//   const [score, setScore] = useState(0);
//   const [showScore, setShowScore] = useState(false);
//   const [selectedOptions, setSelectedOptions] = useState({});
//   const [timer, setTimer] = useState(0);
//   const [timerOn, setTimerOn] = useState(false);
//   const [timeUp, setTimeUp] = useState(false);
//   const [testStarted, setTestStarted] = useState(false);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [cameraAccessDialogOpen, setCameraAccessDialogOpen] = useState(false);

//   const testDuration = 40 * 60; // 40 minutes
//   const navigate = useNavigate();
//   const videoRef = useRef(null);

//   const handleSubmitTest = useCallback(() => {
//     setShowScore(true);
//     setTimerOn(false);
//     stopCamera(); // Stop the camera when the test is submitted
//     let finalScore = 0;
//     questions.forEach((question, index) => {
//       if (selectedOptions[index] === question.correctanswer) {
//         finalScore += 1;
//       }
//     });
//     setScore(finalScore);
//   }, [selectedOptions, questions]);

//   useEffect(() => {
//     let intervalId;
//     if (timerOn) {
//       intervalId = setInterval(() => {
//         setTimer((prevTimer) => prevTimer + 1);
//         if (timer >= testDuration) {
//           setTimerOn(false);
//           setTimeUp(true);
//           handleSubmitTest();
//         }
//       }, 1000);
//     }
//     return () => clearInterval(intervalId);
//   }, [timerOn, timer, testDuration, handleSubmitTest]);

//   useEffect(() => {
//     const handleVisibilityChange = () => {
//       if (testStarted && document.hidden) {
//         setOpenDialog(true);
//       }
//     };
//     document.addEventListener("visibilitychange", handleVisibilityChange);
//     return () => {
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//     };
//   }, [testStarted]);

//   useEffect(() => {
//     if (testStarted) {
//       setCameraAccessDialogOpen(true);
//     } else {
//       stopCamera();
//     }
//   }, [testStarted]);

//   useEffect(() => {
//     const cameraCheckInterval = setInterval(() => {
//       if (testStarted) {
//         checkCameraAccess();
//       }
//     }, 5000); // Check every 5 seconds
  
//     return () => clearInterval(cameraCheckInterval);
//   }, [testStarted]);

//   const checkCameraAccess = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//       }
//     } catch (error) {
//       setCameraAccessDialogOpen(true);
//     }
//   };

//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//       }
//     } catch (error) {
//       setCameraAccessDialogOpen(true);
//       console.error("Error accessing the camera:", error);
//     }
//   };

//   const stopCamera = () => {
//     if (videoRef.current && videoRef.current.srcObject) {
//       const tracks = videoRef.current.srcObject.getTracks();
//       tracks.forEach((track) => track.stop());
//       videoRef.current.srcObject = null;
//     }
//   };

//   const handleOptionChange = (option) => {
//     setSelectedOptions({
//       ...selectedOptions,
//       [currentQuestion]: option,
//     });
//   };

//   const handleNextQuestion = () => {
//     const nextQuestion = currentQuestion + 1;
//     if (nextQuestion < questions.length) {
//       setSelectedOptions({
//         ...selectedOptions,
//         [currentQuestion]: selectedOptions[currentQuestion] || "", // Store the current question's answer even if not selected
//       });
//       setCurrentQuestion(nextQuestion);
//     } else {
//       handleSubmitTest();
//     }
//   };

//   const handlePrevQuestion = () => {
//     const prevQuestion = currentQuestion - 1;
//     if (prevQuestion >= 0) {
//       setCurrentQuestion(prevQuestion);
//     }
//   };

//   const formatTime = (timeInSeconds) => {
//     const minutes = Math.floor(timeInSeconds / 60);
//     const seconds = timeInSeconds % 60;
//     return `${minutes.toString().padStart(2, "0")}:${seconds
//       .toString()
//       .padStart(2, "0")}`;
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//     handleSubmitTest();
//   };

//   const handleCameraAccess = () => {
//     setCameraAccessDialogOpen(false);
//     startCamera();
//   };

//   const fetchQuestions = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/api/react-questions`
//       );
//       setQuestions(response.data);
//     } catch (error) {
//       console.error("Error fetching questions:", error);
//     }
//   };

//   return (
//     <div className="quiz-container">
//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>Tab Switch Detected</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             You have switched tabs. The test will now be submitted.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <Dialog open={cameraAccessDialogOpen}>
//         <DialogTitle>Camera Access Required</DialogTitle>
//         <DialogContent>
//           <DialogContentText>
//             This test requires access to your camera. Please allow camera access
//             to proceed with the test.
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCameraAccess} color="primary">
//             Allow
//           </Button>
//         </DialogActions>
//       </Dialog>

//       <h1 className="coding-test text-white p-3 d-flex justify-content-center mb-4">
//         MCQ TEST
//       </h1>

//       {!timerOn && !showScore && (
//         <div className="start-container">
//           <h2>
//             <strong>Read carefully</strong>
//           </h2>
//           <div className="test-criteria">
//             <p>
//               <strong>Format:</strong> The test consists of 20 multiple-choice
//               questions (MCQs).
//             </p>
//             <p>
//               <strong>Passing Score:</strong> A minimum of 60% is required for
//               selection.
//             </p>
//             <p>
//               <strong>Time Limit: </strong>The test must be completed within 40
//               minutes.
//             </p>
//             <p>
//               <strong>Camera Access:</strong> Allow the camera access from settings before starting the test.
//             </p>
//             <p>
//               <strong>Warning: </strong>Don't change the tab when the exam starts. Otherwise, the exam will be submitted.
//             </p>
//             <p>All The Best !!</p>
//           </div>
//           <button
//             className="start-btn"
//             onClick={() => {
//               fetchQuestions();
//               setTimerOn(true);
//               setTestStarted(true);
//             }}
//           >
//             Start MCQ Test
//           </button>
//         </div>
//       )}

//       {timerOn && (
//         <div className="timer">
//           Time Left:{" "}
//           {timer < testDuration ? formatTime(testDuration - timer) : "00:00"}
//         </div>
//       )}

//       {timeUp && (
//         <div className="quiz-card score-card">
//           <h1>Time's Up!</h1>
//           <h2>
//             Your Score: {score} out of {questions.length}
//           </h2>
//         </div>
//       )}

//       {!timeUp && timerOn && !showScore && (
//         <div className="quiz-card">
//           <h2>
//             Question {currentQuestion + 1} / {questions.length}
//           </h2>
//           <h3>{questions[currentQuestion]?.question}</h3>
//           {questions[currentQuestion]?.options.map((option) => (
//             <FormControlLabel
//               key={option}
//               control={
//                 <Checkbox
//                   checked={selectedOptions[currentQuestion] === option}
//                   onChange={() => handleOptionChange(option)}
//                 />
//               }
//               label={option}
//             />
//           ))}
//           <div className="quiz-nav">
//             <button
//               className="prev-btn"
//               onClick={handlePrevQuestion}
//               disabled={currentQuestion === 0}
//             >
//               Previous
//             </button>
//             <button
//               className="next-btn"
//               onClick={handleNextQuestion}
//               disabled={
//                 selectedOptions[currentQuestion] === undefined &&
//                 currentQuestion < questions.length - 1
//               }
//             >
//               {currentQuestion === questions.length - 1 ? "Submit" : "Next"}
//             </button>
//           </div>
//         </div>
//       )}

//       {showScore && (
//         <div className="quiz-card score-card">
//           <h1>Test Completed</h1>
//           <h2>
//             Your Score: {score} out of {questions.length}
//           </h2>
//           <button onClick={() => navigate("/")} className="view-score-btn">
//             View Results
//           </button>
//         </div>
//       )}

//       <video ref={videoRef} style={{ display: "none" }} autoPlay />
//     </div>
//   );
// };

// export default Reactmcqtest;




import React, { useState, useEffect, useRef, useCallback } from "react";
import axios from "axios";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import "./Reactmcqtest.css";

const Reactmcqtest = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [timer, setTimer] = useState(0);
  const [timerOn, setTimerOn] = useState(false);
  const [timeUp, setTimeUp] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [cameraAccessDialogOpen, setCameraAccessDialogOpen] = useState(false);

  const testDuration = 40 * 60;
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const handleSubmitTest = useCallback(async () => {
    setShowScore(true);
    setTimerOn(false);
    stopCamera(); // Stop the camera when the test is submitted
    let finalScore = 0;
    questions.forEach((question, index) => {
      if (selectedOptions[index] === question.correct_answer) {
        finalScore += 1;
      }
    });
    setScore(finalScore);
    console.log(finalScore);

    const postData = {
      name: "Your Name", // Replace with actual user data
      email: "your.email@example.com", // Replace with actual user data
      mcqScore: finalScore,
      mcqTotalQuestions: questions.length,
      codingTotalQuestions: 0, // Replace with actual coding question data if applicable
      codes: [], // Replace with actual code answers if applicable
    };
    try {
      console.log("Posting MCQ test data:", postData); // Log the payload
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/react-test-scores`,
        postData
      );
      console.log("Test score posted successfully:", response.data);
    } catch (error) {
      console.error("Error posting test score:", error);
    }
  }, [selectedOptions, questions]);

  useEffect(() => {
    let intervalId;
    if (timerOn) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
        if (timer >= testDuration) {
          setTimerOn(false);
          setTimeUp(true);
          handleSubmitTest();
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [timerOn, timer, testDuration, handleSubmitTest]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (testStarted && document.hidden) {
        setOpenDialog(true);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [testStarted]);

  useEffect(() => {
    if (testStarted) {
      setCameraAccessDialogOpen(true);
    } else {
      stopCamera();
    }
  }, [testStarted]);

  useEffect(() => {
    const cameraCheckInterval = setInterval(() => {
      if (testStarted) {
        checkCameraAccess();
      }
    }, 5000); // Check every 5 seconds
  
    return () => clearInterval(cameraCheckInterval);
  }, [testStarted]);
  

  const checkCameraAccess = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setCameraAccessDialogOpen(true);
    }
  };
  

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }
    } catch (error) {
      setCameraAccessDialogOpen(true);
      console.error("Error accessing the camera:", error);
    }
  };

  const stopCamera = () => {
    if (videoRef.current && videoRef.current.srcObject) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  const handleOptionChange = (option) => {
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestion]: option,
    });
  };

  const handleNextQuestion = () => {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < questions.length) {
      setSelectedOptions({
        ...selectedOptions,
        [currentQuestion]: selectedOptions[currentQuestion] || "", // Store the current question's answer even if not selected
      });
      setCurrentQuestion(nextQuestion);
    } else {
      handleSubmitTest();
    }
  };

  const handlePrevQuestion = () => {
    const prevQuestion = currentQuestion - 1;
    if (prevQuestion >= 0) {
      setCurrentQuestion(prevQuestion);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    handleSubmitTest();
  };

  const handleCameraAccess = () => {
    setCameraAccessDialogOpen(false);
    startCamera();
  };

  const fetchQuestions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/react-questions`
      );
      setQuestions(response.data);
    } catch (error) {
      console.error("Error fetching questions:", error);
    }
  };

  const handleCloseScoreDialog = () => {
    navigate("/Reactcodetest", {
      state: {
        mcqTestResults: {
          mcqScore: score,
          mcqTotalQuestions: questions.length,
        },
      },
    });
  };

  return (
    <div className="quiz-container">
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Tab Switch Detected</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You have switched tabs. The test will now be submitted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={cameraAccessDialogOpen}>
        <DialogTitle>Camera Access Required</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This test requires access to your camera. Please allow camera access
            to proceed with the test.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCameraAccess} color="primary">
            Allow
          </Button>
        </DialogActions>
      </Dialog>

      <h1 className="coding-test text-white p-3 d-flex justify-content-center mb-4">
        MCQ TEST
      </h1>

      {!timerOn && !showScore && (
        <div className="start-container">
          <h2>
            <strong>Read carefully</strong>
          </h2>
          <div className="test-criteria">
            <p>
              <strong>Format:</strong> The test consists of 20 multiple-choice
              questions (MCQs).
            </p>
            <p>
              <strong>Passing Score:</strong> A minimum of 60% is required for
              selection.
            </p>
            <p>
              <strong>Time Limit: </strong>The test must be completed within 40
              minutes.
            </p>
            <p>
              <strong>Camera Acess </strong>Allow the Camera acess from settings..Before start test..
            </p>
            <p>
              <strong>Warning: </strong>Don't change the tab when the exam will
              start. Otherwise, the exam will be submitted.
            </p>
            <p>All The Best !!</p>
          </div>
          <button
            className="start-btn"
            onClick={() => {
              fetchQuestions();
              setTimerOn(true);
              setTestStarted(true);
            }}
          >
            Start MCQ Test
          </button>
        </div>
      )}

      {timerOn && (
        <div className="timer">
          Time Left:{" "}
          {timer < testDuration ? formatTime(testDuration - timer) : "00:00"}
        </div>
      )}

      {timeUp && (
        <div className="quiz-card score-card">
          <h1>Time's Up!</h1>
          <h2>
            Your Score: {score} out of {questions.length}
          </h2>
        </div>
      )}

      {!timeUp && timerOn && !showScore && (
        <div className="quiz-card">
          <div className="quiz-card-body">
            <h5 className="quiz-card-title">
              Question {currentQuestion + 1} / {questions.length}
            </h5>
            <p className="quiz-card-text">
              {questions[currentQuestion]?.question}
            </p>
            <div className="quiz-options">
              {questions[currentQuestion]?.options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Checkbox
                      checked={selectedOptions[currentQuestion] === option}
                      onChange={() => handleOptionChange(option)}
                    />
                  }
                  label={option}
                />
              ))}
            </div>
            <div className="quiz-navigation">
              {currentQuestion > 0 && (
                <button className="quiz-btn-back" onClick={handlePrevQuestion}>
                  Back
                </button>
              )}
              {currentQuestion < questions.length - 1 ? (
                <button
                  className="quiz-btn-next"
                  onClick={handleNextQuestion}
                  disabled={!selectedOptions[currentQuestion]}
                >
                  Next
                </button>
              ) : (
                <button
                  className="quiz-btn-submit"
                  onClick={handleSubmitTest}
                  disabled={!selectedOptions[currentQuestion]}
                >
                  Submit Test
                </button>
              )}
            </div>

            {!selectedOptions[currentQuestion] && (
              <p>Please select an option.</p>
            )}
          </div>
        </div>
      )}

      {showScore && (
        <div className="quiz-card score-card">
          <h3>Test Submitted Successfully</h3>
          {/* <h2>Your Score: {score} out of {questions.length}</h2> */}
          <h4>Percentage: {((score / questions.length) * 100).toFixed(2)}%</h4>
          <h5>
            {(score / questions.length) * 100 >= 60
              ? "You have Passed "
              : "you have Failed"}
          </h5>
          <Button onClick={handleCloseScoreDialog} className="custom-button">
            Next: Start Coding Test
          </Button>
        </div>
      )}

      <div className="camera">
        <video ref={videoRef} autoPlay playsInline className="video-feed" />
      </div>


      <Dialog
  open={cameraAccessDialogOpen}
  aria-labelledby="camera-access-dialog-title"
  aria-describedby="camera-access-dialog-description"
>
  <DialogTitle id="camera-access-dialog-title">
    {"Camera Access Required"}
  </DialogTitle>
  <DialogContent>
    <DialogContentText id="camera-access-dialog-description">
      To ensure the integrity of the test, we need to access your camera.
      Please allow camera access to proceed with the test.
    </DialogContentText>
  </DialogContent>
  <DialogActions>
    <Button onClick={handleCameraAccess} color="primary" autoFocus>
      Start Test
    </Button>
  </DialogActions>
</Dialog>


    </div>
  );
};

export default Reactmcqtest;
