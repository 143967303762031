// import React, { useEffect, useState } from "react";
// import AdminData from "../AdminData/AdminData";
// import "./GetFreeEstimate.css";
// const GetFREEEstimate = () => {
//   const [contacts, setContacts] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   useEffect(() => {
//     fetchContacts();
//   }, [currentPage]);
//   const fetchContacts = () => {
//     fetch(`${process.env.REACT_APP_API_URL}/registrations?page=${currentPage}`)
//       .then((response) => {
//         if (!response.ok) {
//           throw new Error("Network response was not ok");
//         }
//         return response.json();
//       })
//       .then((data) => {
//         if (Array.isArray(data) && data.length > 0) {
//           setContacts(data);
//         } else {
//           throw new Error("Invalid data format");
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching contacts:", error);
//         setContacts([]);
//       });
//   };
//   const handleNextPage = () => {
//     setCurrentPage((prevPage) => prevPage + 1);
//   };
//   const handlePrevPage = () => {
//     setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
//   };
//   return (
//     <>
//       <AdminData />
//       <div className="AdminLogin" style={{ display: "flex" }}></div>
//       <div className="container">
//         <h2 className="get-free-estimate-admin">Get FREE Estimate</h2>
//         <table className="table custom-table">
//           <thead className="thead-dark">
//             <tr>
//               <th scope="col">Sr No</th>
//               <th scope="col">Name</th>
//               <th scope="col">Email</th>
//               <th scope="col">Contact Number</th>
//             </tr>
//           </thead>
//           <tbody>
//             {contacts.map((data, index) => (
//               <tr key={data.id}>
//                 <td>{index + 1}</td>
//                 <td>{data.name}</td>
//                 <td>{data.email}</td>
//                 <td>{data.contact_number}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         <div className="pagination">
//           <button
//             className="btn btn-primary mr-2"
//             onClick={handlePrevPage}
//             disabled={currentPage === 1}
//           >
//             Previous
//           </button>
//           <span>{`Page ${currentPage}`}</span>
//           <button className="btn btn-primary ml-2" onClick={handleNextPage}>
//             Next
//           </button>
//         </div>
//       </div>
//     </>
//   );
// };
// export default GetFREEEstimate;

import React, { useEffect, useState } from "react";
import AdminData from "../AdminData/AdminData";
import "./GetFreeEstimate.css";
const GetFREEEstimate = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  useEffect(() => {
    const fetchContacts = () => {
      fetch(`${process.env.REACT_APP_API_URL}/registrations?page=${currentPage}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            setContacts(data);
          } else {
            throw new Error("Invalid data format");
          }
        })
        .catch((error) => {
          console.error("Error fetching contacts:", error);
          setContacts([]);
        });
    };
    fetchContacts();
  }, [currentPage]);
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  return (
    <>
      <AdminData />
      <div className="AdminLogin" style={{ display: "flex" }}></div>
      <div className="container">
        <h2 className="get-free-estimate-admin">Get FREE Estimate</h2>
        <table className="table custom-table">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Sr No</th>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Contact Number</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((data, index) => (
              <tr key={data.id}>
                <td>{index + 1}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.contact_number}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          <button
            className="btn btn-primary mr-2"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span>{`Page ${currentPage}`}</span>
          <button className="btn btn-primary ml-2" onClick={handleNextPage}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};
export default GetFREEEstimate;
