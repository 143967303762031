//   // import React, { useState, useEffect, useCallback } from "react";
//   // import { Container, Table, Button, Modal } from "react-bootstrap";
//   // import { FaEye, FaTrashAlt } from "react-icons/fa";
//   // import axios from "axios";
//   // import {
//   //   Dialog,
//   //   DialogTitle,
//   //   DialogContent,
//   //   DialogContentText,
//   //   DialogActions,
//   // } from "@material-ui/core";

//   // const DocumentUploadAdminDM = () => {
//   //   const [candidates, setCandidates] = useState([]);
//   //   const [currentPage, setCurrentPage] = useState(1);
//   //   const [perPage] = useState(50); // Number of items per page
//   //   const [totalItems, setTotalItems] = useState(0); // Total number of items
//   //   const [openImageDialog, setOpenImageDialog] = useState(false);
//   //   const [imageSrc, setImageSrc] = useState("");

//   //   const fetchData = useCallback(async () => {
//   //     try {
//   //       const response = await axios.get(
//   //         `${process.env.REACT_APP_API_URL}/admin/dmcandidates`,
//   //         {
//   //           params: {
//   //             _page: currentPage, // Pass current page number as a query parameter
//   //             _limit: perPage, // Limit number of items per page
//   //           },
//   //         }
//   //       );
//   //       setCandidates(response.data);
//   //       setTotalItems(parseInt(response.headers["x-total-count"], 10)); // Assuming the total count is provided in the headers
//   //     } catch (error) {
//   //       console.error("Error fetching data:", error);
//   //     }
//   //   }, [currentPage, perPage]);

//   //   useEffect(() => {
//   //     fetchData();
//   //   }, [currentPage, fetchData]);

//   //   const handleNextPage = () => {
//   //     if (currentPage < Math.ceil(totalItems / perPage)) {
//   //       setCurrentPage((prevPage) => prevPage + 1);
//   //     }
//   //   };

//   //   const handlePrevPage = () => {
//   //     if (currentPage > 1) {
//   //       setCurrentPage((prevPage) => prevPage - 1);
//   //     }
//   //   };

//   //   const handleResumeClick = async (candidateId) => {
//   //     try {
//   //       const response = await axios.get(
//   //         `${process.env.REACT_APP_API_URL}/resume_dm/${candidateId}`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       );
//   //       console.log("Response:", response); // Log the entire response to see what's being returned
//   //       if (response && response.headers) {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType && contentType.startsWith("application/pdf")) {
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType && contentType.startsWith("image/")) {
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       } else {
//   //         console.error("Content-Type header is missing in the response.");
//   //       }
//   //     } catch (error) {
//   //       if (error.response && error.response.status === 404) {
//   //         console.error("Resume not found.");
//   //       } else {
//   //         console.error("Error fetching resume:", error);
//   //       }
//   //     }
//   //   };

//   //   const handleInternshipletter = async (candidateEmail) => {
//   //     axios
//   //       .get(
//   //         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/internship_letter`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       )
//   //       .then((response) => {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType.startsWith("application/pdf")) {
//   //           // PDF content
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType.startsWith("image/")) {
//   //           // Image content
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching image:", error);
//   //       });
//   //   };

//   //   const handleExperienceletter = async (candidateEmail) => {
//   //     axios
//   //       .get(
//   //         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/experience_letter`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       )
//   //       .then((response) => {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType.startsWith("application/pdf")) {
//   //           // PDF content
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType.startsWith("image/")) {
//   //           // Image content
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching image:", error);
//   //       });
//   //   };

//   //   const handleAadharCard = async (candidateEmail) => {
//   //     axios
//   //       .get(
//   //         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/aadhar_card`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       )
//   //       .then((response) => {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType.startsWith("application/pdf")) {
//   //           // PDF content
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType.startsWith("image/")) {
//   //           // Image content
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching image:", error);
//   //       });
//   //   };

//   //   const handleHSC = async (candidateEmail) => {
//   //     axios
//   //       .get(
//   //         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/twelth_certificate`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       )
//   //       .then((response) => {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType.startsWith("application/pdf")) {
//   //           // PDF content
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType.startsWith("image/")) {
//   //           // Image content
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching image:", error);
//   //       });
//   //   };

//   //   const handleSSC = async (candidateEmail) => {
//   //     axios
//   //       .get(
//   //         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/tenth_certificate`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       )
//   //       .then((response) => {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType.startsWith("application/pdf")) {
//   //           // PDF content
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType.startsWith("image/")) {
//   //           // Image content
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching image:", error);
//   //       });
//   //   };

//   //   const handleDegreeCertificate = async (candidateEmail) => {
//   //     axios
//   //       .get(
//   //         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/degree_certificate`,
//   //         {
//   //           responseType: "blob",
//   //         }
//   //       )
//   //       .then((response) => {
//   //         const contentType = response.headers["content-type"];
//   //         if (contentType.startsWith("application/pdf")) {
//   //           // PDF content
//   //           const pdfUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           window.open(pdfUrl);
//   //         } else if (contentType.startsWith("image/")) {
//   //           // Image content
//   //           const imageUrl = URL.createObjectURL(
//   //             new Blob([response.data], { type: contentType })
//   //           );
//   //           setImageSrc(imageUrl);
//   //           setOpenImageDialog(true);
//   //         } else {
//   //           console.error("Unsupported content type:", contentType);
//   //         }
//   //       })
//   //       .catch((error) => {
//   //         console.error("Error fetching image:", error);
//   //       });
//   //   };

//   //   const handleCloseImageDialog = () => {
//   //     setOpenImageDialog(false);
//   //     setImageSrc("");
//   //   };

//   //   const handleDeleteCandidate = async (id) => {
//   //     try {
//   //       const response = await axios.delete(
//   //         `${process.env.REACT_APP_API_URL}/dm_application_entries/${id}`
//   //       );
//   //       console.log("Candidate deleted:", response.data);

//   //       // Update the candidates state by filtering out the deleted candidate
//   //       setCandidates((prevCandidates) =>
//   //         prevCandidates.filter((candidate) => candidate.id !== id)
//   //       );

//   //       // Optionally, show a success message or perform other actions
//   //     } catch (error) {
//   //       console.error("Error deleting candidate:", error);
//   //       // Optionally, show an error message or handle the error
//   //     }
//   //   };

//   //   return (
//   //     <>
//   //       <Container className="document-upload-container ml-6">
//   //         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
//   //           View Documents DigitalMarketing Job
//   //         </h2>
//   //         <Table responsive striped bordered hover className="custom-table">
//   //           <thead>
//   //             <tr>
//   //               <th>Full Name</th>
//   //               <th>Email</th>
//   //               <th>Mobile Number</th>
//   //               <th>Experience Level</th>
//   //               <th>Available Starting Date</th>
//   //               <th>Skills</th>
//   //               <th>Resume</th>
//   //               <th>MCQ Score</th>
//   //               <th>10th Certificate</th>
//   //               <th>12th Certificate/Diploma</th>
//   //               <th>Highest Degree Certificate</th>
//   //               <th>Aadhar Card</th>
//   //               <th>Internship Letter</th>
//   //               <th>Experience Letter</th>
//   //               <th>Actions</th>
//   //             </tr>
//   //           </thead>
//   //           <tbody>
//   //             {candidates.map((candidate, index) => (
//   //               <tr key={index}>
//   //                 <td>{candidate.full_name}</td>
//   //                 <td>{candidate.email}</td>
//   //                 <td>{candidate.mobile_number}</td>
//   //                 <td>{candidate.experience_level}</td>
//   //                 <td>{candidate.available_starting_date}</td>
//   //                 <td>{candidate.skills}</td>
//   //                 {/* <td>
//   //                   {candidate.resume_upload && (
//   //                     <Button variant="link" onClick={() => handleResumeClick(candidate.id, 'resume_upload')}><FaEye /></Button>
//   //                   )}
//   //                 </td> */}
//   //                 <td>
//   //                   {candidate.resume_upload && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() =>
//   //                         handleResumeClick(candidate.id, "resume_upload")
//   //                       }
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td>{candidate.mcq_score}</td>
//   //                 <td>
//   //                   {candidate["tenth_certificate"] && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() => handleSSC(candidate.email)}
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td>
//   //                   {candidate["twelth_certificate"] && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() => handleHSC(candidate.email)}
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td>
//   //                   {candidate.degree_certificate && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() => handleDegreeCertificate(candidate.email)}
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td>
//   //                   {candidate.aadhar_card && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() => handleAadharCard(candidate.email)}
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td>
//   //                   {candidate.internship_letter && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() => handleInternshipletter(candidate.email)}
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td>
//   //                   {candidate.experience_letter && (
//   //                     <Button
//   //                       variant="link"
//   //                       onClick={() => handleExperienceletter(candidate.email)}
//   //                     >
//   //                       <FaEye />
//   //                     </Button>
//   //                   )}
//   //                 </td>
//   //                 <td className="text-center">
//   //                   <Button
//   //                     variant="danger"
//   //                     onClick={() => handleDeleteCandidate(candidate.id)}
//   //                   >
//   //                     <FaTrashAlt />
//   //                   </Button>
//   //                 </td>
//   //               </tr>
//   //             ))}
//   //           </tbody>
//   //         </Table>

//   //         {/* Pagination controls */}
//   //         {/* <div className="pagination d-flex justify-content-end">
//   //           <button
//   //             className="btn btn-primary mr-2"
//   //             onClick={handlePrevPage}
//   //             disabled={currentPage === 1}
//   //           >
//   //             Previous
//   //           </button>
//   //           <span>{`Page ${currentPage}`}</span>
//   //           <button
//   //             className="btn btn-primary ml-2"
//   //             onClick={handleNextPage}
//   //             disabled={currentPage >= Math.ceil(totalItems / perPage)}
//   //           >
//   //             Next
//   //           </button>
//   //         </div> */}

//   // <div className="pagination d-flex justify-content-end">
//   //   <button
//   //     className="btn btn-primary mr-2"
//   //     onClick={handlePrevPage}
//   //     disabled={currentPage === 1}
//   //   >
//   //     <strong>{" >"}</strong> 
//   //     </button>
//   //   <span>{`Page ${currentPage}`}</span>
//   //   <button
//   //     className="btn btn-primary ml-2"
//   //     onClick={handleNextPage}
//   //     disabled={currentPage >= Math.ceil(totalItems / perPage)}
//   //   >
//   //     <strong>{" >"}</strong> 
//   //   </button>
//   // </div>


//   //         {/* Modal for displaying documents */}
//   //         <Modal show={false} onHide={() => {}} size="lg">
//   //           <Modal.Header closeButton>
//   //             <Modal.Title>View Document</Modal.Title>
//   //           </Modal.Header>
//   //           <Modal.Body>
//   //             {/* Use object or iframe to display document */}
//   //             <object data="" type="application/pdf" width="100%" height="500px">
//   //               <p>Document is not available.</p>
//   //             </object>
//   //           </Modal.Body>
//   //         </Modal>
//   //       </Container>

//   //       <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
//   //         <DialogTitle>Uploaded Document</DialogTitle>
//   //         <DialogContent>
//   //           {imageSrc ? (
//   //             <img src={imageSrc} alt="Document" style={{ width: "100%" }} />
//   //           ) : (
//   //             <DialogContentText>No image available</DialogContentText>
//   //           )}
//   //         </DialogContent>
//   //         <DialogActions>
//   //           <Button onClick={handleCloseImageDialog} color="primary">
//   //             Close
//   //           </Button>
//   //         </DialogActions>
//   //       </Dialog>
//   //     </>
//   //   );
//   // };

//   // export default DocumentUploadAdminDM;


// import React, { useState, useEffect, useCallback } from "react";
// import { Container, Table, Button, Modal } from "react-bootstrap";
// import { FaEye, FaTrashAlt } from "react-icons/fa";
// import axios from "axios";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
// } from "@material-ui/core";
// import AdminData from "../../AdminData/AdminData";

// const DocumentUploadAdminDM = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [perPage] = useState(50); // Number of items per page
//   const [totalItems, setTotalItems] = useState(0); // Total number of items
//   const [openImageDialog, setOpenImageDialog] = useState(false);
//   const [imageSrc, setImageSrc] = useState("");

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/admin/dmcandidates`,
//         {
//           params: {
//             _page: currentPage, // Pass current page number as a query parameter
//             _limit: perPage, // Limit number of items per page
//           },
//         }
//       );
//       setCandidates(response.data);
//       setTotalItems(parseInt(response.headers["x-total-count"], 10)); // Assuming the total count is provided in the headers
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }, [currentPage, perPage]);

//   useEffect(() => {
//     fetchData();
//   }, [currentPage, fetchData]);

//   const handleNextPage = () => {
//     if (currentPage < Math.ceil(totalItems / perPage)) {
//       setCurrentPage((prevPage) => prevPage + 1);
//     }
//   };

//   const handlePrevPage = () => {
//     if (currentPage > 1) {
//       setCurrentPage((prevPage) => prevPage - 1);
//     }
//   };

//   const handleResumeClick = async (candidateId) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/resume_dm/${candidateId}`,
//         {
//           responseType: "blob",
//         }
//       );
//       console.log("Response:", response); // Log the entire response to see what's being returned
//       if (response && response.headers) {
//         const contentType = response.headers["content-type"];
//         if (contentType && contentType.startsWith("application/pdf")) {
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType && contentType.startsWith("image/")) {
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       } else {
//         console.error("Content-Type header is missing in the response.");
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         console.error("Resume not found.");
//       } else {
//         console.error("Error fetching resume:", error);
//       }
//     }
//   };

//   const handleInternshipletter = async (candidateEmail) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/internship_letter`,
//         {
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const contentType = response.headers["content-type"];
//         if (contentType.startsWith("application/pdf")) {
//           // PDF content
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType.startsWith("image/")) {
//           // Image content
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching image:", error);
//       });
//   };

//   const handleExperienceletter = async (candidateEmail) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/experience_letter`,
//         {
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const contentType = response.headers["content-type"];
//         if (contentType.startsWith("application/pdf")) {
//           // PDF content
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType.startsWith("image/")) {
//           // Image content
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching image:", error);
//       });
//   };

//   const handleAadharCard = async (candidateEmail) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/aadhar_card`,
//         {
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const contentType = response.headers["content-type"];
//         if (contentType.startsWith("application/pdf")) {
//           // PDF content
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType.startsWith("image/")) {
//           // Image content
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching image:", error);
//       });
//   };

//   const handleHSC = async (candidateEmail) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/twelth_certificate`,
//         {
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const contentType = response.headers["content-type"];
//         if (contentType.startsWith("application/pdf")) {
//           // PDF content
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType.startsWith("image/")) {
//           // Image content
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching image:", error);
//       });
//   };

//   const handleSSC = async (candidateEmail) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/tenth_certificate`,
//         {
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const contentType = response.headers["content-type"];
//         if (contentType.startsWith("application/pdf")) {
//           // PDF content
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType.startsWith("image/")) {
//           // Image content
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching image:", error);
//       });
//   };

//   const handleDegreeCertificate = async (candidateEmail) => {
//     axios
//       .get(
//         `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/degree_certificate`,
//         {
//           responseType: "blob",
//         }
//       )
//       .then((response) => {
//         const contentType = response.headers["content-type"];
//         if (contentType.startsWith("application/pdf")) {
//           // PDF content
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType.startsWith("image/")) {
//           // Image content
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching image:", error);
//       });
//   };

//   const handleCloseImageDialog = () => {
//     setOpenImageDialog(false);
//     setImageSrc("");
//   };

//   const handleDeleteCandidate = async (id) => {
//     try {
//       const response = await axios.delete(
//         `${process.env.REACT_APP_API_URL}/dm_application_entries/${id}`
//       );
//       console.log("Candidate deleted:", response.data);

//       // Update the candidates state by filtering out the deleted candidate
//       setCandidates((prevCandidates) =>
//         prevCandidates.filter((candidate) => candidate.id !== id)
//       );

//       // Optionally, show a success message or perform other actions
//     } catch (error) {
//       console.error("Error deleting candidate:", error);
//       // Optionally, show an error message or handle the error
//     }
//   };

//   return (
//     <>
//     <AdminData/>
//       <Container className="document-upload-container ml-6">
//         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
//           View Details DigitalMarketing Job opning
//         </h2>
//         <Table responsive striped bordered hover className="custom-table">
//           <thead>
//             <tr>
//               <th>Full Name</th>
//               <th>Email</th>
//               <th>Mobile Number</th>
//               <th>Experience Level</th>
//               <th>Available Starting Date</th>
//               <th>Skills</th>
//               <th>Resume</th>
//               <th>MCQ Score</th>
//               <th>10th Certificate</th>
//               <th>12th Certificate/Diploma</th>
//               <th>Highest Degree Certificate</th>
//               <th>Aadhar Card</th>
//               <th>Internship Letter</th>
//               <th>Experience Letter</th>
//               <th>Actions</th>
//             </tr>
//           </thead>
//           <tbody>
//             {candidates.map((candidate, index) => (
//               <tr key={index}>
//                 <td>{candidate.full_name}</td>
//                 <td>{candidate.email}</td>
//                 <td>{candidate.mobile_number}</td>
//                 <td>{candidate.experience_level}</td>
//                 <td>{new Date(candidate.available_starting_date).toLocaleDateString()}</td>
//                 <td>{candidate.skills}</td>
//                 <td>
//                   {candidate.resume_upload && (
//                     <Button
//                       variant="link"
//                       onClick={() =>
//                         handleResumeClick(candidate.id, "resume_upload")
//                       }
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td>{candidate.mcq_score}</td>
//                 <td>
//                   {candidate["tenth_certificate"] && (
//                     <Button
//                       variant="link"
//                       onClick={() => handleSSC(candidate.email)}
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td>
//                   {candidate["twelth_certificate"] && (
//                     <Button
//                       variant="link"
//                       onClick={() => handleHSC(candidate.email)}
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td>
//                   {candidate.degree_certificate && (
//                     <Button
//                       variant="link"
//                       onClick={() => handleDegreeCertificate(candidate.email)}
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td>
//                   {candidate.aadhar_card && (
//                     <Button
//                       variant="link"
//                       onClick={() => handleAadharCard(candidate.email)}
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td>
//                   {candidate.internship_letter && (
//                     <Button
//                       variant="link"
//                       onClick={() => handleInternshipletter(candidate.email)}
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td>
//                   {candidate.experience_letter && (
//                     <Button
//                       variant="link"
//                       onClick={() => handleExperienceletter(candidate.email)}
//                     >
//                       <FaEye />
//                     </Button>
//                   )}
//                 </td>
//                 <td className="text-center">
//                   <Button
//                     variant="danger"
//                     onClick={() => handleDeleteCandidate(candidate.id)}
//                   >
//                     <FaTrashAlt />
//                   </Button>
//                 </td>
//               </tr>
//             ))}
//           </tbody>
//         </Table>

//         {/* Pagination controls */}
//         <div className="pagination d-flex justify-content-end">
//           <button
//             className="btn btn-primary mr-2"
//             onClick={handlePrevPage}
//             disabled={currentPage === 1}
//           >
//             <strong>{" <"}</strong>
//           </button>
//           <span>{`Page ${currentPage}`}</span>
//           <button
//             className="btn btn-primary ml-2"
//             onClick={handleNextPage}
//             disabled={currentPage >= Math.ceil(totalItems / perPage)}
//           >
//             <strong>{" >"}</strong>
//           </button>
//         </div>

//         {/* Modal for displaying documents */}
//         <Modal show={false} onHide={() => {}} size="lg">
//           <Modal.Header closeButton>
//             <Modal.Title>View Document</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             {/* Use object or iframe to display document */}
//             <object data="" type="application/pdf" width="100%" height="500px">
//               <p>Document is not available.</p>
//             </object>
//           </Modal.Body>
//         </Modal>
//       </Container>

//       <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
//         <DialogTitle>Uploaded Document</DialogTitle>
//         <DialogContent>
//           {imageSrc ? (
//             <img src={imageSrc} alt="Document" style={{ width: "100%" }} />
//           ) : (
//             <DialogContentText>No image available</DialogContentText>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleCloseImageDialog} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DocumentUploadAdminDM;



// // import React, { useState, useEffect, useCallback } from "react";
// // import { Container, Table, Button, Modal } from "react-bootstrap";
// // import { FaEye, FaTrashAlt } from "react-icons/fa";
// // import axios from "axios";
// // import {
// //   Dialog,
// //   DialogTitle,
// //   DialogContent,
// //   DialogContentText,
// //   DialogActions,
// // } from "@material-ui/core";
// // import AdminData from "../../AdminData/AdminData";

// // const DocumentUploadAdminDM = () => {
// //   const [candidates, setCandidates] = useState([]);
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const [perPage] = useState(50); // Number of items per page
// //   const [totalItems, setTotalItems] = useState(0); // Total number of items
// //   const [openImageDialog, setOpenImageDialog] = useState(false);
// //   const [imageSrc] = useState("");
// //   const [deleteCandidateId, setDeleteCandidateId] = useState(null);
// //   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

// //   const fetchData = useCallback(async () => {
// //     try {
// //       const response = await axios.get(
// //         `${process.env.REACT_APP_API_URL}/admin/dmcandidates`,
// //         {
// //           params: {
// //             _page: currentPage, // Pass current page number as a query parameter
// //             _limit: perPage, // Limit number of items per page
// //           },
// //         }
// //       );
// //       setCandidates(response.data);
// //       setTotalItems(parseInt(response.headers["x-total-count"], 10)); // Assuming the total count is provided in the headers
// //     } catch (error) {
// //       console.error("Error fetching data:", error);
// //     }
// //   }, [currentPage, perPage]);

// //   useEffect(() => {
// //     fetchData();
// //   }, [currentPage, fetchData]);

// //   const handleNextPage = () => {
// //     if (currentPage < Math.ceil(totalItems / perPage)) {
// //       setCurrentPage((prevPage) => prevPage + 1);
// //     }
// //   };

// //   const handlePrevPage = () => {
// //     if (currentPage > 1) {
// //       setCurrentPage((prevPage) => prevPage - 1);
// //     }
// //   };

// //   const handleResumeClick = async (candidateId) => {
// //     // Implement your resume click logic
// //   };

// //   const handleDocumentClick = async (candidateEmail, documentType) => {
// //     // Implement your document click logic
// //   };

// //   const handleDeleteCandidate = async (id) => {
// //     try {
// //       const response = await axios.delete(
// //         `${process.env.REACT_APP_API_URL}/dm_application_entries/${id}`
// //       );
// //       console.log("Candidate deleted:", response.data);

// //       // Update the candidates state by filtering out the deleted candidate
// //       setCandidates((prevCandidates) =>
// //         prevCandidates.filter((candidate) => candidate.id !== id)
// //       );

// //       // Optionally, show a success message or perform other actions
// //     } catch (error) {
// //       console.error("Error deleting candidate:", error);
// //       // Optionally, show an error message or handle the error
// //     } finally {
// //       setOpenDeleteDialog(false); // Close the delete confirmation dialog
// //     }
// //   };

// //   const openDeleteConfirmation = (id) => {
// //     setDeleteCandidateId(id); // Set the candidate ID to be deleted
// //     setOpenDeleteDialog(true); // Open the delete confirmation dialog
// //   };

// //   const handleCloseDeleteDialog = () => {
// //     setOpenDeleteDialog(false); // Close the delete confirmation dialog
// //   };

// //   return (
// //     <>
// //       <AdminData />
// //       <Container className="document-upload-container ml-6">
// //         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
// //           View Details DigitalMarketing Job openings
// //         </h2>
// //         <Table responsive striped bordered hover className="custom-table">
// //           <thead>
// //             <tr>
// //               <th>Full Name</th>
// //               <th>Email</th>
// //               <th>Mobile Number</th>
// //               <th>Experience Level</th>
// //               <th>Available Starting Date</th>
// //               <th>Skills</th>
// //               <th>Resume</th>
// //               <th>MCQ Score</th>
// //               <th>10th Certificate</th>
// //               <th>12th Certificate/Diploma</th>
// //               <th>Highest Degree Certificate</th>
// //               <th>Aadhar Card</th>
// //               <th>Internship Letter</th>
// //               <th>Experience Letter</th>
// //               <th>Actions</th>
// //             </tr>
// //           </thead>
// //           <tbody>
// //             {candidates.map((candidate, index) => (
// //               <tr key={index}>
// //                 <td>{candidate.full_name}</td>
// //                 <td>{candidate.email}</td>
// //                 <td>{candidate.mobile_number}</td>
// //                 <td>{candidate.experience_level}</td>
// //                 <td>
// //                   {new Date(candidate.available_starting_date).toLocaleDateString()}
// //                 </td>
// //                 <td>{candidate.skills}</td>
// //                 <td>
// //                   {candidate.resume_upload && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleResumeClick(candidate.id)}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td>{candidate.mcq_score}</td>
// //                 <td>
// //                   {candidate["tenth_certificate"] && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleDocumentClick(candidate.email, "tenth_certificate")}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td>
// //                   {candidate["twelth_certificate"] && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleDocumentClick(candidate.email, "twelth_certificate")}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td>
// //                   {candidate.degree_certificate && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleDocumentClick(candidate.email, "degree_certificate")}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td>
// //                   {candidate.aadhar_card && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleDocumentClick(candidate.email, "aadhar_card")}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td>
// //                   {candidate.internship_letter && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleDocumentClick(candidate.email, "internship_letter")}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td>
// //                   {candidate.experience_letter && (
// //                     <Button
// //                       variant="link"
// //                       onClick={() => handleDocumentClick(candidate.email, "experience_letter")}
// //                     >
// //                       <FaEye />
// //                     </Button>
// //                   )}
// //                 </td>
// //                 <td className="text-center">
// //                   <Button
// //                     variant="danger"
// //                     onClick={() => openDeleteConfirmation(candidate.id)}
// //                   >
// //                     <FaTrashAlt />
// //                   </Button>
// //                 </td>
// //               </tr>
// //             ))}
// //           </tbody>
// //         </Table>

// //         {/* Pagination controls */}
// //         <div className="pagination d-flex justify-content-end">
// //           <button
// //             className="btn btn-primary mr-2"
// //             onClick={handlePrevPage}
// //             disabled={currentPage === 1}
// //           >
// //             <strong>{" <"}</strong>
// //           </button>
// //           <span>{`Page ${currentPage}`}</span>
// //           <button
// //             className="btn btn-primary ml-2"
// //             onClick={handleNextPage}
// //             disabled={currentPage >= Math.ceil(totalItems / perPage)}
// //           >
// //             <strong>{" >"}</strong>
// //           </button>
// //         </div>

//         // {/* Delete Confirmation Dialog */}
//         // <Dialog
//         //   open={openDeleteDialog}
//         //   onClose={handleCloseDeleteDialog}
//         //   aria-labelledby="alert-dialog-title"
//         //   aria-describedby="alert-dialog-description"
//         // >
//         //   <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
//         //   <DialogContent>
//         //     <DialogContentText id="alert-dialog-description">
//         //       Are you sure you want to delete this candidate?
//         //     </DialogContentText>
//         //   </DialogContent>
//         //   <DialogActions>
//         //     <Button onClick={handleCloseDeleteDialog} color="primary">
//         //       Cancel
//         //     </Button>
//         //     <Button
//         //       onClick={() => handleDeleteCandidate(deleteCandidateId)}
//         //       color="primary"
//         //       autoFocus
//         //     >
//         //       Delete
//         //     </Button>
//         //   </DialogActions>
//         // </Dialog>
// //       </Container>

// //       {/* Modal for displaying documents */}
// //       <Modal show={false} onHide={() => {}} size="lg">
// //         <Modal.Header closeButton>
// //           <Modal.Title>View Document</Modal.Title>
// //         </Modal.Header>
// //         <Modal.Body>
// //           {/* Use object or iframe to display document */}
// //           <object data="" type="application/pdf" width="100%" height="500px">
// //             <p>Document is not available.</p>
// //           </object>
// //         </Modal.Body>
// //       </Modal>

// //       {/* Image Display Dialog */}
// //       <Dialog open={openImageDialog} onClose={() => setOpenImageDialog(false)}>
// //         <DialogTitle>Uploaded Document</DialogTitle>
// //         <DialogContent>
// //           {imageSrc ? (
// //             <img src={imageSrc} alt="Document" style={{ width: "100%" }} />
// //           ) : (
// //             <DialogContentText>No image available</DialogContentText>
// //           )}
// //         </DialogContent>
// //         <DialogActions>
// //           <Button onClick={() => setOpenImageDialog(false)} color="primary">
// //             Close
// //           </Button>
// //         </DialogActions>
// //       </Dialog>
// //     </>
// //   );
// // };

// // export default DocumentUploadAdminDM;

import React, { useState, useEffect, useCallback } from "react";
import { Container, Table, Button, Modal } from "react-bootstrap";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import AdminData from "../../AdminData/AdminData";

const DocumentUploadAdminDM = () => {
  const [candidates, setCandidates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(50); // Number of items per page
  const [totalItems, setTotalItems] = useState(0); // Total number of items
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/dmcandidates`,
        {
          params: {
            _page: currentPage, // Pass current page number as a query parameter
            _limit: perPage, // Limit number of items per page
          },
        }
      );
      setCandidates(response.data);
      setTotalItems(parseInt(response.headers["x-total-count"], 10)); // Assuming the total count is provided in the headers
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [currentPage, perPage]);

  useEffect(() => {
    fetchData();
  }, [currentPage, fetchData]);

  const handleNextPage = () => {
    if (currentPage < Math.ceil(totalItems / perPage)) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleResumeClick = async (candidateId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/resume_dm/${candidateId}`,
        {
          responseType: "blob",
        }
      );
      console.log("Response:", response); // Log the entire response to see what's being returned
      if (response && response.headers) {
        const contentType = response.headers["content-type"];
        if (contentType && contentType.startsWith("application/pdf")) {
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType && contentType.startsWith("image/")) {
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      } else {
        console.error("Content-Type header is missing in the response.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resume not found.");
      } else {
        console.error("Error fetching resume:", error);
      }
    }
  };

  const handleInternshipletter = async (candidateEmail) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/internship_letter`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("application/pdf")) {
          // PDF content
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType.startsWith("image/")) {
          // Image content
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const handleExperienceletter = async (candidateEmail) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/experience_letter`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("application/pdf")) {
          // PDF content
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType.startsWith("image/")) {
          // Image content
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const handleAadharCard = async (candidateEmail) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/aadhar_card`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("application/pdf")) {
          // PDF content
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType.startsWith("image/")) {
          // Image content
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const handleHSC = async (candidateEmail) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/twelth_certificate`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("application/pdf")) {
          // PDF content
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType.startsWith("image/")) {
          // Image content
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const handleSSC = async (candidateEmail) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/tenth_certificate`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("application/pdf")) {
          // PDF content
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType.startsWith("image/")) {
          // Image content
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const handleDegreeCertificate = async (candidateEmail) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/candidatesdm/${candidateEmail}/document/degree_certificate`,
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        const contentType = response.headers["content-type"];
        if (contentType.startsWith("application/pdf")) {
          // PDF content
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType.startsWith("image/")) {
          // Image content
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setImageSrc("");
  };

  const handleDeleteCandidate = async () => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/dm_application_entries/${deleteCandidateId}`
      );
      console.log("Candidate deleted:", response.data);

      // Update the candidates state by filtering out the deleted candidate
      setCandidates((prevCandidates) =>
        prevCandidates.filter((candidate) => candidate.id !== deleteCandidateId)
      );

      // Close the delete dialog
      setOpenDeleteDialog(false);

      // Optionally, show a success message or perform other actions
    } catch (error) {
      console.error("Error deleting candidate:", error);
      // Optionally, show an error message or handle the error
    }
  };

  const handleOpenDeleteDialog = (id) => {
    setDeleteCandidateId(id);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
    setDeleteCandidateId(null);
  };

  return (
    <>
      <AdminData />
      <Container className="document-upload-container ml-6">
        <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
          View Details DigitalMarketing Job opning
        </h2>
        <Table responsive striped bordered hover className="custom-table">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th>Mobile Number</th>
              <th>Experience Level</th>
              <th>Available Starting Date</th>
              <th>Skills</th>
              <th>Resume</th>
              <th>MCQ Score</th>
              <th>10th Certificate</th>
              <th>12th Certificate/Diploma</th>
              <th>Highest Degree Certificate</th>
              <th>Aadhar Card</th>
              <th>Internship Letter</th>
              <th>Experience Letter</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {candidates.map((candidate, index) => (
              <tr key={index}>
                <td>{candidate.full_name}</td>
                <td>{candidate.email}</td>
                <td>{candidate.mobile_number}</td>
                <td>{candidate.experience_level}</td>
                <td>
                  {new Date(candidate.available_starting_date).toLocaleDateString()}
                </td>
                <td>{candidate.skills}</td>
                <td>
                  {candidate.resume_upload && (
                    <Button
                      variant="link"
                      onClick={() => handleResumeClick(candidate.id)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td>{candidate.mcq_score}</td>
                <td>
                  {candidate.tenth_certificate && (
                    <Button
                      variant="link"
                      onClick={() => handleSSC(candidate.email)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td>
                  {candidate.twelth_certificate && (
                    <Button
                      variant="link"
                      onClick={() => handleHSC(candidate.email)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td>
                  {candidate.degree_certificate && (
                    <Button
                      variant="link"
                      onClick={() => handleDegreeCertificate(candidate.email)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td>
                  {candidate.aadhar_card && (
                    <Button
                      variant="link"
                      onClick={() => handleAadharCard(candidate.email)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td>
                  {candidate.internship_letter && (
                    <Button
                      variant="link"
                      onClick={() => handleInternshipletter(candidate.email)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td>
                  {candidate.experience_letter && (
                    <Button
                      variant="link"
                      onClick={() => handleExperienceletter(candidate.email)}
                    >
                      <FaEye />
                    </Button>
                  )}
                </td>
                <td className="text-center">
                  <Button
                    variant="danger"
                    onClick={() => handleOpenDeleteDialog(candidate.id)}
                  >
                    <FaTrashAlt />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {/* Pagination controls */}
        <div className="pagination d-flex justify-content-end">
          <button
            className="btn btn-primary mr-2"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <strong>{" <"}</strong>
          </button>
          <span>{`Page ${currentPage}`}</span>
          <button
            className="btn btn-primary ml-2"
            onClick={handleNextPage}
            disabled={currentPage >= Math.ceil(totalItems / perPage)}
          >
            <strong>{" >"}</strong>
          </button>
        </div>

        {/* Modal for displaying documents */}
        <Modal show={false} onHide={() => {}} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>View Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Use object or iframe to display document */}
            <object data="" type="application/pdf" width="100%" height="500px">
              <p>Document is not available.</p>
            </object>
          </Modal.Body>
        </Modal>
      </Container>

      <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
        <DialogTitle>Uploaded Document</DialogTitle>
        <DialogContent>
          {imageSrc ? (
            <img src={imageSrc} alt="Document" style={{ width: "100%" }} />
          ) : (
            <DialogContentText>No image available</DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImageDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this candidate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteCandidate} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentUploadAdminDM;
