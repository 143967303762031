import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Table,
  Button,
  Form,
  Alert,
  Modal,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure you have this import for Bootstrap styles
import "./DocumentUploadUI.css"; // Custom CSS import

const documentFields = [
  { label: "10th Certificate (optional)", key: "tenth_certificate" },
  { label: "12th Certificate/Diploma (optional)", key: "twelth_certificate" },
  {
    label: "Highest Degree Certificate",
    key: "degree_certificate",
    required: true,
  },
  { label: "Aadhar Card", key: "aadhar_card", required: true },
  { label: "Internship Letter (optional)", key: "internship_letter" },
  { label: "Experience Letter (optional)", key: "experience_letter" },
];

const DocumentUploadUI = () => {
  const navigate = useNavigate();
  const emailFromStorage = localStorage.getItem("userEmail"); // Retrieve email from local storage
  const [documents, setDocuments] = useState({
    tenth_certificate: null,
    twelth_certificate: null,
    degree_certificate: null,
    aadhar_card: null,
    internship_letter: null,
    experience_letter: null,
  });
  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const fileInputs = useRef(
    documentFields.reduce((acc, field) => {
      acc[field.key] = React.createRef();
      return acc;
    }, {})
  );

  const handleFileChange = (e, key) => {
    setDocuments({
      ...documents,
      [key]: e.target.files[0],
    });
  };

  const handleViewDocument = (key) => {
    const file = documents[key];
    if (file) {
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }
  };

  const handleReset = (key) => {
    setDocuments((prevDocs) => ({
      ...prevDocs,
      [key]: null,
    }));
    if (fileInputs.current[key]) {
      fileInputs.current[key].current.value = null;
    }
  };

  const validate = () => {
    const newErrors = {};
    documentFields.forEach((field) => {
      if (field.required && !documents[field.key]) {
        newErrors[field.key] = `${field.label} is required`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true); // Start loading
      const formData = new FormData();
      Object.entries(documents).forEach(([key, value]) => {
        if (value) {
          formData.append(key, value);
        }
      });
      formData.append("email", emailFromStorage);
      try {
         const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/ui-documents`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error(`Error uploading documents: ${response.statusText}`);
        }
        setShowModal(true); // Show success modal
      } catch (error) {
        console.error("Error uploading documents:", error);
        alert("Error uploading documents. Please try again.");
      } finally {
        setLoading(false); // End loading
      }
    }
  };

  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <Container className="document-upload-container">
      <h2 className="mt-4 heading-primary text-center">
        Upload Your Documents
      </h2>
      <p className="description text-center mt-5">
        Now, you have completed the test! To proceed with your application,
        please upload the required documents. These will help us better
        understand your qualifications and ensure a smooth review process.
      </p>
      <h4 className="sub-heading text-center mt-5">
        <strong>Please upload the following documents</strong>
      </h4>
      {Object.keys(errors).length > 0 && (
        <Alert variant="danger" className="mt-3">
          {Object.values(errors).map((error, idx) => (
            <div key={idx}>{error}</div>
          ))}
        </Alert>
      )}
      <Form onSubmit={handleSubmit}>
        <Table className="no-border mt-4 mb-4">
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Upload</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documentFields.map((field, index) => (
              <tr key={index}>
                <td>{field.label}</td>
                <td>
                  <Form.Control
                    type="file"
                    ref={fileInputs.current[field.key]}
                    onChange={(e) => handleFileChange(e, field.key)}
                    isInvalid={!!errors[field.key]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[field.key]}
                  </Form.Control.Feedback>
                </td>
                <td>
                  {documents[field.key] && (
                    <>
                      <Button
                        variant="secondary"
                        onClick={() => handleViewDocument(field.key)}
                        className="me-2"
                      >
                        View
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() => handleReset(field.key)}
                      >
                        Reset
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className="text-center">
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="visually-hidden">Uploading...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </div>
      </Form>
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Submission Successful</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Your documents have been submitted successfully!
        <p>Thank You</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleModalClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default DocumentUploadUI;
