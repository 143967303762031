

// import React, { useState, useEffect, useCallback } from "react";
// import { Container, Button, Modal } from "react-bootstrap";
// import { FaEye, FaTrashAlt } from "react-icons/fa";
// import axios from "axios";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
// } from "@material-ui/core";
// import AdminData from "../AdminData/AdminData";
// import DocumentUploadAdminDM from "./DocumentUploadDM/DocumentUploadAdminDM";

// const DocumentUploadAdmin = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalItems, setTotalItems] = useState(0);
//   const [showDocumentModal, setShowDocumentModal] = useState(false);
//   const [documentUrl,] = useState("");
//   const [openImageDialog, setOpenImageDialog] = useState(false);
//   const [imageSrc, setImageSrc] = useState("");
//   const [showCodeModal, setShowCodeModal] = useState(false);
//   const [selectedCodeValue, setSelectedCodeValue] = useState("");

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/admin/candidates`,
//         {
//           params: {
//             _page: page + 1,
//             _limit: rowsPerPage,
//           },
//         }
//       );
//       setCandidates(response.data);
//       setTotalItems(parseInt(response.headers["x-total-count"], 10));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }, [page, rowsPerPage]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleResumeClick = async (candidateId) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/resume/${candidateId}`,
//         {
//           responseType: "blob",
//         }
//       );
//       if (response && response.headers) {
//         const contentType = response.headers["content-type"];
//         if (contentType && contentType.startsWith("application/pdf")) {
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType && contentType.startsWith("image/")) {
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       } else {
//         console.error("Content-Type header is missing in the response.");
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         console.error("Resume not found.");
//       } else {
//         console.error("Error fetching resume:", error);
//       }
//     }
//   };

//   const handleDocumentClick = async (candidateEmail, documentType) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/candidates/${candidateEmail}/document/${documentType}`,
//         {
//           responseType: "blob",
//         }
//       );
//       const contentType = response.headers["content-type"];
//       if (contentType.startsWith("application/pdf")) {
//         const pdfUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         window.open(pdfUrl);
//       } else if (contentType.startsWith("image/")) {
//         const imageUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         setImageSrc(imageUrl);
//         setOpenImageDialog(true);
//       } else {
//         console.error("Unsupported content type:", contentType);
//       }
//     } catch (error) {
//       console.error("Error fetching document:", error);
//     }
//   };

//   const handleViewCode = (value) => {
//     setSelectedCodeValue(value);
//     setShowCodeModal(true);
//   };

//   const handleDeleteCandidate = async (id) => {
//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_API_URL}/job_application_entries/${id}`
//       );
//       setCandidates((prevCandidates) =>
//         prevCandidates.filter((candidate) => candidate.id !== id)
//       );
//     } catch (error) {
//       console.error("Error deleting candidate:", error);
//     }
//   };

//   const handleCloseImageDialog = () => {
//     setOpenImageDialog(false);
//     setImageSrc("");
//   };

//   return (
//     <>
//       <AdminData />
//       <Container className="document-upload-container">
//         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
//           View Documents Development Job
//         </h2>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Full Name</TableCell>
//                 <TableCell>Email</TableCell>
//                 <TableCell>Mobile Number</TableCell>
//                 <TableCell>Experience Level</TableCell>
//                 <TableCell>Available Starting Date</TableCell>
//                 <TableCell>Skills</TableCell>
//                 <TableCell>Resume</TableCell>
//                 <TableCell>MCQ Score</TableCell>
//                 <TableCell>Code 1</TableCell>
//                 <TableCell>Code 2</TableCell>
//                 <TableCell>Code 3</TableCell>
//                 <TableCell>Code 4</TableCell>
//                 <TableCell>10th Certificate</TableCell>
//                 <TableCell>12th Certificate/Diploma</TableCell>
//                 <TableCell>Highest Degree Certificate</TableCell>
//                 <TableCell>Aadhar Card</TableCell>
//                 <TableCell>Internship Letter</TableCell>
//                 <TableCell>Experience Letter</TableCell>
//                 <TableCell>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {candidates.map((candidate, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{candidate.full_name}</TableCell>
//                   <TableCell>{candidate.email}</TableCell>
//                   <TableCell>{candidate.mobile_number}</TableCell>
//                   <TableCell>{candidate.experience_level}</TableCell>
//                   <TableCell>{candidate.available_starting_date}</TableCell>
//                   <TableCell>{candidate.skills}</TableCell>
//                   <TableCell>
//                     {candidate.resume_upload && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleResumeClick(candidate.id)
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>{candidate.mcq_score}</TableCell>
//                   <TableCell>
//                     {candidate.code1 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code1)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code2 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code2)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code3 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code3)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code4 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code4)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.tenth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "tenth_certificate")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.twelth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "twelth_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.degree_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "degree_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.aadhar_card && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "aadhar_card")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.internship_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "internship_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.experience_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "experience_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell className="text-center">
//                     <Button
//                       variant="danger"
//                       onClick={() => handleDeleteCandidate(candidate.id)}
//                     >
//                       <FaTrashAlt />
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[10, 25, 50]}
//           component="div"
//           count={totalItems}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//         <Modal
//           show={showDocumentModal}
//           onHide={() => setShowDocumentModal(false)}
//           size="lg"
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>View Document</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <object
//               data={documentUrl}
//               type="application/pdf"
//               width="100%"
//               height="500px"
//             >
//               <p>Document is not available.</p>
//             </object>
//           </Modal.Body>
//         </Modal>
//         <Modal show={showCodeModal} onHide={() => setShowCodeModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Code Details</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <pre>{selectedCodeValue}</pre>
//           </Modal.Body>
//         </Modal>
//         <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
//           <DialogTitle>View Image</DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               <img
//                 src={imageSrc}
//                 alt="Document"
//                 style={{ maxWidth: "100%", maxHeight: "80vh" }}
//               />
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseImageDialog} color="primary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Container>
//       <DocumentUploadAdminDM />
//     </>
//   );
// };

// export default DocumentUploadAdmin;


// import React, { useState, useEffect, useCallback } from "react";
// import { Container, Button, Modal } from "react-bootstrap";
// import { FaEye, FaTrashAlt } from "react-icons/fa";
// import axios from "axios";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
// } from "@material-ui/core";
// import AdminData from "../AdminData/AdminData";
// // import DocumentUploadAdminDM from "./DocumentUploadDM/DocumentUploadAdminDM";

// const DocumentUploadAdmin = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalItems, setTotalItems] = useState(0);
//   const [showDocumentModal, setShowDocumentModal] = useState(false);
//   const [documentUrl,] = useState("");
//   const [openImageDialog, setOpenImageDialog] = useState(false);
//   const [imageSrc, setImageSrc] = useState("");
//   const [showCodeModal, setShowCodeModal] = useState(false);
//   const [selectedCodeValue, setSelectedCodeValue] = useState("");

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/admin/candidates`,
//         {
//           params: {
//             _page: page + 1,
//             _limit: rowsPerPage,
//           },
//         }
//       );
//       setCandidates(response.data);
//       setTotalItems(parseInt(response.headers["x-total-count"], 10));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }, [page, rowsPerPage]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString(); // Adjust locale as needed
//   };

//   const handleResumeClick = async (candidateId) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/resume/${candidateId}`,
//         {
//           responseType: "blob",
//         }
//       );
//       if (response && response.headers) {
//         const contentType = response.headers["content-type"];
//         if (contentType && contentType.startsWith("application/pdf")) {
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType && contentType.startsWith("image/")) {
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       } else {
//         console.error("Content-Type header is missing in the response.");
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         console.error("Resume not found.");
//       } else {
//         console.error("Error fetching resume:", error);
//       }
//     }
//   };

//   const handleDocumentClick = async (candidateEmail, documentType) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/candidates/${candidateEmail}/document/${documentType}`,
//         {
//           responseType: "blob",
//         }
//       );
//       const contentType = response.headers["content-type"];
//       if (contentType.startsWith("application/pdf")) {
//         const pdfUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         window.open(pdfUrl);
//       } else if (contentType.startsWith("image/")) {
//         const imageUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         setImageSrc(imageUrl);
//         setOpenImageDialog(true);
//       } else {
//         console.error("Unsupported content type:", contentType);
//       }
//     } catch (error) {
//       console.error("Error fetching document:", error);
//     }
//   };

//   const handleViewCode = (value) => {
//     setSelectedCodeValue(value);
//     setShowCodeModal(true);
//   };

//   const handleDeleteCandidate = async (id) => {
//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_API_URL}/job_application_entries/${id}`
//       );
//       setCandidates((prevCandidates) =>
//         prevCandidates.filter((candidate) => candidate.id !== id)
//       );
//     } catch (error) {
//       console.error("Error deleting candidate:", error);
//     }
//   };

//   const handleCloseImageDialog = () => {
//     setOpenImageDialog(false);
//     setImageSrc("");
//   };

//   return (
//     <>
//       <AdminData />
//       <Container className="document-upload-container">
//         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
//           View Details Java Development Job opning
//         </h2>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell>Full Name</TableCell>
//                 <TableCell>Email</TableCell>
//                 <TableCell>Mobile Number</TableCell>
//                 <TableCell>Experience Level</TableCell>
//                 <TableCell>Available Starting Date</TableCell>
//                 <TableCell>Skills</TableCell>
//                 <TableCell>Resume</TableCell>
//                 <TableCell>MCQ Score</TableCell>
//                 <TableCell>Code 1</TableCell>
//                 <TableCell>Code 2</TableCell>
//                 <TableCell>Code 3</TableCell>
//                 <TableCell>Code 4</TableCell>
//                 <TableCell>10th Certificate</TableCell>
//                 <TableCell>12th Certificate/Diploma</TableCell>
//                 <TableCell>Highest Degree Certificate</TableCell>
//                 <TableCell>Aadhar Card</TableCell>
//                 <TableCell>Internship Letter</TableCell>
//                 <TableCell>Experience Letter</TableCell>
//                 <TableCell>Actions</TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {candidates.map((candidate, index) => (
//                 <TableRow key={index}>
//                   <TableCell>{candidate.full_name}</TableCell>
//                   <TableCell>{candidate.email}</TableCell>
//                   <TableCell>{candidate.mobile_number}</TableCell>
//                   <TableCell>{candidate.experience_level}</TableCell>
//                   <TableCell>{formatDate(candidate.available_starting_date)}</TableCell>
//                   <TableCell>{candidate.skills}</TableCell>
//                   <TableCell>
//                     {candidate.resume_upload && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleResumeClick(candidate.id)
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>{candidate.mcq_score}</TableCell>
//                   <TableCell>
//                     {candidate.code1 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code1)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code2 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code2)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code3 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code3)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code4 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code4)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.tenth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "tenth_certificate")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.twelth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "twelth_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.degree_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "degree_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.aadhar_card && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "aadhar_card")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.internship_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "internship_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.experience_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "experience_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell className="text-center">
//                     <Button
//                       variant="danger"
//                       onClick={() => handleDeleteCandidate(candidate.id)}
//                     >
//                       <FaTrashAlt />
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[10, 25, 50]}
//           component="div"
//           count={totalItems}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//         <Modal
//           show={showDocumentModal}
//           onHide={() => setShowDocumentModal(false)}
//           size="lg"
//         >
//           <Modal.Header closeButton>
//             <Modal.Title>View Document</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <object
//               data={documentUrl}
//               type="application/pdf"
//               width="100%"
//               height="500px"
//             >
//               <p>Document is not available.</p>
//             </object>
//           </Modal.Body>
//         </Modal>
//         <Modal show={showCodeModal} onHide={() => setShowCodeModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Code Details</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <pre>{selectedCodeValue}</pre>
//           </Modal.Body>
//         </Modal>
//         <Dialog open={openImageDialog} onClose={handleCloseImageDialog}>
//           <DialogTitle>View Image</DialogTitle>
//           <DialogContent>
//             <DialogContentText>
//               <img
//                 src={imageSrc}
//                 alt="Document"
//                 style={{ maxWidth: "100%", maxHeight: "80vh" }}
//               />
//             </DialogContentText>
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={handleCloseImageDialog} color="primary">
//               Close
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Container>
//       {/* <DocumentUploadAdminDM /> */}
//     </>
//   );
// };

// export default DocumentUploadAdmin;

// import React, { useState, useEffect, useCallback } from "react";
// import { Container, Button, Modal } from "react-bootstrap";
// import { FaEye, FaTrashAlt } from "react-icons/fa";
// import axios from "axios";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
// } from "@material-ui/core";
// import AdminData from "../AdminData/AdminData";

// const DocumentUploadAdmin = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalItems, setTotalItems] = useState(0);
//   const [openImageDialog, setOpenImageDialog] = useState(false);
//   const [imageSrc, setImageSrc] = useState("");
//   const [showCodeModal, setShowCodeModal] = useState(false);
//   const [selectedCodeValue, setSelectedCodeValue] = useState("");
//   const [deleteCandidateId, setDeleteCandidateId] = useState(null);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/admin/candidates`,
//         {
//           params: {
//             _page: page + 1,
//             _limit: rowsPerPage,
//           },
//         }
//       );
//       setCandidates(response.data);
//       setTotalItems(parseInt(response.headers["x-total-count"], 10));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }, [page, rowsPerPage]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString(); // Adjust locale as needed
//   };

//   const handleResumeClick = async (candidateId) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/resume/${candidateId}`,
//         {
//           responseType: "blob",
//         }
//       );
//       if (response && response.headers) {
//         const contentType = response.headers["content-type"];
//         if (contentType && contentType.startsWith("application/pdf")) {
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType && contentType.startsWith("image/")) {
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       } else {
//         console.error("Content-Type header is missing in the response.");
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         console.error("Resume not found.");
//       } else {
//         console.error("Error fetching resume:", error);
//       }
//     }
//   };

//   const handleDocumentClick = async (candidateEmail, documentType) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/candidates/${candidateEmail}/document/${documentType}`,
//         {
//           responseType: "blob",
//         }
//       );
//       const contentType = response.headers["content-type"];
//       if (contentType.startsWith("application/pdf")) {
//         const pdfUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         window.open(pdfUrl);
//       } else if (contentType.startsWith("image/")) {
//         const imageUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         setImageSrc(imageUrl);
//         setOpenImageDialog(true);
//       } else {
//         console.error("Unsupported content type:", contentType);
//       }
//     } catch (error) {
//       console.error("Error fetching document:", error);
//     }
//   };

//   const handleViewCode = (value) => {
//     setSelectedCodeValue(value);
//     setShowCodeModal(true);
//   };

//   const handleDeleteCandidate = (id) => {
//     setDeleteCandidateId(id);
//     setOpenDeleteDialog(true);
//   };

//   const handleConfirmDelete = async (id) => {
//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_API_URL}/job_application_entries/${id}`
//       );
//       setCandidates((prevCandidates) =>
//         prevCandidates.filter((candidate) => candidate.id !== id)
//       );
//     } catch (error) {
//       console.error("Error deleting candidate:", error);
//     }
//   };

//   const handleCloseImageDialog = () => {
//     setOpenImageDialog(false);
//     setImageSrc("");
//   };

//   const isEven = (num) => num % 2 === 0;

//   return (
//     <>
//       <AdminData />
//       <Container className="document-upload-container">
//         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
//           View Details Java Development Job openings
//         </h2>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell><strong>Full Name</strong></TableCell>
//                 <TableCell><strong>Email</strong></TableCell>
//                 <TableCell><strong>Mobile Number</strong></TableCell>
//                 <TableCell><strong>Experience Level</strong></TableCell>
//                 <TableCell><strong>Available Starting Date</strong></TableCell>
//                 <TableCell><strong>Skills</strong></TableCell>
//                 <TableCell><strong>Resume</strong></TableCell>
//                 <TableCell><strong>MCQ Score</strong></TableCell>
//                 <TableCell><strong>Code 1</strong></TableCell>
//                 <TableCell><strong>Code 2</strong></TableCell>
//                 <TableCell><strong>Code 3</strong></TableCell>
//                 <TableCell><strong>Code 4</strong></TableCell>
//                 <TableCell><strong>10th Certificate</strong></TableCell>
//                 <TableCell><strong>12th Certificate/Diploma</strong></TableCell>
//                 <TableCell><strong>Highest Degree Certificate</strong></TableCell>
//                 <TableCell><strong>Aadhar Card</strong></TableCell>
//                 <TableCell><strong>Internship Letter</strong></TableCell>
//                 <TableCell><strong>Experience Letter</strong></TableCell>
//                 <TableCell><strong>Actions</strong></TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {candidates.map((candidate, index) => (
//                 <TableRow key={index} style={{ backgroundColor: isEven(index) ? '#f2f2f2' : 'white' }}>
//                   <TableCell>{candidate.full_name}</TableCell>
//                   <TableCell>{candidate.email}</TableCell>
//                   <TableCell>{candidate.mobile_number}</TableCell>
//                   <TableCell>{candidate.experience_level}</TableCell>
//                   <TableCell>{formatDate(candidate.available_starting_date)}</TableCell>
//                   <TableCell>{candidate.skills}</TableCell>
//                   <TableCell>
//                     {candidate.resume_upload && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleResumeClick(candidate.id)
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>{candidate.mcq_score}</TableCell>
//                   <TableCell>
//                     {candidate.code1 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code1)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code2 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code2)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code3 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code3)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code4 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code4)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.tenth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "tenth_certificate")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.twelth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "twelth_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.degree_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "degree_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.aadhar_card && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "aadhar_card")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.internship_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "internship_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.experience_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "experience_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     <Button
//                       variant="danger"
//                       onClick={() => handleDeleteCandidate(candidate.id)}
//                     >
//                       <FaTrashAlt />
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={totalItems}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Container>

//       <Modal
//         show={openImageDialog}
//         onHide={handleCloseImageDialog}
//         size="lg"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Document Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="text-center">
//           <img src={imageSrc} alt="Document Preview" style={{ maxWidth: "100%" }} />
//         </Modal.Body>
//       </Modal>

//       {/* Code Modal */}
//       <Modal
//         show={showCodeModal}
//         onHide={() => setShowCodeModal(false)}
//         size="lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Code Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <pre>{selectedCodeValue}</pre>
//         </Modal.Body>
//       </Modal>

//       {/* Delete Confirmation Dialog */}
//       <Dialog
//         open={openDeleteDialog}
//         onClose={() => setOpenDeleteDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Are you sure you want to delete this candidate?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={() => {
//               handleConfirmDelete(deleteCandidateId);
//               setOpenDeleteDialog(false);
//             }}
//             color="primary"
//             autoFocus
//           >
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DocumentUploadAdmin;


// import React, { useState, useEffect, useCallback } from "react";
// import { Container, Button, Modal } from "react-bootstrap";
// import { FaEye, FaTrashAlt } from "react-icons/fa";
// import axios from "axios";
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   TablePagination,
// } from "@material-ui/core";
// import AdminData from "../AdminData/AdminData";

// const DocumentUploadAdmin = () => {
//   const [candidates, setCandidates] = useState([]);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(10);
//   const [totalItems, setTotalItems] = useState(0);
//   const [openImageDialog, setOpenImageDialog] = useState(false);
//   const [imageSrc, setImageSrc] = useState("");
//   const [showCodeModal, setShowCodeModal] = useState(false);
//   const [selectedCodeValue, setSelectedCodeValue] = useState("");
//   const [deleteCandidateId, setDeleteCandidateId] = useState(null);
//   const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

//   const fetchData = useCallback(async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/admin/candidates`,
//         {
//           params: {
//             _page: page + 1,
//             _limit: rowsPerPage,
//           },
//         }
//       );
//       setCandidates(response.data);
//       setTotalItems(parseInt(response.headers["x-total-count"], 10));
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   }, [page, rowsPerPage]);

//   useEffect(() => {
//     fetchData();
//   }, [fetchData]);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString(); // Adjust locale as needed
//   };

//   const handleResumeClick = async (candidateId) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/resume/${candidateId}`,
//         {
//           responseType: "blob",
//         }
//       );
//       if (response && response.headers) {
//         const contentType = response.headers["content-type"];
//         if (contentType && contentType.startsWith("application/pdf")) {
//           const pdfUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           window.open(pdfUrl);
//         } else if (contentType && contentType.startsWith("image/")) {
//           const imageUrl = URL.createObjectURL(
//             new Blob([response.data], { type: contentType })
//           );
//           setImageSrc(imageUrl);
//           setOpenImageDialog(true);
//         } else {
//           console.error("Unsupported content type:", contentType);
//         }
//       } else {
//         console.error("Content-Type header is missing in the response.");
//       }
//     } catch (error) {
//       if (error.response && error.response.status === 404) {
//         console.error("Resume not found.");
//       } else {
//         console.error("Error fetching resume:", error);
//       }
//     }
//   };

//   const handleDocumentClick = async (candidateEmail, documentType) => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_API_URL}/candidates/${candidateEmail}/document/${documentType}`,
//         {
//           responseType: "blob",
//         }
//       );
//       const contentType = response.headers["content-type"];
//       if (contentType.startsWith("application/pdf")) {
//         const pdfUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         window.open(pdfUrl);
//       } else if (contentType.startsWith("image/")) {
//         const imageUrl = URL.createObjectURL(
//           new Blob([response.data], { type: contentType })
//         );
//         setImageSrc(imageUrl);
//         setOpenImageDialog(true);
//       } else {
//         console.error("Unsupported content type:", contentType);
//       }
//     } catch (error) {
//       console.error("Error fetching document:", error);
//     }
//   };

//   const handleViewCode = (value) => {
//     setSelectedCodeValue(value);
//     setShowCodeModal(true);
//   };

//   const handleDeleteCandidate = (id) => {
//     setDeleteCandidateId(id);
//     setOpenDeleteDialog(true);
//   };

//   const handleConfirmDelete = async (id) => {
//     try {
//       await axios.delete(
//         `${process.env.REACT_APP_API_URL}/job_application_entries/${id}`
//       );
//       setCandidates((prevCandidates) =>
//         prevCandidates.filter((candidate) => candidate.id !== id)
//       );
//     } catch (error) {
//       console.error("Error deleting candidate:", error);
//     }
//   };

//   const handleCloseImageDialog = () => {
//     setOpenImageDialog(false);
//     setImageSrc("");
//   };

//   const isEven = (num) => num % 2 === 0;

//   return (
//     <>
//       <AdminData />
//       <Container className="document-upload-container">
//         <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
//           View Details Java Development Job openings
//         </h2>
//         <TableContainer component={Paper}>
//           <Table>
//             <TableHead>
//               <TableRow>
//                 <TableCell><strong>S/N</strong></TableCell>
//                 <TableCell><strong>Full Name</strong></TableCell>
//                 <TableCell><strong>Email</strong></TableCell>
//                 <TableCell><strong>Mobile Number</strong></TableCell>
//                 <TableCell><strong>Experience Level</strong></TableCell>
//                 <TableCell><strong>Available Starting Date</strong></TableCell>
//                 <TableCell><strong>Skills</strong></TableCell>
//                 <TableCell><strong>Resume</strong></TableCell>
//                 <TableCell><strong>MCQ Score</strong></TableCell>
//                 <TableCell><strong>Code 1</strong></TableCell>
//                 <TableCell><strong>Code 2</strong></TableCell>
//                 <TableCell><strong>Code 3</strong></TableCell>
//                 <TableCell><strong>Code 4</strong></TableCell>
//                 <TableCell><strong>10th Certificate</strong></TableCell>
//                 <TableCell><strong>12th Certificate/Diploma</strong></TableCell>
//                 <TableCell><strong>Highest Degree Certificate</strong></TableCell>
//                 <TableCell><strong>Aadhar Card</strong></TableCell>
//                 <TableCell><strong>Internship Letter</strong></TableCell>
//                 <TableCell><strong>Experience Letter</strong></TableCell>
//                 <TableCell><strong>Actions</strong></TableCell>
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {candidates.map((candidate, index) => (
//                 <TableRow key={candidate.id} style={{ backgroundColor: isEven(index) ? '#f2f2f2' : 'white' }}>
//                   <TableCell>{page * rowsPerPage + index + 1}</TableCell>
//                   <TableCell>{candidate.full_name}</TableCell>
//                   <TableCell>{candidate.email}</TableCell>
//                   <TableCell>{candidate.mobile_number}</TableCell>
//                   <TableCell>{candidate.experience_level}</TableCell>
//                   <TableCell>{formatDate(candidate.available_starting_date)}</TableCell>
//                   <TableCell>{candidate.skills}</TableCell>
//                   <TableCell>
//                     {candidate.resume_upload && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleResumeClick(candidate.id)
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>{candidate.mcq_score}</TableCell>
//                   <TableCell>
//                     {candidate.code1 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code1)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code2 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code2)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code3 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code3)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.code4 && (
//                       <Button
//                         variant="link"
//                         onClick={() => handleViewCode(candidate.code4)}
//                       >
//                         View
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.tenth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "tenth_certificate")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.twelth_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "twelth_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.degree_certificate && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "degree_certificate"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.aadhar_card && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(candidate.email, "aadhar_card")
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.internship_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "internship_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     {candidate.experience_letter && (
//                       <Button
//                         variant="link"
//                         onClick={() =>
//                           handleDocumentClick(
//                             candidate.email,
//                             "experience_letter"
//                           )
//                         }
//                       >
//                         <FaEye />
//                       </Button>
//                     )}
//                   </TableCell>
//                   <TableCell>
//                     <Button
//                       variant="danger"
//                       onClick={() => handleDeleteCandidate(candidate.id)}
//                     >
//                       <FaTrashAlt />
//                     </Button>
//                   </TableCell>
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         </TableContainer>
//         <TablePagination
//           rowsPerPageOptions={[5, 10, 25]}
//           component="div"
//           count={totalItems}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </Container>

//       <Modal
//         show={openImageDialog}
//         onHide={handleCloseImageDialog}
//         size="lg"
//         centered
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Document Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="text-center">
//           <img src={imageSrc} alt="Document Preview" style={{ maxWidth: "100%" }} />
//         </Modal.Body>
//       </Modal>

//       {/* Code Modal */}
//       <Modal
//         show={showCodeModal}
//         onHide={() => setShowCodeModal(false)}
//         size="lg"
//       >
//         <Modal.Header closeButton>
//           <Modal.Title>Code Preview</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <pre>{selectedCodeValue}</pre>
//         </Modal.Body>
//       </Modal>

//       {/* Delete Confirmation Dialog */}
//       <Dialog
//         open={openDeleteDialog}
//         onClose={() => setOpenDeleteDialog(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//       >
//         <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
//         <DialogContent>
//           <DialogContentText id="alert-dialog-description">
//             Are you sure you want to delete this candidate?
//           </DialogContentText>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
//             Cancel
//           </Button>
//           <Button
//             onClick={() => {
//               handleConfirmDelete(deleteCandidateId);
//               setOpenDeleteDialog(false);
//             }}
//             color="primary"
//             autoFocus
//           >
//             Delete
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </>
//   );
// };

// export default DocumentUploadAdmin;




import React, { useState, useEffect, useCallback } from "react";
import { Container, Button, Modal } from "react-bootstrap";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import axios from "axios";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@material-ui/core";
import AdminData from "../AdminData/AdminData";

const DocumentUploadAdmin = () => {
  const [candidates, setCandidates] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [selectedCodeValue, setSelectedCodeValue] = useState("");
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/candidates`,
        {
          params: {
            _page: page + 1,
            _limit: rowsPerPage,
          },
        }
      );
      setCandidates(response.data);
      setTotalItems(parseInt(response.headers["x-total-count"], 10));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [page, rowsPerPage]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(); // Adjust locale as needed
  };

  const handleResumeClick = async (candidateId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/resume/${candidateId}`,
        {
          responseType: "blob",
        }
      );
      if (response && response.headers) {
        const contentType = response.headers["content-type"];
        if (contentType && contentType.startsWith("application/pdf")) {
          const pdfUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          window.open(pdfUrl);
        } else if (contentType && contentType.startsWith("image/")) {
          const imageUrl = URL.createObjectURL(
            new Blob([response.data], { type: contentType })
          );
          setImageSrc(imageUrl);
          setOpenImageDialog(true);
        } else {
          console.error("Unsupported content type:", contentType);
        }
      } else {
        console.error("Content-Type header is missing in the response.");
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.error("Resume not found.");
      } else {
        console.error("Error fetching resume:", error);
      }
    }
  };

  const handleDocumentClick = async (candidateEmail, documentType) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/candidates/${candidateEmail}/document/${documentType}`,
        {
          responseType: "blob",
        }
      );
      const contentType = response.headers["content-type"];
      if (contentType.startsWith("application/pdf")) {
        const pdfUrl = URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        window.open(pdfUrl);
      } else if (contentType.startsWith("image/")) {
        const imageUrl = URL.createObjectURL(
          new Blob([response.data], { type: contentType })
        );
        setImageSrc(imageUrl);
        setOpenImageDialog(true);
      } else {
        console.error("Unsupported content type:", contentType);
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  const handleViewCode = (value) => {
    setSelectedCodeValue(value);
    setShowCodeModal(true);
  };

  const handleDeleteCandidate = (id) => {
    setDeleteCandidateId(id);
    setOpenDeleteDialog(true);
  };

  const handleConfirmDelete = async (id) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_API_URL}/job_application_entries/${id}`
      );
      setCandidates((prevCandidates) =>
        prevCandidates.filter((candidate) => candidate.id !== id)
      );
    } catch (error) {
      console.error("Error deleting candidate:", error);
    }
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setImageSrc("");
  };

  const isEven = (num) => num % 2 === 0;

  return (
    <>
      <AdminData />
      <Container className="document-upload-container">
        <h2 className="mt-4 mb-4 text-center text-white bg-primary py-2">
          View Details Java Development Job openings
        </h2>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>S/N</strong></TableCell>
                <TableCell><strong>Full Name</strong></TableCell>
                <TableCell><strong>Email</strong></TableCell>
                <TableCell><strong>Mobile Number</strong></TableCell>
                <TableCell><strong>Experience Level</strong></TableCell>
                <TableCell><strong>Available Starting Date</strong></TableCell>
                <TableCell><strong>Skills</strong></TableCell>
                <TableCell><strong>Resume</strong></TableCell>
                <TableCell><strong>MCQ Score</strong></TableCell>
                <TableCell><strong>Code 1</strong></TableCell>
                <TableCell><strong>Code 2</strong></TableCell>
                <TableCell><strong>Code 3</strong></TableCell>
                <TableCell><strong>Code 4</strong></TableCell>
                <TableCell><strong>10th Certificate</strong></TableCell>
                <TableCell><strong>12th Certificate/Diploma</strong></TableCell>
                <TableCell><strong>Highest Degree Certificate</strong></TableCell>
                <TableCell><strong>Aadhar Card</strong></TableCell>
                <TableCell><strong>Internship Letter</strong></TableCell>
                <TableCell><strong>Experience Letter</strong></TableCell>
                <TableCell><strong>Actions</strong></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {candidates.map((candidate, index) => (
                <TableRow key={candidate.id} style={{ backgroundColor: isEven(index) ? '#f2f2f2' : 'white' }}>
                  <TableCell>{page * rowsPerPage + index + 1}</TableCell>
                  <TableCell>{candidate.full_name}</TableCell>
                  <TableCell>{candidate.email}</TableCell>
                  <TableCell>{candidate.mobile_number}</TableCell>
                  <TableCell>{candidate.experience_level}</TableCell>
                  <TableCell>{formatDate(candidate.available_starting_date)}</TableCell>
                  <TableCell>{candidate.skills}</TableCell>
                  <TableCell>
                    {candidate.resume_upload && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleResumeClick(candidate.id)
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>{candidate.mcq_score}</TableCell>
                  <TableCell>
                    {candidate.code1 && (
                      <Button
                        variant="link"
                        onClick={() => handleViewCode(candidate.code1)}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.code2 && (
                      <Button
                        variant="link"
                        onClick={() => handleViewCode(candidate.code2)}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.code3 && (
                      <Button
                        variant="link"
                        onClick={() => handleViewCode(candidate.code3)}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.code4 && (
                      <Button
                        variant="link"
                        onClick={() => handleViewCode(candidate.code4)}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.tenth_certificate && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDocumentClick(candidate.email, "tenth_certificate")
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.twelth_certificate && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDocumentClick(
                            candidate.email,
                            "twelth_certificate"
                          )
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.degree_certificate && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDocumentClick(
                            candidate.email,
                            "degree_certificate"
                          )
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.aadhar_card && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDocumentClick(candidate.email, "aadhar_card")
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.internship_letter && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDocumentClick(
                            candidate.email,
                            "internship_letter"
                          )
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    {candidate.experience_letter && (
                      <Button
                        variant="link"
                        onClick={() =>
                          handleDocumentClick(
                            candidate.email,
                            "experience_letter"
                          )
                        }
                      >
                        <FaEye />
                      </Button>
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="danger"
                      onClick={() => handleDeleteCandidate(candidate.id)}
                    >
                      <FaTrashAlt />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalItems}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Container>

      <Modal
        show={openImageDialog}
        onHide={handleCloseImageDialog}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Document Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <img src={imageSrc} alt="Document Preview" style={{ maxWidth: "100%" }} />
        </Modal.Body>
      </Modal>

      {/* Code Modal */}
      <Modal
        show={showCodeModal}
        onHide={() => setShowCodeModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>{selectedCodeValue}</pre>
        </Modal.Body>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this candidate?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleConfirmDelete(deleteCandidateId);
              setOpenDeleteDialog(false);
            }}
            color="primary"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DocumentUploadAdmin;
